import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import {livejapan} from "./livejapan.js"
// import ip from "@/ip.js";


const japan = createPersistedState({
  path:['state'],
  key:'livejapan'
})

// const plugins = [createPersistedState()];
export default new createStore({
  modules:{
    livejapan
  },
  plugins:[japan]
});

