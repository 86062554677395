<template>
    <Toast />
    <div class="login-body">
        <div class="login-panel" @keydown.enter.prevent="login()">
            <div class="mb-6 logo-container">
                <img src="../assets/LE_logo_login.png" class="login-text" />
            </div>
            <div class="form-container">
                <InputText v-model="data.email" type="text" placeholder="Email" />
            </div>
            <div class="form-container">
                <span class="p-input-icon-right">
                    <i v-if="eyes" @click="eyes = !eyes" class="pi pi-eye-slash"></i>
                    <i v-else @click="eyes = !eyes" class="pi pi-eye"></i>
                    <InputText v-model="data.password" :type="setType()" placeholder="Password" />
                </span>
            </div>
            <div class="form-container">
                <!-- <a href="#" class="flex">Forgot your password?</a> -->
            </div>
            <div class="button-container text-center">
                <Button :disabled="!fill || loading" type="button" @click="login()" label="Log in" style="display: block"></Button>
                <!-- <div>Don’t have an account?<a>Sign-up here</a></div> -->
            </div>
            <!-- <div class="login-footer flex align-items-center">
                <div class="flex align-items-center login-footer-logo-container">
                    <img src="layout/images/logo-gray.png" class="login-footer-logo" />
                    <img src="layout/images/appname-gray.png" class="login-footer-appname" />
                </div>
                <span>Copyright 2021</span>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            data: {
                email: '',
                password: '',
            },
            eyes: true,
            loading: false,
        };
    },
    computed: {
        loginColor() {
            if (this.$appState.colorScheme === 'light') return 'ondark';
            return 'onlight';
        },
        color() {
            if (this.$appState.colorScheme === 'light') return 'dark';
            return 'light';
        },
        fill() {
            return this.data.email && this.data.password;
        },
    },
    methods: {
        setType() {
            if (this.eyes) {
                return 'password';
            } else {
                return 'text';
            }
        },
        async login() {
            let vm = this;
            vm.loading = true;
            vm.$axios
                .post('user/login', vm.data)
                .then((res) => {
                    console.log(res.data);
                    if (res.data.status == 200) {
                        if (res.data.message == 'success') {
                            vm.$store.commit('userlogin', res.data.data[0]);
                            vm.$toast.add({ severity: 'success', summary: 'Confirmation', detail: 'Login Successful', life: 3000 });
                            this.checkUser();
                        } else {
                            vm.loading = false;
                            vm.$toast.add({ severity: 'warn', summary: 'Confirmation', detail: res.data.message, life: 3000 });
                        }
                    } else {
                        if (res.data.status == 204) {
                            vm.loading = false;
                            vm.$toast.add({ severity: 'warn', summary: 'Confirmation', detail: res.data.message, life: 3000 });
                        }
                    }
                })
                .catch((err) => {
                    vm.loading = false;
                    console.log(err);
                    vm.$toast.add({ severity: 'error', summary: 'Warning', detail: 'An error occurred on the server', life: 3000 });
                });
        },
        async checkUser() {
            let vm = this;
            let user = await vm.$axios('user/check_user');
            // console.log(user.data.data, 'check');
            if (user.data.status == 200) {
                vm.$store.commit('userCheck', user.data.data[0]);
                setTimeout(() => {
                    vm.loading = false;
                    vm.$router.push('/dashboard');
                }, 2000);
            }
        },
    },
};
</script>

<style scoped>
.login-body {
    box-sizing: border-box;
    min-height: 100vh;
}
.login-body .login-image {
    height: 100vh;
}
.login-body .login-image img {
    height: 100vh;
}
/* .login-body .login-panel {
    background-image: url('../../../layout/images/effect-onlight.png');
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-repeat: no-repeat;
    text-align: center;
    width: 100%;
    padding: 260px 100px 75px 100px;
    box-sizing: border-box;
} */
.login-body .login-panel .login-logo {
    width: 45px;
}
.login-body .login-panel .login-appname {
    width: 100px;
    margin-left: 1rem;
}
.login-body .login-panel .form-container {
    display: flex;
    justify-content: center;
}

.login-body .login-panel .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.login-body .login-panel .form-container input {
    display: block;
    max-width: 320px;
    min-width: 270px;
    margin-bottom: 10px;
    width: 100%;
}

.login-body .login-panel .form-container .p-input-icon-right {
    display: block;
    max-width: 320px;
    min-width: 270px;
    margin-bottom: 10px;
    width: 100%;
}
.login-body .login-panel .form-container a {
    color: #8a8ea6;
    margin-bottom: 20px;
    font-size: 11px;
}
.login-body .login-panel .button-container button {
    display: block;
    max-width: 320px;
    margin-bottom: 32px;
    width: 100%;
}
.login-body .login-panel .button-container > span {
    display: flex;
    font-size: 11px;
    color: #8a8ea6;
}
.login-body .login-panel .button-container > span a {
    cursor: pointer;
    margin-left: 0.25rem;
}
.login-body .login-panel .login-footer {
    position: absolute;
    bottom: 75px;
}
.login-body .login-panel .login-footer .login-footer-logo-container {
    padding-right: 1.5rem;
    border-right: 1px solid rgba(68, 72, 109, 0.2);
}
.login-body .login-panel .login-footer .login-footer-logo-container .login-footer-logo {
    width: 22px;
}
.login-body .login-panel .login-footer .login-footer-logo-container .login-footer-appname {
    width: 45px;
    margin-left: 0.5rem;
}
.login-body .login-panel .login-footer span {
    margin-left: 1rem;
    font-size: 11px;
    color: #8a8ea6;
}

@media (max-width: 991px) {
    .login-body .login-image {
        display: none;
    }
    .login-body .login-panel {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 60px 50px;
    }
}
</style>
