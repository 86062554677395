<template>
    <div class="layout-footer">
        <div class="footer-logo-container">
            <!-- <img id="footer-logo" :src="'layout/images/logo-'+ (colorScheme === 'light' ? 'dark' : 'light') + '.png'" alt="atlantis-layout"/> -->
            <span class="app-name"> &#169; 2012 - 2024 Live Inc.</span>
        </div>
        <span class="copyright">All Rights Reserved </span>
    </div>
</template>

<script>
export default {
    props: {
        colorScheme: String,
    },
};
</script>
