<template>
    <div class="layout-breadcrumb-container">
        <div class="layout-breadcrumb-left-items">
            <a href="#" class="menu-button" @click="onMenuButtonClick($event)" v-if="isStatic()">
                <i class="pi pi-bars"></i>
            </a>

            <!-- <Breadcrumb :model="items" class="layout-breadcrumb"></Breadcrumb> -->
            <Breadcrumb :model="items" class="layout-breadcrumb">
                <template #item="{ item }">
                    <div class="p-menuitem-link cursor-pointer">
                        <span v-if="item.icon" class="p-menuitem-text" @click="goTo(item.to)"><i :class="item.icon" style="font-size: 1.25rem"></i></span>
                        <span v-else class="p-menuitem-text" @click="goTo(item.to)">{{ item.label }}</span>
                    </div>
                </template>
            </Breadcrumb>
        </div>
        <div class="layout-breadcrumb-right-items">
            <!-- <a tabindex="0" class="search-icon" @click="breadcrumbClick">
                <i class="pi pi-bell"></i>
            </a> -->

            <!-- <div class="search-wrapper" :class="{ 'active-search-wrapper': searchActive }">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <InputText placeholder="Search..." v-model="search" @click="inputClick" />
                </span>
            </div> -->

            <span class="layout-rightmenu-button-desktop mr-4 mt-2">
                <!-- <Button label="" icon="pi pi-bell" class="p-button-secondary" @click="onRightMenuButtonClick()"></Button> -->
                <Notif v-if="role == 'student' || role == 'teacher'" />
            </span>

            <span class="layout-rightmenu-button-desktop">
                <Button label="Today" icon="pi pi-bookmark" class="layout-rightmenu-button" @click="onRightMenuButtonClick()">{{ times }}</Button>
            </span>
            <!-- <span class="layout-rightmenu-button-mobile">
                <Button icon="pi pi-bookmark" class="layout-rightmenu-button" @click="onRightMenuButtonClick()"></Button>
            </span> -->
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import 'moment/dist/locale/id';
import Notif from './modal_notification.vue';
export default {
    props: {
        menuMode: String,
        searchActive: {
            type: Boolean,
            default: false,
        },
        searchClick: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Notif,
    },
    emits: ['menubutton-click', 'rightmenu-button-click', 'update:searchActive', 'update:searchClick'],
    data() {
        return {
            items: [],
            search: '',
            moment,
            now: '',
        };
    },
    watch: {
        $route() {
            this.watchRouter();
        },
    },
    created() {
        this.watchRouter();
        this.setTimer();
    },
    computed: {
        times() {
            return moment(this.now).format('lll');
        },
        queries() {
            return this.$store.state.livejapan.last_query && this.$store.state.livejapan.last_query != {} ? this.$store.state.livejapan.last_query : null;
        },
        user_id() {
            return this.$route.query ? this.$route.query.id : null;
        },
        last_from() {
            return this.$store.state.livejapan.last_from ? this.$store.state.livejapan.last_from : null;
        },
        role() {
            return this.$store.state.livejapan.aktif_role ? this.$store.state.livejapan.aktif_role : null;
        },
    },
    methods: {
        watchRouter() {
            if (this.$router.currentRoute.value.meta.breadcrumb) {
                this.items = [];
                const bc = this.$router.currentRoute.value.meta.breadcrumb;
                for (let pro in bc) {
                    this.items.push(bc[pro]);
                }
            }
        },
        goTo(x) {
            let vm = this;
            if (vm.items[vm.items.length - 1].to != x) {
                if (vm.last_from) {
                    let kode = x + vm.last_from;
                    let kuery = vm.queries[kode] ? vm.queries[kode] : null;
                    if (kuery) {
                        vm.$router.replace({
                            path: x,
                            query: kuery,
                        });
                    } else {
                        vm.$router.push(x);
                    }
                } else {
                    vm.$router.push(x);
                }
            }
        },
        setTimer() {
            let vm = this;
            let interval = setInterval(() => {
                vm.now = moment();
            }, 1000);
            vm.interval = interval;
            clearInterval(interval - 1);
        },
        onMenuButtonClick(event) {
            this.$emit('menubutton-click', event);
        },
        onRightMenuButtonClick() {
            this.$emit('rightmenu-button-click');
        },
        inputClick() {
            this.$emit('update:searchClick', true);
        },
        breadcrumbClick() {
            this.$emit('update:searchActive', true);
            this.$emit('update:searchClick', true);
        },
        isStatic() {
            return this.menuMode === 'static';
        },
    },
};
</script>

<style scoped>
::v-deep(.p-breadcrumb > ul > li.p-breadcrumb-chevron:first-of-type) {
    display: none;
}
</style>
