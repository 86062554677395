<template>
    <div v-if="display"
        style="width: 100%; flex-direction: column; justify-content: center; align-items: center; gap: 60px; display: inline-flex; background-color: #ffffff">
        <div style="width: 100%; height: 180px; position: relative; background-image: url('../../assets/header.png')"
            :style="{ 'background-image': 'url(' + require('../../assets/header.png') + ')' }"></div>
        <div style="
                width: 600px;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 183.99px;
                padding-right: 183.98px;
                top: 130px;
                position: absolute;
                background: rgba(4, 124, 193, 0.95);
                justify-content: center;
                align-items: center;
                display: inline-flex;
            ">
            <div>
                <p style="text-align: center; color: white; font-size: 30px; font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif;; font-weight: 700">
                    CONTACT</p>
                <p class="mb-3"
                    style="text-align: center; color: white; font-size: 15px; font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif;; line-height: 3px">
                    お問い合わせ</p>
            </div>
        </div>

        <div class="p-0" style="background-color: #ffffff; width: 1150px; height: 100%">
            <div class="flex justify-content-center mt-4">
                <div class="flex justify-content-center align-items-center"
                    style="width: 50%; height: 40px; text-align: center; font-weight: 700; background-color: rgba(4, 124, 193, 0.95); color: white;">
                    入力画面
                </div>
                <div class="flex justify-content-start align-items-center text-align-center"
                    style="width: 50%; height: 40px; transition: background-color 0.5s; background-color: #e3eff6">
                    <div class="segitiga-menghadap-kanan"></div>
                    <div class="" style="text-align: center; margin-left: 170px; font-weight: 700">完了</div>
                </div>
            </div>
            <div style="color: red" class="mt-4">こちらはお客様専用お問い合わせフォームです。営業、売り込みはご遠慮ください。</div>
            <div><span style="color: red">※</span> ※は必須項目です</div>
            <div class="p-fluid formgrid grid">
                <div class="field grid col-12 md:col-12 mt-4">
                    <div class="col-12 mt-4 md:col-3 flex justify-content-end">
                        <label for="given_name">お名前 </label>
                    </div>
                    <div class="flex md:col-4">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-8">
                            <InputText v-model.lazy="data.given_name" placeholder="性"
                                @blur="v$.data.given_name.$touch()"
                                :class="{ 'p-invalid': v$.data.given_name.$invalid ? !v$.data.given_name.$anyError : null }"
                                id="given_name" type="text" />
                            <small v-if="v$.data.given_name.$invalid"
                                :class="{ 'p-error': v$.data.given_name.$invalid ? !v$.data.given_name.$anyError : null }">{{
        v$.data.given_name.$errors[0].$message
    }}</small>
                        </div>
                        <div class="md:col-8">
                            <InputText placeholder="名" v-model.lazy="data.surname" @blur="v$.data.surname.$touch()"
                                :class="{ 'p-invalid': v$.data.surname.$invalid ? !v$.data.surname.$anyError : null }"
                                id="given_name_english" type="text" />
                            <small v-if="v$.data.surname.$invalid"
                                :class="{ 'p-error': v$.data.surname.$invalid ? !v$.data.surname.$anyError : null }">{{
        v$.data.surname.$errors[0].$message
    }}</small>
                        </div>
                    </div>
                </div>

                <div class="field grid col-12 md:col-12">
                    <div class="col-12 mt-4 md:col-3 flex justify-content-end">
                        <label for="given_name_english">フリガナ </label>
                    </div>
                    <div class="flex md:col-4">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-8">
                            <InputText placeholder="セイ" v-model.lazy="data.given_name_furigana"
                                @blur="v$.data.given_name_furigana.$touch()"
                                :class="{ 'p-invalid': v$.data.given_name_furigana.$invalid ? !v$.data.given_name_furigana.$anyError : null }"
                                id="given_name_furigana" type="text" />
                            <small v-if="v$.data.given_name_furigana.$invalid"
                                :class="{ 'p-error': v$.data.given_name_furigana.$invalid ? !v$.data.given_name_furigana.$anyError : null }">{{
        v$.data.given_name_furigana.$errors[0].$message
    }}</small>
                        </div>
                        <div class="md:col-8">
                            <InputText placeholder="メイ" v-model.lazy="data.surname_furigana"
                                @blur="v$.data.surname_furigana.$touch()"
                                :class="{ 'p-invalid': v$.data.surname_furigana.$invalid ? !v$.data.surname_furigana.$anyError : null }"
                                id="surname_furigana" type="text" />
                            <small v-if="v$.data.surname_furigana.$invalid"
                                :class="{ 'p-error': v$.data.surname_furigana.$invalid ? !v$.data.surname_furigana.$anyError : null }">{{
        v$.data.surname_furigana.$errors[0].$message
    }}</small>
                        </div>
                    </div>
                </div>

                <div class="field grid col-12 md:col-12">
                    <div class="col-12 mt-4 md:col-3 flex justify-content-end">
                        <label for="email">Email</label>
                    </div>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <InputText placeholder="example@example.com" v-model.lazy="data.email"
                                @blur="v$.data.email.$touch()"
                                :class="{ 'p-invalid': v$.data.email.$invalid ? !v$.data.email.$anyError : null }"
                                id="email" type="text" />
                            <small v-if="v$.data.email.$invalid"
                                :class="{ 'p-error': v$.data.email.$invalid ? !v$.data.email.$anyError : null }">{{
        v$.data.email.$errors[0].$message }}</small>
                        </div>
                    </div>
                </div>

                <div class="field grid col-12 md:col-12">
                    <div class="col-12 mt-4 md:col-3 flex justify-content-end">
                        <label for="email_confirmation">Email(確認)</label>
                    </div>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <InputText placeholder="example@example.com" v-model.lazy="data.confirm_email"
                                @blur="v$.data.confirm_email.$touch()"
                                :class="{ 'p-invalid': v$.data.confirm_email.$invalid ? !v$.data.confirm_email.$anyError : null }"
                                id="confirm_email_confirmation" type="text" />
                            <div>
                                <small style="color: red;">
                                    ご連絡はメールで差し上げております。
                                    @live-english.jpのドメインを受信できるように設定ください。
                                </small>
                            </div>
                            <div>
                                <small v-if="v$.data.confirm_email.$invalid"
                                    :class="{ 'p-error': v$.data.confirm_email.$invalid ? !v$.data.confirm_email.$anyError : null }">{{
        v$.data.confirm_email.$errors[0].$message
    }}</small>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field grid col-12 md:col-12">
                    <label style="text-align: right" for="content_of_inquiry"
                        class="col-12 md:col-3 mt-2 flex justify-content-end">お問い合わせ内容 <span style="color: red">※</span>
                    </label>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-6">:</span>
                        <div class="md:col-12">
                            <Textarea v-model.lazy="data.content_of_inquiry" @blur="v$.data.content_of_inquiry.$touch()"
                                :class="{ 'p-invalid': v$.data.content_of_inquiry.$invalid ? !v$.data.content_of_inquiry.$anyError : null }"
                                id="content_of_inquiry" type="text" rows="4" />
                            <small v-if="v$.data.content_of_inquiry.$invalid"
                                :class="{ 'p-error': v$.data.content_of_inquiry.$invalid ? !v$.data.content_of_inquiry.$anyError : null }">{{
        v$.data.content_of_inquiry.$errors[0].$message
    }}</small>
                        </div>
                    </div>
                </div>
            </div>
            <div style="text-align: center">
                <div>
                    Live Englishオンライン英会話へのお問い合わせを誠に有難うございます。 <br />
                    2営業日以内にご返信をさせていただきます。
                </div>
                <div class="mt-4">
                    <Button :disabled="!isValid" @click="register_student_inquiry()"
                        style="width: 15%; height: 50px; text-align: center" label="送信する"></Button>
                </div>
            </div>
        </div>
        <!-- <divds style="text-align: center">
            <div>
                Live Englishオンライン英会話へのお問い合わせを誠に有難うございます。 <br />
                2営業日以内にご返信をさせていただきます。
            </div>
            <div class="mt-4">
                <Button :disabled="!isValid" @click="register_student_inquiry()" style="width: 50%; height: 50px; text-align: center" label="送信する"></Button>
            </div>
        </divds> -->

        <div style="height: 50px"></div>
    </div>

    <!-- HALAMAN COMPLETE  -->

    <div v-if="!display"
        style="width: 100%; flex-direction: column; align-items: center; gap: 60px; display: inline-flex; background-color: #ffffff">
        <div style="width: 100%; height: 180px; position: relative; background-image: url('../../assets/header.png')"
            :style="{ 'background-image': 'url(' + require('../../assets/img03.png') + ')' }"></div>
        <div style="
                width: 600px;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 183.99px;
                padding-right: 183.98px;
                top: 130px;
                position: absolute;
                background: rgba(4, 124, 193, 0.95);
                justify-content: center;
                align-items: center;
                display: inline-flex;
            ">
            <div>
                <p style="text-align: center; color: white; font-size: 30px; font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif;; font-weight: 700">
                    THANKS</p>
                <p class="mb-3"
                    style="text-align: center; color: white; font-size: 15px; font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif;; line-height: 3px">
                    Inquiry (completed)</p>
            </div>
        </div>

        <div class="p-0" style="background-color: #ffffff; width: 1150px;; height: 100%">
            <div class="mt-4">
                <div class="flex">
                    <div class="flex justify-content-center align-items-center text-align-center"
                        style="width: 50%; height: 40px; font-weight: 700; background-color: #e3eff6">入力画面</div>
                    <div class="flex justify-content-center align-items-center text-align-center"
                        style="width: 50%; height: 40px; font-weight: 700; transition: background-color 0.5s; background-color: rgba(4, 124, 193, 0.95); color:white;">
                        完了
                    </div>
                    <div class="segitiga-menghadap-kanan"></div>
                </div>
            </div>
            <div class="flex justify-content-start mt-8">
                <div style="text-align: left">
                    <div> <b>お問い合わせをいただきまして誠にありがとうございます。</b> </div>
                    <div>
                        <p>2営業日以内にご登録のメールアドレスへご連絡をさせていただきます。</p>
                    </div>
                    <div>
                        <p>もし3日経ってご連絡がございません場合は、大変恐れ入りますが support@live-english.jp までメール</p>
                    </div>
                    <div>
                        <p>にてご連絡ください。</p>
                    </div>
                </div>
            </div>
        </div>

        <div style="height: 350px"></div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { useRouter } from 'vue-router';
import { required, email, sameAs, helpers } from '@vuelidate/validators';
export default {
    setup() {
        const router = useRouter();
        return { v$: useVuelidate({ $lazy: true, $autoDirty: true }), router };
        // const background = require('@/assets/header.png');
    },
    data() {
        return {
            data: {
                id: null,
                given_name: null,
                surname: null,
                given_name_furigana: null,
                surname_furigana: null,
                email: null,
                confirm_email: null,
                content_of_inquiry: null,
                type_user: 'Inquiry',
                ms_role_id: 'student',
                is_registered: 1,
                status_student: 'New',
            },
            checkboxValue: [],
            display: true,
        };
    },
    computed: {
        formString() {
            return JSON.stringify(this.data);
        },
        isValid() {
            return !this.v$.$invalid;
        },
        isDirty() {
            return this.v$.$anyDirty;
        },
    },
    validations() {
        return {
            data: {
                given_name: {
                    required,
                },
                surname: {
                    required,
                },
                given_name_furigana: {
                    required,
                },
                surname_furigana: {
                    required,
                },
                email: {
                    required,
                    email,
                },
                confirm_email: {
                    required,
                    email,
                    sameAsPassword: helpers.withMessage('Confirm Email not match', sameAs(this.data.email)),
                    // minLength: minLength(8)
                },
                content_of_inquiry: {
                    required,
                },
                ms_role_id: {
                    required,
                },
            },
        };
    },
    methods: {
        register_student_inquiry() {
            let vm = this;
            if (vm.isValid && vm.isDirty) {
                vm.loading = true;
                vm.$axios
                    .post('user/register', vm.data)
                    .then((res) => {
                        // console.log(res.data.data[0].id, "lhaiki");
                        if (res.data.status == 200) {
                            if (res.data.message == 'success') {
                                vm.data = {
                                    id: null,
                                    given_name: null,
                                    surname: null,
                                    given_name_furigana: null,
                                    surname_furigana: null,
                                    email: null,
                                    confirm_email: null,
                                    content_of_inquiry: null,
                                    type_user: 'Inquiry',
                                    ms_role_id: 'teacher',
                                    is_registered: 2,
                                    student_status: 'New',
                                };
                                vm.v$.$reset();
                                vm.display = false;
                                vm.$toast.add({ severity: 'success', summary: 'Confirmation', detail: 'Register Student Inquiry Success', life: 3000 });
                            } else {
                                vm.loading = false;
                                vm.$toast.add({ severity: 'warn', summary: 'Confirmation', detail: res.data.message, life: 3000 });
                            }
                        } else {
                            vm.loading = false;
                            if (res.data.status == 204) {
                                vm.data = {
                                    id: null,
                                    given_name: null,
                                    surname: null,
                                    given_name_furigana: null,
                                    surname_furigana: null,
                                    email: null,
                                    confirm_email: null,
                                    content_of_inquiry: null,
                                    type_user: 'Inquiry',
                                    ms_role_id: 'teacher',
                                    is_registered: 2,
                                    student_status: 'New',
                                };
                                vm.v$.$reset();
                                vm.display = false;
                                vm.$toast.add({ severity: 'success', summary: 'Confirmation', detail: 'Register Student Inquiry Success', life: 3000 });
                            }
                        }
                    })
                    .catch((err) => {
                        vm.loading = false;
                        console.log(err);
                        vm.$toast.add({ severity: 'error', summary: 'Confirmation', detail: 'There is something wrong with the server', life: 3000 });
                    });
            } else {
                vm.loading = false;
                vm.$toast.add({ severity: 'error', summary: 'Warning', detail: 'There are still invalid data entries', life: 3000 });
            }
        },
    },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Yu+Gothic:wght@400;500&family=Meiryo&display=swap');
body {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}
.segitiga-menghadap-kanan {
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 30px solid rgba(4, 124, 193, 0.95);
    transition: border-left 0.5s;
}
</style>
