import router from '../router';

const state = {
    login_token: null,
    login_role: null,
    login_data: null,
    aktif_role: null,
    aktif_login_data: null,
    aktif_login_token: null,
    loading: false,
    alert: '',
    show: false,
    backColor: '',
    data_edit: null,
    menuMode: 'sidebar',
    sideBarStatic: false,
    scheme: 'light',
    theme: null,
    timezone_aktif: null,
    last_query: {},
    last_from: null,
    recent_teacher: [],
    recent_student: [],
    seen_notif: [],
    login_warning: null,
};

const mutations = {
    userlogin(state, tokens) {
        // console.log(tokens, 'user login store');
        state.login_token = tokens.token;
        state.aktif_login_token = tokens.token;
        state.login_role = tokens.ms_role_id;
        state.aktif_role = tokens.ms_role_id;
    },
    userLoginAs(state, data) {
        state.aktif_login_data = data;
        state.aktif_role = data.ms_role_id;
        router.push('/dashboard');
    },
    lock_side_bar(state, bar) {
        state.sideBarStatic = bar;
        // console.log('bar', bar, state.sideBarStatic);
    },
    login_warning(state, bar) {
        state.login_warning = null;
        state.login_warning = bar;
        // console.log('bar', bar, state.sideBarStatic);
    },
    backToAdmin(state) {
        state.aktif_login_data = state.login_data;
        state.aktif_role = state.login_role;
    },
    check_notif(state, newnotif) {
        newnotif.forEach((i) => {
            if (!state.seen_notif.includes(i.iden)) {
                state.seen_notif.push(i.iden);
            }
        });
    },
    userCheck(state, data) {
        // console.log(data,'iki data')
        state.login_data = data;
        if (state.login_data && state.aktif_login_data) {
            if (state.login_data.user_id == state.aktif_login_data.user_id) {
                state.aktif_login_data = data;
            }
        } else {
            state.aktif_login_data = data;
        }
    },
    changeMenuMode(state, menu) {
        console.log(menu);
        state.menuMode = menu;
    },
    recent_student(state, student) {
        let x = state.recent_student;
        let inclu = x.filter((i) => {
            return student == i;
        });
        // console.log(inclu);
        let index = state.recent_student.indexOf(inclu[0]);
        if (x.length <= 30) {
            if (index !== -1) {
                state.recent_student.splice(index, 1);
                state.recent_student.push(student);
            } else {
                state.recent_student.push(student);
            }
        } else if (x.length > 30) {
            state.recent_student.splice(0, 1);
            state.recent_student.push(student);
        }
    },
    recent_teacher(state, teacher) {
        let x = state.recent_teacher;
        let inclu = x.filter((i) => {
            return i == teacher;
        });
        let index = state.recent_teacher.indexOf(inclu[0]);
        if (x.length <= 30) {
            if (index !== -1) {
                state.recent_teacher.splice(index, 1);
                state.recent_teacher.push(teacher);
            } else {
                state.recent_teacher.push(teacher);
            }
        } else if (x.length > 30) {
            state.recent_teacher.splice(0, 1);
            state.recent_teacher.push(teacher);
        }
    },
    change_color_scheme(state, scheme) {
        state.scheme = scheme;
    },
    set_last_query(state, route) {
        let key = (route.path += route.query.id ? route.query.id : '');
        state.last_query[key] = route.query;
    },
    set_last_from(state, route) {
        let key = route.query.id ? route.query.id : '';
        state.last_from = key;
    },
    set_role_aktif(state, role) {
        state.aktif_role = role;
    },
    set_clear_token(state) {
        console.log('set_clear');
        state.login_token = null;
        state.login_role = null;
        state.aktif_role = null;
        state.login_data = null;
        state.aktif_login_data = null;
        state.aktif_login_token = null;
    },
    set_loading_state(state, value) {
        state.loading = value;
    },
    set_user_level(state, value) {
        state.user_level = value;
        state.level_aktif = value.level;
    },
    show_alert_success(state, value) {
        state.alert = value.toUpperCase();
        state.backColor = 'background-color: #91f086';
        state.show = true;
    },
    show_alert_fail(state, value) {
        state.alert = value.toUpperCase();
        state.backColor = 'background-color: #FF6962';
        state.show = true;
    },
    hide_alert(state) {
        state.alert = '';
        state.show = false;
    },
    set_timezone(state, value) {
        // console.log(value);
        state.timezone_aktif = value;
    },
};
const actions = {
    save_token_login({ commit }, log_respon) {
        // console.log(log_respon,'store login')
        commit('set_login_token', log_respon);
    },
    save_token_intercept({ commit }, int_respon) {
        commit('set_intercept_token', int_respon);
    },
    clear_token({ commit }) {
        commit('set_clear_token');
    },
    set_loading({ commit }, value) {
        commit('set_loading_state', value);
    },
    set_alert_show_success({ commit }, value) {
        commit('show_alert_success', value);
    },
    set_alert_show_fail({ commit }, value) {
        commit('show_alert_fail', value);
    },
    set_alert_hide({ commit }) {
        commit('hide_alert');
    },
};
//   const modules = {};
// const plugins = [createPersistedState({
//   storage: {
//     getItem: key => Cookies.get(key),
//     setItem: (key, value) => Cookies.set(key, value, { expires: 3, secure: true }),
//     removeItem: key => Cookies.remove(key)
//   }
// })];
//   const plugins = [createPersistedState()];
export const livejapan = {
    state,
    mutations,
    actions,
    // modules,
    // plugins,
};
