<template>
    <div v-if="display" style="width: 100%; flex-direction: column; justify-content: center; align-items: center; gap: 60px; display: inline-flex">
        <div
            style="width: 100%; height: 180px; position: relative; background-image: url('../../assets/header.png')"
            :style="{ 'background-image': 'url(' + require('../../assets/header.png') + ')' }"
        ></div>
        <div
            style="
                width: 600px;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 183.99px;
                padding-right: 183.98px;
                top: 130px;
                position: absolute;
                background: rgba(4, 124, 193, 0.95);
                justify-content: center;
                align-items: center;
                display: inline-flex;
            "
        >
            <div>
                <p style="text-align: center; color: white; font-size: 30px; font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif; font-weight: 700">Jobs@Live</p>
                <p class="mb-3" style="text-align: center; color: white; font-size: 15px; font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif; line-height: 3px">Thrive in the digital normal</p>
            </div>
        </div>

        <div class="flex justify-content-center align-items-center mt-4">
            <div style="font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif; font-weight: 700; text-align: center; font-size: 28px; color: #047cc1">Application Form</div>
        </div>

        <div style="background-color: #efefef; width: 1150px;; height: 800px">
            <div class="mt-3 flex justify-content-center align-items-center" style="font-size: 16px; font-weight: 700">Personal Information</div>
            <div class="p-fluid formgrid grid ml-3 mr-3 mt-4">
                <div class="field col-12 md:col-6">
                    <label style="color: #047cc1; font-size: 16px" for="first_name">First Name*</label>
                    <InputText
                        v-model.lazy="data.first_name"
                        @blur="v$.data.first_name.$touch()"
                        :class="{ 'p-invalid': v$.data.first_name.$invalid ? !v$.data.first_name.$anyError : null }"
                        id="first_name"
                        type="text"
                    />
                    <small v-if="v$.data.first_name.$invalid" :class="{ 'p-error': v$.data.first_name.$invalid ? !v$.data.first_name.$anyError : null }">{{
                        v$.data.first_name.$errors[0].$message
                    }}</small>
                </div>
                <div class="field col-12 md:col-6">
                    <label style="color: #047cc1; font-size: 16px" for="first_name">Last Name*</label>
                    <InputText
                        v-model.lazy="data.last_name"
                        @blur="v$.data.last_name.$touch()"
                        :class="{ 'p-invalid': v$.data.last_name.$invalid ? !v$.data.last_name.$anyError : null }"
                        id="last_name"
                        type="text"
                    />
                    <small v-if="v$.data.last_name.$invalid" :class="{ 'p-error': v$.data.last_name.$invalid ? !v$.data.last_name.$anyError : null }">{{
                        v$.data.last_name.$errors[0].$message
                    }}</small>
                </div>

                <div class="field col-12 md:col-12">
                    <label style="color: #047cc1; font-size: 16px" for="first_name">Email Application*</label>
                    <InputText
                        v-model.lazy="data.email_application"
                        @blur="v$.data.email_application.$touch()"
                        :class="{ 'p-invalid': v$.data.email_application.$invalid ? !v$.data.email_application.$anyError : null }"
                        id="email_application"
                        type="text"
                    />
                    <small v-if="v$.data.email_application.$invalid" :class="{ 'p-error': v$.data.email_application.$invalid ? !v$.data.email_application.$anyError : null }">{{
                        v$.data.email_application.$errors[0].$message
                    }}</small>
                </div>
                <div class="field col-12 md:col-12">
                    <label style="color: #047cc1; font-size: 16px" for="ms_nation_id">Nationality*</label>
                    <Dropdown id="ms_nation_id" type="text" v-model.lazy="data.ms_nation_id" :options="nations" optionValue="ms_nation_id" optionLabel="nation_name" placeholder="Select nations" />
                    <!-- <small v-if="v$.data.ms_nation_id.$invalid" :class="{ 'p-error': v$.data.ms_nation_id.$invalid ? !v$.data.ms_nation_id.$anyError : null }">{{ v$.data.ms_nation_id.$errors[0].$message }}</small> -->
                </div>
                <div class="field col-12 md:col-12">
                    <label style="color: #047cc1; font-size: 16px" for="first_name">Resume/CV*</label>
                    <div @click="upload()" @dragover.prevent @drop="handleDrop" style="width: 100%; height: 150px; border: 1px dotted; border-radius: 8px; flex-direction: column">
                        <!-- <div class="material-icons ml-8 mb-8 " style="color:#047cc1"> file_save </div> -->
                        <div class="flex justify-content-center align-items-center text-align-center mt-3">
                            <img src="@/assets/pdf-file.png" alt="ada" />
                        </div>
                        <div v-if="data.cv_application" class="flex justify-content-center align-items-center">
                            {{ data.cv_application.name }}
                            <!-- <Button class="text-red" color="light" square @click="deletefile()">
                                    <i class="pi pi-trash" />
                                </Button> -->
                        </div>
                        <!-- <div class="mr-3" v-for="(item, index) in cv_application" :key="index" @click="viewFoto(item.filenya)">
                            <div style="position: absolute">
                              <div v-if="file1">
                              halo
                              </div>
                                <Button class="text-red" color="light" style="margin-left: 100px; margin-top: 120px" square @click="deleteItem(index)">
                                    <i class="pi pi-trash" />
                                </Button>
                            </div>
                            <Image :src="item.src" width="140"/>
                        </div> -->
                        <div class="flex justify-content-center text-align-center mt-4" style="font-size: 16px">
                            <p>Click or drag file to this area to upload</p>
                        </div>
                        <div class="flex justify-content-center text-align-center" style="font-size: 14px; color: #6c757d">
                            <p>Support for a single or bulk upload. Maximum file size 1MB</p>
                        </div>
                    </div>
                    <small v-if="v$.data.cv_application.$invalid" :class="{ 'p-error': v$.data.cv_application.$invalid ? !v$.data.cv_application.$anyError : null }">{{
                        v$.data.cv_application.$errors[0].$message
                    }}</small>
                    <input class="hai" ref="file1" type="file" ondrop="dropHandler(event)" @input="handleFile1()" accept="application/pdf" />
                </div>

                <div class="field col-12 md:col-12">
                    <label style="color: #047cc1; font-size: 16px" for="first_name">Cover Letter*</label>
                    <div @click="upload2()" @dragover.prevent @drop="handleDropCoverLetter" style="width: 100%; height: 150px; border: 1px dotted; border-radius: 8px; flex-direction: column">
                        <!-- <div class="material-icons ml-8 mb-8 " style="color:#047cc1"> file_save </div> -->
                        <div class="flex justify-content-center align-items-center text-align-center mt-3" style="color: grey">
                            <img src="@/assets/pdf-file.png" alt="" />
                        </div>
                        <div v-if="data.cover_letter_application" class="flex justify-content-center align-items-center">{{ data.cover_letter_application.name }} {{}}</div>
                        <div class="flex justify-content-center text-align-center mt-4" style="font-size: 16px">
                            <p>Click or drag file to this area to upload</p>
                        </div>
                        <div class="flex justify-content-center text-align-center" style="font-size: 14px; color: #6c757d">
                            <p>Support for a single or bulk upload. Maximum file size 1MB</p>
                        </div>
                    </div>
                    <small v-if="v$.data.cover_letter_application.$invalid" :class="{ 'p-error': v$.data.cover_letter_application.$invalid ? !v$.data.cover_letter_application.$anyError : null }">{{
                        v$.data.cover_letter_application.$errors[0].$message
                    }}</small>
                    <input class="hai" ref="file2" type="file" ondrop="dropHandler(event);" @input="handleFile2()" accept="application/pdf" />
                    <div class="field-checkbox">
                        <Checkbox id="checkOption2" name="option" :binary="true" @change="validasiCheckbox" v-model="checkboxValue" />
                        <label for="checkOption2">Before submitting this application you must agree to Live English’s Privacy Policy</label>
                    </div>
                </div>
            </div>
            <div class="flex justify-content-center align-items-center">
                <Button :disabled="!isValid || loading || checkbox" @Click="register_teacher()" style="width: 50%; height: 40%" label="SUBMIT" class="mr-2 mb-2" />
            </div>
        </div>
    </div>

    <!-- halaman confirmation -->

    <div v-if="!display" style="width: 100%; flex-direction: column; justify-content: center; align-items: center; gap: 60px; display: inline-flex">
        <div
            style="width: 100%; height: 280px; position: relative; background-image: url('../../assets/img03.png')"
            :style="{ 'background-image': 'url(' + require('../../assets/img03.png') + ')' }"
        ></div>
        <div
            style="
                width: 600px;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 183.99px;
                padding-right: 183.98px;
                top: 230px;
                position: absolute;
                background: rgba(4, 124, 193, 0.95);
                justify-content: center;
                align-items: center;
                display: inline-flex;
            "
        >
            <div>
                <p style="text-align: center; color: white; font-size: 30px; font-family: YuGothic; font-weight: 700">THANKS</p>
                <p class="mb-3" style="text-align: center; color: white; font-size: 15px; font-family: YuGothic">Thank you {{ nama }} for your application!</p>
            </div>
        </div>
        <div class="mt-8">
            <div>
                <p style="text-align: center; font-size: 15px; font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif; font-weight: 400">Your application will be reviewed by our recruiting team at Live English</p>
                <p class="mt-6" style="text-align: center; font-size: 15px; font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif; line-height: 3px">If your application is found to meets the needs of the position,</p>
                <p style="text-align: center; font-size: 15px; font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif; line-height: 3px">a Live English representative will contact you to proceed to the next stage of the process</p>
            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { useRouter } from 'vue-router';
import { required, email } from '@vuelidate/validators';
export default {
    setup() {
        const router = useRouter();
        return { v$: useVuelidate({ $lazy: true, $autoDirty: true }), router };
    },
    data() {
        return {
            data: {
                id: null,
                first_name: null,
                last_name: null,
                email_application: null,
                ms_nation_id: null,
                cv_application: null,
                cover_letter_application: null,
                ms_role_id: 'teacher',
                status_student: 'New',
            },
            nama: null,
            loading: false,
            nations: [],
            checkboxValue: null,
            bebas: null,
            show: true,
            max: true,
            fotoModal: false,
            fotonya: '',
            checkbox: true,
            display: true,
            // file1: []
        };
    },
    computed: {
        formString() {
            return JSON.stringify(this.data);
        },
        isValid() {
            return !this.v$.$invalid;
        },
        isDirty() {
            return this.v$.$anyDirty;
        },
    },
    validations() {
        return {
            data: {
                first_name: {
                    required,
                },
                last_name: {
                    required,
                },
                email_application: {
                    required,
                    email,
                },
                // ms_nation_id: {
                //     required,
                // },
                cv_application: {
                    required,
                },
                cover_letter_application: {
                    required,
                },
            },
        };
    },
    mounted() {
        this.get_datas();
        // this.register_teacher()
    },
    methods: {
        upload() {
            console.log('iki file upload');
            let inputElement = this.$refs.file1;
            inputElement.click();
        },
        upload2() {
            console.log('iki');
            let inputElement = this.$refs.file2;
            inputElement.click();
        },
        // handleFile1() {
        //     let vm = this;
        //     vm.data.cv_application = vm.$refs.file1.files[0];
        //     console.log(vm.data.cv_application);

        // },
        handleFile1() {
            let vm = this;
            let file = vm.$refs.file1.files[0];
            if (file.size <= 1048576) {
                // 1 MB in bytes
                vm.data.cv_application = file;
                console.log(vm.data.cv_application);
            } else {
                // File size is too large, reset the input
                vm.$refs.file1.value = null;
                vm.$toast.add({
                    severity: 'error',
                    summary: 'Warn',
                    detail: 'CV/Application file size exceeds 1MB',
                    life: 3000,
                });
            }
        },
        deletefile() {
            console.log('iki delete file');
            this.data.cv_application = null;
        },
        // handleFile2() {
        //     let vm = this;
        //     vm.data.cover_letter_application = vm.$refs.file1.files[0];
        //     console.log(vm.data.cover_letter_application);
        //     // vm.src = URL.createObjectURL(vm.file1)
        //     // console.log(vm.src, 'ini file1');
        //     // let x = {}
        //     // x.file2 = vm.file2
        //     // x.src = URL.createObjectURL(vm.file2)
        //     // vm.cover_letter_application.push(x)
        //     // console.log(vm.cover_letter_application, 'ini list foto');
        // },
        handleFile2() {
            let vm = this;
            let file = vm.$refs.file2.files[0];
            if (file.size <= 1048576) {
                // 1 MB in bytes
                vm.data.cover_letter_application = file;
                console.log(vm.data.cover_letter_application);
            } else {
                // File size is too large, reset the input
                vm.$refs.file2.value = null;
                vm.$toast.add({
                    severity: 'error',
                    summary: 'Warning',
                    detail: 'Cover Letter file size exceeds 1MB',
                    life: 3000,
                });
            }
        },
        selectFile1() {
            // console.log('ajksldhklashfjkahsl');
            let fileInputElement = this.$refs.file1;
            fileInputElement.click();
        },
        selectFile2() {
            // console.log('ajksldhklashfjkahsl');
            let fileInputElement = this.$refs.file2;
            fileInputElement.click();
        },
        viewFoto(x) {
            this.fotonya = x;
            // console.log(this.fotonya, 'ini x');
            this.fotoModal = true;
        },
        validasiCheckbox() {
            console.log('hiii');
            if (this.checkboxValue) {
                this.checkbox = false;
            } else {
                this.checkbox = true;
            }
        },
        async get_datas() {
            let vm = this;
            try {
                let nations = await vm.$axios.post('ms_nation/list');
                let data = nations.data.data;
                vm.nations = data.map((item) => item);
                // console.log(vm.nations, 'iki nations');
            } catch (err) {
                console.log(err);
            }
        },
        async register_teacher() {
            let vm = this;
            vm.v$.$touch();
            if (vm.isValid && vm.isDirty) {
                let formData = new FormData();
                formData.append('first_name', vm.data.first_name);
                formData.append('last_name', vm.data.last_name);
                formData.append('email_application', vm.data.email_application);
                formData.append('ms_nation_id', vm.data.ms_nation_id);
                formData.append('ms_role_id', vm.data.ms_role_id);
                formData.append('file1', vm.data.cv_application);
                formData.append('file2', vm.data.cover_letter_application);
                vm.$axios
                    .post('application_form/register', formData)
                    .then((res) => {
                        if (res.data.status == 200) {
                            if (res.data.message == 'success') {
                                // vm.data = {
                                //     first_name: null,
                                //     last_name: null,
                                //     email_aplication: null,
                                //     ms_nation_id: null,
                                //     cv_application: null,
                                //     cover_letter_application: null,
                                // };
                                vm.nama = res.data.data[0].first_name;
                                vm.display = false;
                                // console.log(vm.nama, 'inilah namanya');
                                vm.$toast.add({ severity: 'success', summary: 'Confirmation', detail: 'Registration Success', life: 3000 });
                            } else {
                                vm.loading = false;
                                vm.display = false;
                                vm.$toast.add({ severity: 'success', summary: 'Confirmation', detail: 'Registration Success', life: 3000 });
                                // vm.$toast.add({ severity: 'warn', summary: 'Confirmation', detail: res.data.message, life: 3000 });
                            }
                        } else {
                            vm.loading = false;
                            if (res.data.status == 204) {
                                vm.$toast.add({ severity: 'warn', summary: 'Confirmation', detail: res.data.message, life: 3000 });
                            }
                        }
                    })
                    .catch((err) => {
                        vm.loading = false;
                        console.log(err);
                        vm.$toast.add({ severity: 'error', summary: 'Confirmation', detail: 'There is something wrong with the server', life: 3000 });
                    });
            } else {
                vm.loading = false;
                vm.$toast.add({ severity: 'error', summary: 'Warning', detail: 'There are still invalid data entries', life: 3000 });
            }
        },
        handleDrop(event) {
            event.preventDefault();
            const files = event.dataTransfer.files;
            if (files.length > 0) {
                let file = files[0];
                this.data.cv_application = file;
                // console.log('File dropped:', file);
            }
        },
        handleDropCoverLetter(event) {
            event.preventDefault();
            const files = event.dataTransfer.files;
            if (files.length > 0) {
                // Hanya mengambil file pertama jika Anda ingin membatasi satu file
                const file = files[0];
                this.data.cover_letter_application = file;
                // console.log('File dropped:', file);
            }
        },
    },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Yu+Gothic:wght@400;500&family=Meiryo&display=swap');
body {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}
.hai {
    visibility: hidden;
}
img {
    width: 50px;
    height: 50px;
}
</style>
