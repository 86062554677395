<template>
    <div v-if="display" style="width: 100%; flex-direction: column; justify-content: center; align-items: center; gap: 60px; display: inline-flex; background-color: #ffffff">
        <div
            style="width: 100%; height: 180px; position: relative; background-image: url('../../assets/header.png')"
            :style="{ 'background-image': 'url(' + require('../../assets/header.png') + ')' }"
        ></div>
        <div
            style="
                width: 600px;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 183.99px;
                padding-right: 183.98px;
                top: 130px;
                position: absolute;
                background: rgba(4, 124, 193, 0.95);
                justify-content: center;
                align-items: center;
                display: inline-flex;
            "
        >
            <div>
                <p style="text-align: center; color: white; font-size: 30px;  font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif;  font-weight: 700">Trial Lesson</p>
                <p class="mb-3" style="text-align: center; color: white; font-size: 15px; font-family: Yu Gothic; line-height: 3px">無料体験レッスンお申し込み</p>
            </div>
        </div>

        <div style="background-color: #ffffff; width: 1150px; height: 100%">
            <div class="flex justify-content-start align-items-center mt-4">
                <div style="font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif; font-weight: 700; text-align: center; font-size: 28px; color: #047cc1">
                    <span style="border-bottom: 4px solid #047cc1; border-top-left-radius: 3px">これ</span>からの学習課題がわかる、欧米ネイティブ講師との体験レッスン
                </div>
            </div>

            <div class="mt-4">
                <img class="img-smile" style="width: 100%; height: auto" src="@/assets/header2.png" alt="" />
            </div>
            <div style="width: 100%; height: 150px; border: 1px solid; border-radius: 8px; position: relative" class="flex justify-content-center mt-4">
                <div class="flex justify-content-center col-12 md:col-4" style="flex-direction: column">
                    <div class="flex justify-content-center">
                        <Button class="p-button-text" style="font-size: 16px; font-weight: 700">Step 01</Button>
                    </div>
                    <div style="text-align: center; color: #333333; font-size: 20px; font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif; font-weight: 700; line-height: 32px; word-wrap: break-word">無料体験レッスンお申 込み</div>
                </div>
                <div class="col-12 md:col-8 mt-5 ml-3" style="flex-direction: column">
                    <div class="mr-8" style="text-align: left; color: #333333; font-size: 16px; font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif; font-weight: 300; line-height: 32px; word-wrap: break-word">
                        ページ下部の無料体験レッスンお申込みフォームよりお申込みください。日程の調整・確定のため、ご登録のメールアド
                        レス宛にご連絡をさしあげます。お申し込み前までにSkypeアカウントをご準備ください。
                    </div>
                </div>
                <div class="line">
                    <div class="arrow">
                        <div class="pin"></div>
                        <div class="pin"></div>
                    </div>
                </div>
            </div>
            <br />

            <div style="width: 100%; height: 150px; border: 1px solid; border-radius: 8px; position: relative" class="flex justify-content-center mt-4">
                <div class="flex justify-content-center col-12 md:col-4" style="flex-direction: column">
                    <div class="flex justify-content-center">
                        <Button class="p-button-text" style="font-size: 16px; font-weight: 700">Step 02</Button>
                    </div>
                    <div style="text-align: center; color: #333333; font-size: 20px; font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif; font-weight: 700; line-height: 32px; word-wrap: break-word">
                        事前カウンセリング (体験当日)
                    </div>
                </div>
                <div class="col-12 md:col-8 mt-5 ml-3" style="flex-direction: column">
                    <div class="mr-8" style="text-align: left; color: #333333; font-size: 16px; font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif; font-weight: 300; line-height: 32px; word-wrap: break-word">
                        まず日本人スタッフよりご連絡を差し上げます。スカイプ音声のチェックと共に、学習目的や課題などの事前カウンセリン
                        グを行い、担当講師に引き継ぎをいたします。初級レベルの方、オンライン英会話学習が初めて
                        の方もご安心ください。
                        <div class="line">
                            <div class="arrow">
                                <div class="pin"></div>
                                <div class="pin"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />

            <div style="width: 100%; height: 150px; border: 1px solid; border-radius: 8px; position: relative" class="flex justify-content-center mt-4">
                <div class="flex justify-content-center col-12 md:col-4" style="flex-direction: column">
                    <div class="flex justify-content-center">
                        <Button class="p-button-text" style="font-size: 16px; font-weight: 700">Step 03</Button>
                    </div>
                    <div style="text-align: center; color: #333333; font-size: 20px; font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif; font-weight: 700; line-height: 32px; word-wrap: break-word">
                        30分間のレッスン (体 験当日)
                    </div>
                </div>
                <div class="col-12 md:col-8 mt-5 ml-3" style="flex-direction: column">
                    <div class="mr-8" style="text-align: left; color: #333333; font-size: 16px; font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif; font-weight: 300; line-height: 32px; word-wrap: break-word">
                        ネイティブ講師とマンツーマンでレッスンをご受講いただきます。自己紹介から入り、体験用ワークシート等を使用しなが らあなたの学習に適したレベルを選定します。
                    </div>
                    <div class="line">
                        <div class="arrow">
                            <div class="pin"></div>
                            <div class="pin"></div>
                        </div>
                    </div>
                </div>
            </div>
            <br />

            <div style="width: 100%; height: 150px; border: 1px solid; border-radius: 8px; position: relative" class="flex justify-content-center mt-4">
                <div class="flex justify-content-center col-12 md:col-4" style="flex-direction: column">
                    <div class="flex justify-content-center">
                        <Button class="p-button-text" style="font-size: 16px; font-weight: 700">Step 04</Button>
                    </div>
                    <div style="text-align: center; color: #333333; font-size: 20px; font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif; font-weight: 700; line-height: 32px; word-wrap: break-word">
                        学習相談とご案内 (体 験当日)
                    </div>
                </div>
                <div class="col-12 md:col-8 mt-5 ml-3" style="flex-direction: column">
                    <div class="mr-8" style="text-align: left; color: #333333; font-size: 16px; font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif; font-weight: 300; line-height: 32px; word-wrap: break-word">
                        レッスンへのご要望や講師からのフィードバックをもとに、日本人スタッフと今後のレッスンの進め方を決めます。システ
                        ム・コースのご案内も致しますので、ご不安な点はどんな事でもご相談ください。
                        <div class="line">
                            <div class="arrow">
                                <div class="pin"></div>
                                <div class="pin"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />

            <div style="width: 100%; height: 150px; border: 1px solid; border-radius: 8px; position: relative" class="flex justify-content-center mt-4">
                <div class="flex justify-content-center col-12 md:col-4" style="flex-direction: column">
                    <div class="flex justify-content-center">
                        <Button class="p-button-text" style="font-size: 16px; font-weight: 700">Step 05</Button>
                    </div>
                    <div style="text-align: center; color: #333333; font-size: 20px; font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif; font-weight: 700; line-height: 32px; word-wrap: break-word">
                        コースお申込み&ご予 約開始
                    </div>
                </div>
                <div class="col-12 md:col-8 mt-5 ml-3" style="flex-direction: column">
                    <div class="mr-8" style="text-align: left; color: #333333; font-size: 16px; font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif; font-weight: 300; line-height: 32px; word-wrap: break-word">
                        弊社サービスを気に入っていただけましたら、お好きなコースをお申し込み下さい。経験豊富なネイティブ講師があなた との学習を楽しみにお待ちしています。
                    </div>
                    <div class="line">
                        <div class="arrow">
                            <div class="pin"></div>
                            <div class="pin"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif; font-weight: 700; font-size: 28px; color: #047cc1" class="mt-8">
                <span style="border-bottom: 4px solid #047cc1; border-top-left-radius: 3px">無料</span> 体験レッスン申し込みフォーム
            </div>

            <div class="flex mt-4">
                <div
                    class="flex justify-content-center align-items-center"
                    :style="{ 'background-color': par_color, color: color_text }"
                    style="width: 33%; height: 40px; text-align: center; font-weight: 700"
                >
                    入力画面
                </div>
                <div
                    class="flex justify-content-start align-items-center text-align-center"
                    style="width: 33%; height: 40px; transition: background-color 0.5s"
                    :style="{ 'background-color': par_color1 }"
                >
                    <div class="segitiga-menghadap-kanan"></div>
                    <div class="" style="text-align: center; margin-left: 170px; font-weight: 700">確認画面</div>
                    <!-- <i style="font-size: 43px; color: rgba(4, 124, 193, 0.95); margin-bottom: 20px;" class="fa fa-play " aria-hidden="true"></i> -->
                </div>
                <div
                    class="flex justify-content-start align-items-center text-align-center"
                    style="width: 33%; height: 40px; background-color: #e3eff6; transition: background-color 0.5s"
                    :style="{ 'background-color': par_color2 }"
                >
                    <!-- <div :class="{ 'segitiga-menghadap-kanan2': par, 'segitiga-menghadap-kanan': !par }"></div> -->
                    <div class="" style="text-align: center; margin-left: 170px; font-weight: 700">完了</div>
                    <!-- <i style="font-size: 43px; color: rgba(4, 124, 193, 0.95); margin-bottom: 20px;" class="fa fa-play " aria-hidden="true"></i> -->
                </div>
            </div>
            <!-- <Button class="mt-5" @click="ubah_warna">Ubah warna</Button>

            <Button class="mt-5" @click="ubah_warna1">Ubah warna</Button>
            <Button class="mt-5" @click="ubah_warna2">Ubah warna</Button> -->
            <div class="flex mt-4">
                <p style="color: red">※</p>

                <p>は必須項目です。</p>
            </div>
            <div class="p-fluid formgrid grid">
                <!-- firstname english -->
                <div class="field grid col-12 md:col-12">
                    <div class="col-12 mt-4 md:col-3 flex justify-content-end">
                        <label for="given_name_english" style="font-size: 14px; color: black; font-weight: 700">お名前 <span style="color: red">※</span></label>
                    </div>
                    <div class="flex md:col-4">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-8">
                            <InputText
                                placeholder="姓"
                                v-model.lazy="data.given_name"
                                @blur="v$.data.given_name.$touch()"
                                :class="{ 'p-invalid': v$.data.given_name.$invalid ? !v$.data.given_name.$anyError : null }"
                                id="given_name"
                                type="text"
                            />
                            <small v-if="v$.data.given_name.$invalid" :class="{ 'p-error': v$.data.given_name.$invalid ? !v$.data.given_name.$anyError : null }">{{
                                v$.data.given_name.$errors[0].$message
                            }}</small>
                        </div>
                        <div class="md:col-8">
                            <InputText
                                placeholder="名"
                                v-model.lazy="data.surname"
                                @blur="v$.data.surname.$touch()"
                                :class="{ 'p-invalid': v$.data.surname.$invalid ? !v$.data.surname.$anyError : null }"
                                id="surname"
                                type="text"
                            />
                            <small v-if="v$.data.surname.$invalid" :class="{ 'p-error': v$.data.surname.$invalid ? !v$.data.surname.$anyError : null }">{{
                                v$.data.surname.$errors[0].$message
                            }}</small>
                        </div>
                    </div>
                </div>

                <!-- first name furigana -->
                <div class="field grid col-12 md:col-12">
                    <div class="col-12 mt-4 md:col-3 flex justify-content-end">
                        <label for="given_name_furigana" style="font-size: 14px; color: black; font-weight: 700">フリガナ <span style="color: red">※</span></label>
                    </div>
                    <div class="flex md:col-4">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-8">
                            <InputText
                                placeholder="セイ"
                                v-model.lazy="data.given_name_furigana"
                                @blur="v$.data.given_name_furigana.$touch()"
                                :class="{ 'p-invalid': v$.data.given_name_furigana.$invalid ? !v$.data.given_name_furigana.$anyError : null }"
                                id="given_name_furigana"
                                type="text"
                            />
                            <small v-if="v$.data.given_name_furigana.$invalid" :class="{ 'p-error': v$.data.given_name_furigana.$invalid ? !v$.data.given_name_furigana.$anyError : null }">{{
                                v$.data.given_name_furigana.$errors[0].$message
                            }}</small>
                        </div>
                        <div class="md:col-8">
                            <InputText
                                placeholder="メイ"
                                v-model.lazy="data.surname_furigana"
                                @blur="v$.data.surname_furigana.$touch()"
                                :class="{ 'p-invalid': v$.data.surname_furigana.$invalid ? !v$.data.surname_furigana.$anyError : null }"
                                id="surname_furigana"
                                type="text"
                            />
                            <small v-if="v$.data.surname_furigana.$invalid" :class="{ 'p-error': v$.data.surname_furigana.$invalid ? !v$.data.surname_furigana.$anyError : null }">{{
                                v$.data.surname_furigana.$errors[0].$message
                            }}</small>
                        </div>
                    </div>
                </div>

                <!-- Email -->
                <div class="field grid col-12 md:col-12">
                    <div class="col-12 mt-4 md:col-3 flex justify-content-end">
                        <label for="email" style="font-size: 14px; color: black; font-weight: 700">Email <span style="color: red">※</span></label>
                    </div>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <InputText
                                placeholder="example@example.com"
                                v-model.lazy="data.email"
                                @blur="v$.data.email.$touch()"
                                :class="{ 'p-invalid': v$.data.email.$invalid ? !v$.data.email.$anyError : null }"
                                id="email"
                                type="text"
                            />
                            <small v-if="v$.data.email.$invalid" :class="{ 'p-error': v$.data.email.$invalid ? !v$.data.email.$anyError : null }">{{ v$.data.email.$errors[0].$message }}</small>
                            <p style="font-size: 14px">
                                ご連絡はメールで差し上げております。<br />
                                @live-english.jpのドメインを受信できるように設定ください。
                            </p>
                        </div>
                    </div>
                </div>

                <!-- phone number -->
                <div class="field grid col-12 md:col-12">
                    <div class="col-12 mt-4 md:col-3 flex justify-content-end">
                        <label for="mobile_number" style="font-size: 14px; color: black; font-weight: 700">携帯電話番号 <span style="color: red">※</span></label>
                    </div>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <InputText
                                v-model.lazy="data.mobile_number"
                                @blur="v$.data.mobile_number.$touch()"
                                :class="{ 'p-invalid': v$.data.mobile_number.$invalid ? !v$.data.mobile_number.$anyError : null }"
                                id="mobile_number"
                                type="text"
                            />
                            <small v-if="v$.data.mobile_number.$invalid" :class="{ 'p-error': v$.data.mobile_number.$invalid ? !v$.data.mobile_number.$anyError : null }">{{
                                v$.data.mobile_number.$errors[0].$message
                            }}</small>
                        </div>
                    </div>
                </div>

                <!-- gender -->
                <div class="field grid col-12 md:col-12">
                    <div class="col-12 mt-3 md:col-3 flex justify-content-end">
                        <label for="gender" style="font-size: 14px; color: black; font-weight: 700">性別 <span style="color: red">※</span></label>
                    </div>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-2">:</span>
                        <div class="md:col-12">
                            <RadioButton id="option1" class="" name="option" value="男性・Male" v-model="data.gender" />
                            <label class="ml-2" for="option1" style="color: black; font-weight: 300; font-size: 14px">男性・Male</label>
                            <RadioButton id="option2" class="ml-6" name="option" value="女性・Female" v-model="data.gender" />
                            <label class="ml-2" for="option2" style="color: black; font-weight: 300; font-size: 14px">女性・Female</label>
                            <RadioButton id="option3" class="ml-6" name="option" value="その他・Other" v-model="data.gender" />
                            <label class="ml-2" for="option3" style="color: black; font-weight: 300; font-size: 14px">その他・Other</label>
                            <div class="flex justify-content-start">
                                <small v-if="v$.data.gender.$invalid" :class="{ 'p-error': v$.data.gender.$invalid ? !v$.data.gender.$anyError : null }">{{
                                    v$.data.gender.$errors[0].$message
                                }}</small>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field grid col-12 md:col-12">
                    <div class="col-12 mt-4 md:col-3 flex justify-content-end">
                        <label for="desire_date" style="font-size: 14px; color: black; font-weight: 700">体験希望日 <span style="color: red">※</span></label>
                    </div>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <!-- <input type="time"> -->
                            <Calendar
                                id="desire_date"
                                v-model="data.trial_date"
                                :numberOfMonths="2"
                                :selectOtherMonths="true"
                                iconDisplay="input"
                                @blur="v$.data.trial_date.$touch()"
                                :class="{ 'p-invalid': v$.data.trial_date.$invalid ? !v$.data.trial_date.$anyError : null }"
                            />
                            <!-- <InputTime v-model="selectedTime" mode="12" :show-seconds="false" placeholder="Pilih waktu"></InputTime> -->
                            <small v-if="v$.data.trial_date.$invalid" :class="{ 'p-error': v$.data.trial_date.$invalid ? !v$.data.trial_date.$anyError : null }">{{
                                v$.data.trial_date.$errors[0].$message
                            }}</small>
                            <p style="color: red">※日程調整のため48時間後以内の回を避け、3日後以降の日程をご選択くださいますようお願い致します。</p>
                        </div>
                    </div>
                </div>

                <!-- <pre>{{ data.trial_time }}</pre> -->
                <div class="field grid col-12 md:col-12">
                    <label for="desire_time" class="col-12 mt-2 md:col-3 flex justify-content-end" style="font-size: 14px; color: black; font-weight: 700"
                        >希望時間
                        <p style="color: red">※</p>
                    </label>
                    <div class="flex md:col-3">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12 flex">
                            <Dropdown id="state" v-model="data.trial_time" :options="dropdownItems" optionLabel="label" optionValue="value" placeholder="選択してください"></Dropdown>
                            <small v-if="v$.data.trial_time.$invalid" :class="{ 'p-error': v$.data.trial_time.$invalid ? !v$.data.trial_time.$anyError : null }">{{
                                v$.data.trial_time.$errors[0].$message
                            }}</small>
                            <!-- <div>
                                <Calendar placeholder="start time" id="desire_time" v-model="time_start"
                                    iconDisplay="input" :timeOnly="true" @blur="v$.time_start.$touch()"
                                    :class="{ 'p-invalid': v$.time_start.$invalid ? !v$.time_start.$anyError : null }" />
                                <div>
                                    <small v-if="v$.time_start.$invalid"
                                        :class="{ 'p-error': v$.time_start.$invalid ? !v$.time_start.$anyError : null }">{{
        v$.time_start.$errors[0].$message
    }}</small>
                                </div>
                            </div>
                            <div>
                                <Calendar class="ml-3" placeholder="finish time" id="desire_time" v-model="time_finish"
                                    iconDisplay="input" :timeOnly="true" @blur="v$.time_finish.$touch()"
                                    :class="{ 'p-invalid': v$.time_finish.$invalid ? !v$.time_finish.$anyError : null }" />

                                <small v-if="v$.time_finish.$invalid"
                                    :class="{ 'p-error': v$.time_finish.$invalid ? !v$.time_finish.$anyError : null }">{{
        v$.time_finish.$errors[0].$message
    }}</small>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="flex align-items-center">
                    <div style="height: 20px; width: 20px; background-color: #047cc1"></div>
                    <div class="ml-2" style="font-size: 24px; font-weight: 700; font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif; color: black">事前アンケート</div>
                </div>
                <div class="field flex col-12 md:col-12 mt-4">
                    <label for="firstname_kanji" style="font-size: 14px; color: black; font-weight: 700; text-align: right" class="col-12 md:col-3 mt-2 pr-3"
                        >1. 今回の学習目的 (該当するすべてにクリック) <span style="color: red">※</span>
                    </label>
                    <div class="flex md:col-9 pl-0">
                        <span class="px-1">:</span>
                        <div style="flex-direction: column">
                            <div>
                                <Checkbox
                                    id="checkOption1"
                                    name="option"
                                    value="現在の仕事のため"
                                    class="ml-3"
                                    v-model="data.learning_objective"
                                    @blur="v$.data.learning_objective.$touch()"
                                    :class="{ 'p-invalid': v$.data.learning_objective.$invalid ? !v$.data.learning_objective.$anyError : null }"
                                />
                                <label class="ml-2" for="checkOption1" style="color: black; font-weight: 300; font-size: 14px">現在の仕事のため</label>
                                <Checkbox id="checkOption2" name="option" value="今後の仕事で英語力が求められるため" class="ml-3" v-model="data.learning_objective" />
                                <label class="ml-2" for="checkOption2" style="color: black; font-weight: 300; font-size: 14px">今後の仕事で英語力が求められるため</label>
                                <Checkbox id="checkOption3" name="option" value="将来のキャリア・転職に備えて" class="ml-3" v-model="data.learning_objective" />
                                <label class="ml-2" for="checkOption3" style="color: black; font-weight: 300; font-size: 14px">将来のキャリア・転職に備えて</label>
                                <Checkbox id="checkOption4" name="option" value="昇給昇格のため" class="ml-3" v-model="data.learning_objective" />
                                <label class="ml-2" for="checkOption4" style="color: black; font-weight: 300; font-size: 14px">昇給昇格のため</label>
                            </div>
                            <div class="mt-2">
                                <Checkbox id="checkOption5" name="option" value="留学のため(海外大学院・大学)" class="ml-3" v-model="data.learning_objective" />
                                <label class="ml-2" for="checkOption5" style="color: black; font-weight: 300; font-size: 14px">留学のため(海外大学院・大学)</label>
                                <Checkbox id="checkOption6" name="option" value="語学留学のため" class="ml-3" v-model="data.learning_objective" />
                                <label class="ml-2" for="checkOption6" style="color: black; font-weight: 300; font-size: 14px">語学留学のため</label>

                                <Checkbox id="checkOption7" name="option" value="ワーキングホリデーのため" class="ml-3" v-model="data.learning_objective" />

                                <label class="ml-2" for="checkOption7" style="color: black; font-weight: 300; font-size: 14px">ワーキングホリデーのため</label>
                                <Checkbox id="checkOption8" name="option" value="TOEFL/IELTSのため" class="ml-3" v-model="data.learning_objective" />
                                <label class="ml-2" for="checkOption8" style="color: black; font-weight: 300; font-size: 14px">TOEFL/IELTSのため</label>
                            </div>
                            <div class="mt-2">
                                <Checkbox id="checkOption9" name="option" value="TOEIC/英検のため" class="ml-3" v-model="data.learning_objective" />
                                <label class="ml-2" for="checkOption9" style="color: black; font-weight: 300; font-size: 14px">TOEIC/英検のため</label>
                                <Checkbox id="checkOption10" name="option" value="海外赴任に備えるため" class="ml-3" v-model="data.learning_objective" />
                                <label class="ml-2" for="checkOption10" style="color: black; font-weight: 300; font-size: 14px">海外赴任に備えるため</label>
                                <Checkbox id="checkOption11" name="option" value="趣味・教養のため" class="ml-3" v-model="data.learning_objective" />
                                <label class="ml-2" for="checkOption11" style="color: black; font-weight: 300; font-size: 14px">趣味・教養のため</label>
                                <Checkbox id="checkOption12" name="option" value="友人家族との交流のため" class="ml-3" v-model="data.learning_objective" />
                                <label class="ml-2" for="checkOption12" style="color: black; font-weight: 300; font-size: 14px">友人家族との交流のため</label>
                            </div>
                            <div class="mt-2">
                                <Checkbox id="checkOption13" name="option" value="英語力の維持のため" class="ml-3" v-model="data.learning_objective" />
                                <label class="ml-2" for="checkOption13" style="color: black; font-weight: 300; font-size: 14px">英語力の維持のため</label>
                                <Checkbox id="checkOption14" name="option" value="その他" class="ml-3" v-model="data.learning_objective" />
                                <label class="ml-2" for="checkOption14" style="color: black; font-weight: 300; font-size: 14px">その他</label>
                            </div>
                            <div>
                                <small v-if="v$.data.learning_objective.$invalid" :class="{ 'p-error': v$.data.learning_objective.$invalid ? !v$.data.learning_objective.$anyError : null }">{{
                                    v$.data.learning_objective.$errors[0].$message
                                }}</small>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field flex col-12 md:col-12 mt-4">
                    <label for="firstname_kanji" style="font-size: 14px; color: black; font-weight: 700; text-align: right" class="col-12 md:col-3 mt-2 pr-3"
                        >2. 現在の英語使用状況 (該当するすべてにクリック)<span style="color: red">※</span>
                    </label>
                    <div class="flex md:col-9 pl-0">
                        <span class="px-1">:</span>
                        <div style="flex-direction: column; font-size: 14px">
                            <div>
                                <Checkbox
                                    id="checkOption15"
                                    name="option"
                                    value="ほぼ毎日英語でのコミュニケーションがある"
                                    class="ml-3"
                                    v-model="data.current_english_usage"
                                    @blur="v$.data.current_english_usage.$touch()"
                                    :class="{ 'p-invalid': v$.data.current_english_usage.$invalid ? !v$.data.current_english_usage.$anyError : null }"
                                />
                                <label class="ml-2" for="checkOption15" style="color: black; font-weight: 300; font-size: 14px">ほぼ毎日英語でのコミュニケーションがある</label>
                                <Checkbox id="checkOption16" name="option" value="頻繁に英語メールのやりとりがある" class="ml-3" v-model="data.current_english_usage" />
                                <label class="ml-2" for="checkOption16" style="color: black; font-weight: 300; font-size: 14px">頻繁に英語メールのやりとりがある</label>
                                <Checkbox id="checkOption17" name="option" value="資料やメールを読むことは多い" class="ml-3" v-model="data.current_english_usage" />
                                <label class="ml-2" for="checkOption17" style="color: black; font-weight: 300; font-size: 14px">資料やメールを読むことは多い</label>
                            </div>
                            <div class="mt-2">
                                <Checkbox id="checkOption18" name="option" value="資料やメールを書くことがある" class="ml-3" v-model="data.current_english_usage" />
                                <label class="ml-2" for="checkOption18" style="color: black; font-weight: 300; font-size: 14px">資料やメールを書くことがある</label>
                                <Checkbox id="checkOption19" name="option" value="仕事で話すことは多い" class="ml-3" v-model="data.current_english_usage" />
                                <label class="ml-2" for="checkOption19" style="color: black; font-weight: 300; font-size: 14px">仕事で話すことは多い</label>
                                <Checkbox id="checkOption20" name="option" value="仕事以外で話すことは多い" class="ml-3" v-model="data.current_english_usage" />
                                <label class="ml-2" for="checkOption20" style="color: black; font-weight: 300; font-size: 14px">仕事以外で話すことは多い</label>
                            </div>
                            <div class="mt-2">
                                <Checkbox id="checkOption21" name="option" value="以前は英語を使っていたが現状は少ない" class="ml-3" v-model="data.current_english_usage" />
                                <label class="ml-2" for="checkOption21" style="color: black; font-weight: 300; font-size: 14px">以前は英語を使っていたが現状は少ない</label>
                                <Checkbox id="checkOption22" name="option" value="英語は使っていない" class="ml-3" v-model="data.current_english_usage" />
                                <label class="ml-2" for="checkOption22" style="color: black; font-weight: 300; font-size: 14px">英語は使っていない</label>
                                <Checkbox id="checkOption23" name="option" value="その他" class="ml-3" v-model="data.current_english_usage" />
                                <label class="ml-2" for="checkOption23" style="color: black; font-weight: 300; font-size: 14px">その他</label>
                            </div>
                            <div>
                                <small v-if="v$.data.current_english_usage.$invalid" :class="{ 'p-error': v$.data.current_english_usage.$invalid ? !v$.data.current_english_usage.$anyError : null }">{{
                                    v$.data.current_english_usage.$errors[0].$message
                                }}</small>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <pre>{{ data.current_english_usage }}</pre> -->
                <div class="field flex col-12 md:col-12 mt-4">
                    <label for="firstname_kanji" style="font-size: 14px; color: black; font-weight: 700; text-align: right" class="col-12 md:col-3 mt-2 pr-3"
                        >3. 英語学習経験について (該当するすべてにクリック) <span style="color: red">※</span>
                    </label>
                    <div class="flex md:col-9 pl-0">
                        <span class="px-1">:</span>
                        <div style="flex-direction: column; font-size: 14px">
                            <div>
                                <Checkbox
                                    id="checkOption24"
                                    name="option"
                                    value="通学型英会話スクール (グループ)"
                                    class="ml-3"
                                    v-model="data.english_learning_exp"
                                    @blur="v$.data.english_learning_exp.$touch()"
                                    :class="{ 'p-invalid': v$.data.english_learning_exp.$invalid ? !v$.data.english_learning_exp.$anyError : null }"
                                />

                                <label class="ml-2" for="checkOption24" style="color: black; font-weight: 300; font-size: 14px">通学型英会話スクール (グループ)</label>
                                <Checkbox id="checkOption25" name="option" value="通学型英会話スクール (マンツーマン)" class="ml-3" v-model="data.english_learning_exp" />
                                <label class="ml-2" for="checkOption25" style="color: black; font-weight: 300; font-size: 14px">通学型英会話スクール (マンツーマン)</label>
                            </div>
                            <div class="mt-2">
                                <Checkbox id="checkOption26" name="option" value="オンライン英会話スクール (ネイティブ講師)" class="ml-3" v-model="data.english_learning_exp" />
                                <label class="ml-2" for="checkOption26" style="color: black; font-weight: 300; font-size: 14px">オンライン英会話スクール (ネイティブ講師)</label>
                                <Checkbox id="checkOption27" name="option" value="オンライン英会話スクール (非ネイティブ講師)" class="ml-3" v-model="data.english_learning_exp" />
                                <label class="ml-2" for="checkOption27" style="color: black; font-weight: 300; font-size: 14px">オンライン英会話スクール (非ネイティブ講師)</label>
                            </div>
                            <div class="mt-2">
                                <Checkbox id="checkOption28" name="option" value="TOEIC/TOEFLなどの試験対策学校" class="ml-3" v-model="data.english_learning_exp" />
                                <label class="ml-2" for="checkOption28" style="color: black; font-weight: 300; font-size: 14px">TOEIC/TOEFLなどの試験対策学校</label>
                                <Checkbox id="checkOption29" name="option" value="留学 (海外大学・大学院)" class="ml-3" v-model="data.english_learning_exp" />
                                <label class="ml-2" for="checkOption29" style="color: black; font-weight: 300; font-size: 14px">留学 (海外大学・大学院)</label>
                                <Checkbox id="checkOption30" name="option" value="留学 (語学留学)" class="ml-3" v-model="data.english_learning_exp" />
                                <label class="ml-2" for="checkOption30" style="color: black; font-weight: 300; font-size: 14px">留学 (語学留学)</label>
                            </div>
                            <div class="mt-2">
                                <Checkbox id="checkOption31" name="option" value="ワーキングホリデー等の海外生活" class="ml-3" v-model="data.english_learning_exp" />
                                <label class="ml-2" for="checkOption31" style="color: black; font-weight: 300; font-size: 14px">ワーキングホリデー等の海外生活</label>
                                <Checkbox id="checkOption32" name="option" value="海外勤務" class="ml-3" v-model="data.english_learning_exp" />
                                <label class="ml-2" for="checkOption32" style="color: black; font-weight: 300; font-size: 14px">海外勤務</label>
                                <Checkbox id="checkOption33" name="option" value="独学" class="ml-3" v-model="data.english_learning_exp" />
                                <label class="ml-2" for="checkOption33" style="color: black; font-weight: 300; font-size: 14px">独学</label>
                                <Checkbox id="checkOption34" name="option" value="学習経験は特になし" class="ml-3" v-model="data.english_learning_exp" />
                                <label class="ml-2" for="checkOption34" style="color: black; font-weight: 300; font-size: 14px">学習経験は特になし</label>
                                <Checkbox id="checkOption35" name="option" value="その他" class="ml-3" v-model="data.english_learning_exp" />
                                <label class="ml-2" for="checkOption35" style="color: black; font-weight: 300; font-size: 14px">その他</label>
                            </div>
                            <div>
                                <small v-if="v$.data.english_learning_exp.$invalid" :class="{ 'p-error': v$.data.english_learning_exp.$invalid ? !v$.data.english_learning_exp.$anyError : null }">{{
                                    v$.data.english_learning_exp.$errors[0].$message
                                }}</small>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field grid col-12 md:col-12">
                    <label style="font-size: 14px; color: black; font-weight: 700; text-align: right" for="firstname_kanji" class="mb-2 md:col-3 mt-2 pr-3"
                        >4. 上記のうち、通学型英会話スクール かオンライン英会話、またはその両方 で学習されたトータルの期間を教えて ください。
                    </label>
                    <div class="flex md:col-9 pl-0">
                        <span class="px-1 ml-2 mt-1">:</span>
                        <div class="md:col-12">
                            <RadioButton id="less5" class="ml-2" name="option" value="5ヶ月未満" v-model="data.total_period_time_studied" />
                            <label class="ml-2" for="less5" style="color: black; font-weight: 300; font-size: 14px">5ヶ月未満</label>
                            <RadioButton id="less1" class="ml-6" name="option" value="1年未満" v-model="data.total_period_time_studied" />
                            <label class="ml-2" for="less1" style="color: black; font-weight: 300; font-size: 14px">1年未満</label>
                            <RadioButton id="1-2" class="ml-6" name="option" value="1~2年" v-model="data.total_period_time_studied" />
                            <label class="ml-2" for="1-2" style="color: black; font-weight: 300; font-size: 14px">1~2年</label>
                            <RadioButton id="3-5" class="ml-6" name="option" value="3~5年" v-model="data.total_period_time_studied" />
                            <label class="ml-2" for="3-5" style="color: black; font-weight: 300; font-size: 14px">3~5年</label>
                            <RadioButton id="more5" class="ml-6" name="option" value="5年以上" v-model="data.total_period_time_studied" />
                            <label class="ml-2" for="more5" style="color: black; font-weight: 300; font-size: 14px">5年以上</label>
                            <div class="mr-">
                                <!-- <small
                                    v-if="v$.data.total_period_time_studied.$invalid"
                                    :class="{ 'p-error': v$.data.total_period_time_studied.$invalid ? !v$.data.total_period_time_studied.$anyError : null }"
                                    >{{ v$.data.total_period_time_studied.$errors[0].$message }}</small
                                > -->
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field flex col-12 md:col-12 mt-4">
                    <label for="firstname_kanji" style="font-size: 14px; color: black; font-weight: 700; text-align: right" class="col-12 md:col-3 mt-2 pr-3"
                        >5. ご興味をお持ちのコース(複数選択化)
                    </label>
                    <div class="flex md:col-9 pl-0">
                        <span class="px-1">:</span>
                        <div style="flex-direction: column; font-size: 14px">
                            <div>
                                <Checkbox id="a" name="option" value="一般ビジネス英会話" class="ml-3" v-model="data.course_interest" />
                                <label class="ml-2" for="a" style="color: black; font-weight: 300; font-size: 14px; font-family: Yu Gothic">一般ビジネス英会話</label>
                                <Checkbox id="b" name="option" value="ディスカッション" class="ml-3" v-model="data.course_interest" />
                                <label class="ml-2" for="b" style="color: black; font-weight: 300; font-size: 14px">ディスカッション</label>
                                <Checkbox id="c" name="option" value="ビジネスミーティング" class="ml-3" v-model="data.course_interest" />
                                <label class="ml-2" for="c" style="color: black; font-weight: 300; font-size: 14px">ビジネスミーティング</label>
                                <Checkbox id="d" name="option" value="英語面接準備" class="ml-3" v-model="data.course_interest" />
                                <label class="ml-2" for="d" style="color: black; font-weight: 300; font-size: 14px">英語面接準備</label>
                                <Checkbox id="e" name="option" value="MBA留学準備英語" class="ml-3" v-model="data.course_interest" />
                                <label class="ml-2" for="e" style="color: black; font-weight: 300; font-size: 14px">MBA留学準備英語</label>
                            </div>
                            <div class="mt-2">
                                <Checkbox id="f" name="option" value="IELTS S&W 理解・対策" class="ml-3" v-model="data.course_interest" />
                                <label class="ml-2" for="f" style="color: black; font-weight: 300; font-size: 14px">IELTS S&W 理解・対策</label>
                                <Checkbox id="g" name="option" value="短期集中学習" class="ml-3" v-model="data.course_interest" />
                                <label class="ml-2" for="g" style="color: black; font-weight: 300; font-size: 14px">短期集中学習</label>
                                <Checkbox id="h" name="option" value="university(大学生)" class="ml-3" v-model="data.course_interest" />
                                <label class="ml-2" for="h" style="color: black; font-weight: 300; font-size: 14px">university(大学生)</label>
                                <Checkbox id="i" name="option" value="teens(中高生)" class="ml-3" v-model="data.course_interest" />
                                <label class="ml-2" for="i" style="color: black; font-weight: 300; font-size: 14px">teens(中高生)</label>
                            </div>
                            <div class="mt-2">
                                <Checkbox id="j" name="option" value="日常英会話" class="ml-3" v-model="data.course_interest" />
                                <label class="ml-2" for="j" style="color: black; font-weight: 300; font-size: 14px">日常英会話</label>
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>

                <div class="field flex col-12 md:col-12 mt-4">
                    <label style="font-size: 14px; color: black; font-weight: 700; text-align: right" for="device" class="col-12 md:col-3 mt-4 pr-3"
                        >6. 体験レッスンに使用予定のディバイス <span style="color: red">※</span></label
                    >
                    <div class="flex md:col-9">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <InputText
                                placeholder="デスクトップパソコン"
                                id="device"
                                type="text"
                                v-model="data.device_planned"
                                @blur="v$.data.device_planned.$touch()"
                                :class="{ 'p-invalid': v$.data.device_planned.$invalid ? !v$.data.device_planned.$anyError : null }"
                            />
                            <small v-if="v$.data.device_planned.$invalid" :class="{ 'p-error': v$.data.device_planned.$invalid ? !v$.data.device_planned.$anyError : null }">{{
                                v$.data.device_planned.$errors[0].$message
                            }}</small>
                            <p style="color: red">※体験時は受講システム等のご案内がございます為、スマートフォンのみでのご受講はご遠慮頂いております。</p>
                        </div>
                    </div>
                </div>

                <div class="field grid col-12 md:col-12">
                    <label style="font-size: 14px; color: black; font-weight: 700; text-align: right" for="req" class="col-12 md:col-3 mt-2 pr-3"
                        >7. その他、何かご要望やご質問などご ざいましたら是非お知らせください
                    </label>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <Textarea v-model="data.questions" id="req" type="text" />
                        </div>
                    </div>
                </div>

                <!-- <div class="field grid col-12 md:col-12">
                    <label style="font-size: 14px; color: black; font-weight: 700; text-align: right" for="referal" class="col-12 md:col-3 mt-2 pr-3"
                        >8. Referal Code
                    </label>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <Inputext v-model="data.questions" id="referal" type="text" disabled />
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div style="text-align: center">
            <div>アンケートへのご回答を誠に有難うございます。日程のご相談のため、ご登録のメールアドレスに折り返しご連絡をさせていただきます。</div>
            <div class="mt-4">
                フォームをご送信の際には、<a href="https://www.live-english.co.jp/terms/" style="color: rgba(4, 124, 193, 0.95)">利用規約</a>と<a
                    href="https://www.live-english.co.jp/privacy/"
                    style="color: rgba(4, 124, 193, 0.95)"
                    >プライバシーポリシー</a
                >をご確認ください。
            </div>
            <div class="mt-4">
                <Button style="width: 50%; height: 50px; text-align: center" label="確認画面" :disabled="!isValid" @click="toTop()"></Button>
            </div>
        </div>

        <!-- <div class="kotak">
            <div class="segitiga"></div>
        </div> -->
        <div style="height: 50px"></div>
        <div class="scroll-to-top" @click="scrollToTop" style="cursor: pointer;">
            <img style="width: 75px" src="../../assets/Top.png" alt="" />

            <!-- <Button @click="scrollToTop" icon="pi pi-chevron-up" /> -->
            <!-- <span class="icon-label">top</span> -->
        </div>
    </div>

    <!-- Halaman Confirmation -->
    <!-- v-if="display1" -->
    <div v-if="display1" style="width: 100%; flex-direction: column; justify-content: center; align-items: center; gap: 60px; display: inline-flex; background-color: #ffffff">
        <div
            style="width: 100%; height: 180px; position: relative; background-image: url('../../assets/header.png')"
            :style="{ 'background-image': 'url(' + require('../../assets/img03.png') + ')' }"
        ></div>
        <div
            style="
                width: 600px;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 183.99px;
                padding-right: 183.98px;
                top: 130px;
                position: absolute;
                background: rgba(4, 124, 193, 0.95);
                justify-content: center;
                align-items: center;
                display: inline-flex;
            "
        >
            <div style="display: flex; flex-direction: column">
                <p style="text-align: center; color: white; font-size: 30px; font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif; font-weight: 700">Confirm</p>
                <p class="mb" style="text-align: center; color: white; font-size: 15px; font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif; width: 300px">無料体験レッスンお申し込み（確認）</p>
            </div>
        </div>

        <div style="background-color: #ffffff; width: 1150px; height: 100%">
            <div class="flex justify-content-start align-items-center mt-4">
                <div style="font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif; font-weight: 700; text-align: center; font-size: 28px; color: #047cc1">無料体験レッスン申し込みフォーム</div>
            </div>

            <div class="flex mt-4">
                <div class="flex justify-content-center align-items-center" style="width: 33%; height: 40px; background-color: #e3eff6; text-align: center; font-weight: 700">入力画面</div>
                <div
                    class="flex justify-content-start align-items-center text-align-center"
                    style="width: 33%; background-color: rgba(4, 124, 193, 0.95); height: 40px; transition: background-color 0.5s"
                >
                    <!-- <div class="segitiga-menghadap-kanan"></div> -->
                    <div class="" style="display: flex; justify-content: center; align-items: center; text-align: center; margin-left: 170px; font-weight: 700; color: white">確認画面</div>
                </div>
                <div class="flex justify-content-start align-items-center text-align-center" style="width: 33%; height: 40px; background-color: #e3eff6; transition: background-color 0.5s">
                    <!-- <div :class="{ 'segitiga-menghadap-kanan2': par, 'segitiga-menghadap-kanan': !par }"></div> -->
                    <div class="segitiga-menghadap-kanan"></div>
                    <div class="" style="text-align: center; margin-left: 170px; font-weight: 700">完了</div>
                    <!-- <i style="font-size: 43px; color: rgba(4, 124, 193, 0.95); margin-bottom: 20px;" class="fa fa-play " aria-hidden="true"></i> -->
                </div>
            </div>
            <!-- <Button class="mt-5" @click="ubah_warna">Ubah warna</Button>
            <Button class="mt-5" @click="ubah_warna1">Ubah warna</Button>
            <Button class="mt-5" @click="ubah_warna2">Ubah warna</Button> -->
            <div class="flex mt-4">
                <p style="color: red">※</p>

                <p>は必須項目です。</p>
            </div>
            <div class="p-fluid formgrid grid">
                <!-- firstname english -->
                <div class="field grid col-12 md:col-12">
                    <div class="col-12 mt-4 md:col-3 flex justify-content-end">
                        <label style="font-size: 14px; color: black; font-weight: 700; text-align: right" for="name">お名前 <span style="color: red">※</span></label>
                    </div>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <InputText v-model="fullName" id="name" type="text" autofocus readonly />
                        </div>
                    </div>
                </div>

                <!-- first name furigana -->
                <div class="field grid col-12 md:col-12">
                    <div class="col-12 mt-4 md:col-3 flex justify-content-end">
                        <label style="font-size: 14px; color: black; font-weight: 700; text-align: right" for="furigana">フリガナ <span style="color: red">※</span></label>
                    </div>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <InputText v-model="fullNameFurigana" id="furigana" type="text" disabled />
                        </div>
                    </div>
                </div>

                <!-- Email -->
                <div class="field grid col-12 md:col-12">
                    <div class="col-12 mt-4 md:col-3 flex justify-content-end">
                        <label style="font-size: 14px; color: black; font-weight: 700; text-align: right" for="email">Email <span style="color: red">※</span></label>
                    </div>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <InputText v-model="data.email" disabled id="email" type="text" />
                            ご連絡はメールで差し上げております。<br />
                            @live-english.jpのドメインを受信できるように設定ください。
                        </div>
                    </div>
                </div>

                <!-- phone number -->
                <div class="field grid col-12 md:col-12">
                    <div class="col-12 mt-4 md:col-3 flex justify-content-end">
                        <label style="font-size: 14px; color: black; font-weight: 700; text-align: right" for="mobile_number">携帯電話番号 <span style="color: red">※</span></label>
                    </div>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <InputText v-model="data.mobile_number" disabled id="mobile_number" type="text" />
                        </div>
                    </div>
                </div>

                <!-- gender -->
                <div class="field grid col-12 md:col-12">
                    <div class="col-12 mt-4 md:col-3 flex justify-content-end">
                        <label style="font-size: 14px; color: black; font-weight: 700; text-align: right" for="gender">性別 <span style="color: red">※</span></label>
                    </div>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <InputText v-model="data.gender" disabled id="gender" type="text" />
                        </div>
                    </div>
                </div>

                <div class="field grid col-12 md:col-12">
                    <div class="col-12 mt-4 md:col-3 flex justify-content-end">
                        <label style="font-size: 14px; color: black; font-weight: 700; text-align: right" for="desire_date">体験希望日 <span style="color: red">※</span></label>
                    </div>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <InputText :value="this.$moment(data.trial_date).format('L')" disabled id="desire_date" type="text" />
                            <!-- <InputText v-model="data.trial_date" disabled id="desire_date" type="text" /> -->
                        </div>
                    </div>
                </div>

                <div class="field grid col-12 md:col-12">
                    <label style="font-size: 14px; color: black; font-weight: 700; text-align: right" for="desire_time" class="col-12 mt-2 md:col-3 flex justify-content-end"
                        >希望時間
                        <p style="color: red">※</p>
                    </label>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <InputText v-model="data.trial_time" disabled id="desire_time" type="text" />
                        </div>
                    </div>
                </div>
                <!-- <pre>{{ data.trial_time }}</pre> -->
                <!-- pre eliminary -->
                <div class="flex align-items-center">
                    <div style="height: 20px; width: 20px; background-color: #047cc1"></div>
                    <div class="ml-2" style="font-size: 24px; font-weight: 700; font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif; color: black">事前アンケート</div>
                </div>

                <div class="field grid col-12 md:col-12">
                    <div class="col-12 mt-4 md:col-3 flex justify-content-end">
                        <label style="font-size: 14px; color: black; font-weight: 700; text-align: right" for="learning_obj"
                            >1. 今回の学習目的 (該当するすべてにクリック) <span style="color: red">※</span></label
                        >
                    </div>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <Textarea v-model="data.learning_objective" disabled id="learning_obj" type="text" />
                        </div>
                    </div>
                </div>

                <div class="field grid col-12 md:col-12">
                    <div class="col-12 mt-4 md:col-3 flex justify-content-end">
                        <label style="font-size: 14px; color: black; font-weight: 700; text-align: right" for="current_english"
                            >2. 現在の英語使用状況 (該当するすべてにクリック) <span style="color: red">※</span></label
                        >
                    </div>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <Textarea v-model="data.current_english_usage" disabled id="current_english" type="text" />
                        </div>
                    </div>
                </div>

                <div class="field grid col-12 md:col-12">
                    <div class="col-12 mt-4 md:col-3 flex justify-content-end">
                        <label style="font-size: 14px; color: black; font-weight: 700; text-align: right" for="about_english"
                            >3. 英語学習経験について (該当するすべてにクリック) <span style="color: red">※</span></label
                        >
                    </div>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <Textarea v-model="data.english_learning_exp" disabled id="about_english" type="text" />
                        </div>
                    </div>
                </div>

                <div class="field grid col-12 md:col-12">
                    <div class="col-12 mt-4 md:col-3 flex justify-content-end">
                        <label style="font-size: 14px; color: black; font-weight: 700; text-align: right" for="total_period"
                            >4. 上記のうち、通学型英会話スクール かオンライン英会話、またはその両方 で学習されたトータルの期間を教えて ください。 <span style="color: red">※</span></label
                        >
                    </div>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <InputText v-model="data.total_period_time_studied" disabled id="total_period" type="text" />
                        </div>
                    </div>
                </div>

                <div class="field grid col-12 md:col-12">
                    <div class="col-12 mt-4 md:col-3 flex justify-content-end">
                        <label style="font-size: 14px; color: black; font-weight: 700; text-align: right" for="course_interest"
                            >5. ご興味をお持ちのコース(複数選択化) <span style="color: red">※</span></label
                        >
                    </div>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <Textarea v-model="data.course_interest" disabled id="course_interest" type="text" />
                        </div>
                    </div>
                </div>

                <div class="field grid col-12 md:col-12">
                    <label style="font-size: 14px; color: black; font-weight: 700; text-align: right" for="device" class="col-12 md:col-3 mb-3 flex justify-content-end"
                        >6. 体験レッスンに使用予定のディバイス <span style="color: red">※</span></label
                    >
                    <div class="flex md:col-9">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <InputText v-model="data.device_planned" placeholder="デスクトップパソコン" id="device" type="text" disabled />
                            <p style="color: red">※体験時は受講システム等のご案内がございます為、スマートフォンのみでのご受講はご遠慮頂いております。</p>
                        </div>
                    </div>
                </div>

                <div class="field grid col-12 md:col-12">
                    <label style="font-size: 14px; color: black; font-weight: 700; text-align: right" for="firstname_kanji" class="col-12 md:col-3 mt-2 pr-3"
                        >7. その他、何かご要望やご質問などご ざいましたら是非お知らせください
                    </label>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <Textarea v-model="data.questions" id="firstname_kanji" type="text" disabled />
                        </div>
                    </div>
                </div>

                


            </div>
        </div>
        <div style="text-align: center">
            <div>アンケートへのご回答を誠に有難うございます。日程のご相談のため、ご登録のメールアドレスに折り返しご連絡をさせていただきます。</div>
            <div class="mt-4">
                フォームをご送信の際には、<a href="https://www.live-english.co.jp/terms/" style="color: rgba(4, 124, 193, 0.95)">利用規約</a>と<a
                    href="https://www.live-english.co.jp/privacy/"
                    style="color: rgba(4, 124, 193, 0.95)"
                    >プライバシーポリシー</a
                >をご確認ください。
            </div>
            
            <div class="mt-4 col 12">
                <Button class="p-button-outlined mr-3" style="width: 200px; height: 50px; text-align: center" label="戻る" @click="toTop()" />
                <Button style="width: 200px; height: 50px; text-align: center" label="送信する" :disabled="!isValid || loading" @click="register_student()"></Button>
            </div>
        </div>

        <div style="height: 50px"></div>

        <div class="scroll-to-top" @click="scrollToTop" style="cursor: pointer;">
            <img style="width: 75px" src="../../assets/Top.png" alt="" />

            <!-- <Button @click="scrollToTop" icon="pi pi-chevron-up" /> -->
            <!-- <span class="icon-label">top</span> -->
        </div>
    </div>

    <!-- HALAMAN KE 3 -->
    <div v-if="display2" style="width: 100%; flex-direction: column; justify-content: center; align-items: center; gap: 60px; display: inline-flex; background-color: #ffffff">
        <div
            style="width: 100%; height: 180px; position: relative; background-image: url('../../assets/header.png')"
            :style="{ 'background-image': 'url(' + require('../../assets/img03.png') + ')' }"
        ></div>
        <div
            style="
                width: 600px;
                padding-top: 10px;
                padding-bottom: 10px;
                top: 130px;
                position: absolute;
                background: rgba(4, 124, 193, 0.95);
                justify-content: center;
                align-items: center;
                display: inline-flex;
            "
        >
            <div>
                <p style="text-align: center; color: white; font-size: 30px; font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif; font-weight: 700">THANKS</p>
                <p class="mb-3" style="text-align: center; color: white; font-size: 15px; font-family: Yu Gothic">無料体験レッスンお申し込み（完了）</p>
            </div>
        </div>

        <div style="background-color: #ffffff; width: 1150px; height: 700px">
            <div class="flex justify-content-start align-items-center mt-4"></div>

            <div class="flex mt-4">
                <div class="flex justify-content-center align-items-center" style="width: 33%; height: 40px; text-align: center; font-weight: 700; background-color: #e3eff6">入力画面</div>
                <div class="flex justify-content-start align-items-center text-align-center" style="width: 33%; height: 40px; transition: background-color 0.5s; background-color: #e3eff6">
                    <!-- <div class="segitiga-menghadap-kanan"></div> -->
                    <div class="" style="display: flex; justify-content: center; align-items: center; text-align: center; margin-left: 170px; font-weight: 700">確認画面</div>
                    <!-- <i style="font-size: 43px; color: rgba(4, 124, 193, 0.95); margin-bottom: 20px;" class="fa fa-play " aria-hidden="true"></i> -->
                </div>
                <div
                    class="flex justify-content-start align-items-center text-align-center"
                    style="width: 33%; height: 40px; background-color: rgba(4, 124, 193, 0.95); transition: background-color 0.5s"
                >
                    <!-- <div :class="{ 'segitiga-menghadap-kanan2': par, 'segitiga-menghadap-kanan': !par }"></div> -->
                    <div class="" style="text-align: center; margin-left: 170px; font-weight: 700; color: white">完了</div>
                    <!-- <i style="font-size: 43px; color: rgba(4, 124, 193, 0.95); margin-bottom: 20px;" class="fa fa-play " aria-hidden="true"></i> -->
                </div>
            </div>
            <div class="mt-8" style="text-align: center">
                体験レッスンのお申し込みを誠に有難うございます。<br />
                ２営業日以内にご登録のメールアドレスへご返信をさせていただきます。
            </div>
            <div class="mt-6" style="text-align: center">
                万が一2日以内にこちらからのご返信がない場合は、<br />
                通信エラー等でフォームが送信できていない可能性がございますので <br />
                大変恐れ入りますが support@live-english.jp までご連絡ください。
            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { useRouter } from 'vue-router';
import { required, email } from '@vuelidate/validators';
export default {
    setup() {
        const router = useRouter();
        return { v$: useVuelidate({ $lazy: true, $autoDirty: true }), router };
        // const background = require('@/assets/header.png');
    },
    data() {
        return {
            data: {
                id: null,
                given_name_english: null,
                surname: null,
                given_name_furigana: null,
                surname_furigana: null,
                email: null,
                mobile_number: null,
                gender: null,
                trial_date: null,
                trial_time: null,
                learning_objective: [],
                current_english_usage: [],
                english_learning_exp: [],
                course_interest: [],
                total_period_time_studied: null,
                device_planned: null,
                questions: null,
                type_user: 'Trial',
                ms_role_id: 'student',
                is_registered: 1,
                status_student: 'New',
            },
            dropdownItems: [
                { value: '9:00-13:00', label: '9:00~13:00の間に開始する回' },
                { value: '17:00-20:30', label: '17:00~20:30の間に開始する回' },
            ],
            loading: false,
            time_start: null,
            time_finish: null,
            checkboxValue: [],
            bebas: null,
            display: true,
            display1: false,
            display2: false,
            par: true,
            par_color: 'rgba(4, 124, 193, 0.95',
            par_color1: '#e3eff6',
            par_color2: '#e3eff6',
            color_text: '#FFFFFF',
        };
    },
    computed: {
        formString() {
            return JSON.stringify(this.data);
        },
        isValid() {
            return !this.v$.$invalid;
        },
        isDirty() {
            return this.v$.$anyDirty;
        },
        fullName() {
            return this.data.given_name + ' ' + this.data.surname;
        },
        fullNameFurigana() {
            return this.data.given_name_furigana + ' ' + this.data.surname_furigana;
        },
        learningObjectives() {
            return this.data.learning_objective.length ? this.data.learning_objective.join(', ') : null;
        },
        englishUsage() {
            return this.data.current_english_usage.length ? this.data.current_english_usage.join(', ') : null;
        },
        experienceEnglish() {
            return this.data.english_learning_exp.length ? this.data.english_learning_exp.join(', ') : null;
        },
        courseInterest() {
            return this.data.course_interest.length ? this.data.course_interest.join(', ') : null;
        },
        trial_time() {
            return `Times starting between ${this.$moment(this.time_start).format('LT')} and ${this.$moment(this.time_finish).format('LT')}`;
        },
    },
    validations() {
        return {
            data: {
                given_name: {
                    required,
                },
                surname: {
                    required,
                },
                given_name_furigana: {
                    required,
                },
                surname_furigana: {
                    required,
                },
                email: {
                    required,
                    email,
                },
                mobile_number: {
                    required,
                },
                gender: {
                    required,
                },
                trial_date: {
                    required,
                },
                trial_time: {
                    required,
                },
                learning_objective: {
                    required,
                },
                current_english_usage: {
                    required,
                },
                english_learning_exp: {
                    required,
                },
                device_planned: {
                    required,
                },
                ms_role_id: {
                    required,
                },
            },
        };
    },
    methods: {
        register_student() {
            let vm = this;
            if (vm.isValid && vm.isDirty) {
                vm.loading = true;
                vm.data.learning_objective = vm.learningObjectives;
                vm.data.current_english_usage = vm.englishUsage;
                vm.data.english_learning_exp = vm.experienceEnglish;
                vm.data.course_interest = vm.courseInterest;

                // vm.data.trial_time = vm.trial_time;
                // console.log(vm.data.trial_time, "ini adalah trial_timenya");
                vm.$axios
                    .post('user/register_landing_trial', vm.data)
                    .then((res) => {
                        // console.log(res.data.data[0].id, "lhaiki");
                        if (res.data.status == 200) {
                            if (res.data.message == 'success') {
                                vm.data = {
                                    id: null,
                                    given_name_english: null,
                                    surname: null,
                                    given_name_furigana: null,
                                    surname_furigana: null,
                                    email: null,
                                    mobile_number: null,
                                    gender: null,
                                    trial_date: null,
                                    // trial_time: null,
                                    learning_objective: null,
                                    current_english_usage: null,
                                    english_learning_exp: null,
                                    total_period_time_studied: null,
                                    course_interest: null,
                                    device_planned: null,
                                    questions: null,
                                    type_user: 'Trial',
                                    ms_role_id: 'student',
                                    is_registered: 1,
                                    student_status: 'New',
                                };
                                // console.log(res, "id ne");
                                vm.v$.$reset();
                                vm.display = false;
                                vm.display1 = false;
                                vm.display2 = true;
                                vm.$toast.add({ severity: 'success', summary: 'Confirmation', detail: 'Student Register Success', life: 3000 });
                            } else {
                                vm.loading = false;
                                vm.$toast.add({ severity: 'warn', summary: 'Confirmation', detail: res.data.message, life: 3000 });
                            }
                        } else {
                            vm.loading = false;
                            if (res.data.status == 204) {
                                vm.data = {
                                    id: null,
                                    given_name_english: null,
                                    surname: null,
                                    given_name_furigana: null,
                                    surname_furigana: null,
                                    email: null,
                                    mobile_number: null,
                                    gender: null,
                                    trial_date: null,
                                    // trial_time: null,
                                    learning_objective: null,
                                    current_english_usage: null,
                                    english_learning_exp: null,
                                    total_period_time_studied: null,
                                    course_interest: null,
                                    device_planned: null,
                                    questions: null,
                                    type_user: 'Trial',
                                    ms_role_id: 'student',
                                    is_registered: 1,
                                    student_status: 'New',
                                };
                                // console.log(res, "id ne");
                                vm.v$.$reset();
                                vm.display = false;
                                vm.display1 = false;
                                vm.display2 = true;
                                vm.$toast.add({ severity: 'success', summary: 'Confirmation', detail: 'Student Register Success', life: 3000 });
                            }
                        }
                    })
                    .catch((err) => {
                        vm.loading = false;
                        console.log(err);
                        vm.$toast.add({ severity: 'error', summary: 'Confirmation', detail: 'There is something wrong with the server', life: 3000 });
                    });
            } else {
                vm.loading = false;
                vm.$toast.add({ severity: 'error', summary: 'Warning', detail: 'There are still invalid data entries', life: 3000 });
            }
        },
        upload() {
            // console.log('iki');
            let bebas = this.$refs.bebas;
            bebas.click();
        },
        ubah_warna() {
            console.log('halooo');
            if (this.par_color == '#e3eff6') {
                this.par_color = 'rgba(4, 124, 193, 0.95';
            } else {
                this.par_color = '#e3eff6';
            }
            this.par = !this.par;
        },
        ubah_warna1() {
            if (this.par_color1 == '#e3eff6') {
                this.par_color1 = 'rgba(4, 124, 193, 0.95)';
            } else {
                this.par_color1 = '#e3eff6';
            }
            this.par = !this.par;
        },
        ubah_warna2() {
            if (this.par_color2 == '#e3eff6') {
                this.par_color2 = 'rgba(4, 124, 193, 0.95)';
            } else {
                this.par_color2 = '#e3eff6';
            }
        },
        toTop() {
            // console.log('woiiiiasjdiasdja');
            this.v$.$touch();
            if (this.display && this.isValid && this.isDirty) {
                this.display = false;
                this.display1 = true;
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            } else {
                this.display = true;
                this.display1 = false;
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                // Jika display adalah false, set display menjadi true
            }
        },
        toTop2() {
            this.display = false;
            this.display1 = false;
            this.display2 = true;
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
    },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Yu+Gothic:wght@400;500&family=Meiryo&display=swap');
body {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}
.hai {
    visibility: hidden;
}

.img-smile {
    border-radius: 15px;
    /* width: 50px;
    height: 50px; */
}
/* .bottom-arrow {
    content: '';
    width: 0;
    height: 0;
    left: 0;
    right: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 10px solid black;
    position: absolute;
    bottom: -10px;
    left: 48%;
} */

* {
    box-sizing: border-box;
}
.line {
    /* height: 20px; */
    /* border-bottom: solid 1px #000; */
    /* margin-top: 100px; */
    /* position: relative; */
    z-index: 1;
}
.arrow {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    height: 20px;
    width: 40px;
    z-index: 2;
    background-color: #fff;
}
.arrow .pin {
    width: calc(50% + 20px);
    height: 1px;
    background: #44486D;
    display: inline-block;
    float: left;
    transform: rotate(60deg);
    transform-origin: 0 0;
}
.arrow .pin:last-child {
    transform: rotate(-60deg);
    transform-origin: 100% 0;
    float: right;
    margin-top: -1px;
}

/* .bottom-arrow.lancip:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: 25px solid;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
} */

.segitiga-menghadap-kanan {
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 30px solid rgba(4, 124, 193, 0.95);
    transition: border-left 0.5s;
}

.segitiga-menghadap-kanan2 {
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 30px solid #e3eff6;
}

.scroll-to-top {
    border-radius: 50%;
    position: fixed;
    /* height: 20px;
  width: 20px; */
    bottom: 30px;
    right: 30px;
    z-index: 1000;
}

.icon-label {
    display: block;
    margin-top: 5px;
    text-align: center;
}

img {
    width: 3vw;
    height: 3vw;
    border-radius: 50%;
    align-items: center;
    object-fit: cover;
}

/* .kotak {
            width: 500px;
            height: 200px;
            position: relative;
            background-color: #f0f0f0;
            border: 2px solid #ccc;
        }

        .segitiga {
            width: 0;
            height: 0;
            border-left: 25px solid transparent; 
            border-right: 25px solid transparent;
            border-top: 50px solid #f0f0f0; 
            position: absolute;
            bottom: -50px; 
            left: 0;
            right: 0;
            margin: auto; 
        }  */
</style>
