<template>
    <div
        class="container-fluid"
        :style="{
            backgroundImage: `url(${require('@/assets/gedung.jpeg')})`,
        }"
    ></div>
    <div class="card">
        <div class="exception-body2 accessdenied">
            <div class="exception-panel">
                <h1>ACCESS</h1>
                <h3>Denied</h3>
                <p>You dont have permission to access this page, please contact administrator for Information</p>
                <Button type="button" class="p-button-success p-button-lg" label="Kembali" @click="$router.go(-1)"></Button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        color() {
            if (this.$appState.colorScheme === 'light') return 'dark';
            return 'light';
        },
    },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
* {
    /* margin: 0;
      padding: 0; */
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}
.container-fluid {
    /* background-image: url('@/assets/gedung.jpeg'); */
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    min-height: 100vh;
    /* background-color:  rgba(225, 225, 225, 0.1); */
    padding: 7%;
    filter: blur(1rem);
    z-index: 0;
    /* color: aqua; */
}

.SSO {
    font-size: 30px;
    line-height: 38px;
    font-weight: 600;
    color: #101828;
    margin-bottom: 0px;
}
.title {
    margin: 0 auto;
    width: 100%;
    max-width: 40rem;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-weight: 400;
    color: #667085;
}

.alert {
    font-size: 10px;
    color: red;
}

.card {
    width: 90%;
    max-width: 440px;
    padding: 5rem 2.5rem;
    height: 600px;
    color: black;
    border-radius: 1rem;
    border: 1px solid transparent;
    /* background-color: rgba(225, 225, 225, 0.1); */
    background-color: #ffffff;

    backdrop-filter: blur(1rem);
    box-shadow: 1.3rem 1.3rem 1.3rem 1.3rem rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    /* border-top-color: rgba(225, 225, 225, 0.5);
    border-left-color: rgba(225, 225, 225, 0.5);
    border-bottom-color: rgba(225, 225, 225, 0.1);
    border-right-color: rgba(225, 225, 225, 0.1); */
}

.card:hover {
    width: 90%;
    max-width: 440px;
    padding: 5rem 2.5rem;
    height: 600px;
    border-radius: 1rem;
    border: 1px solid transparent;
    color: black;
    background-color: #ffffff;
    backdrop-filter: blur(1rem);
    box-shadow: 1.3rem 1.3rem 1.3rem 1.3rem rgba(0, 0, 0, 0.5);

    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.btn {
    width: 100%;
    background-color: #027a48;
    color: black;
    letter-spacing: 1px;
}

.btn:hover {
    width: 100%;
    background-color: #027a48;
    color: #ffffff;
    letter-spacing: 1px;
}

.exception-body2 {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100%;
    margin: 20 20 20 20;
}
.exception-body2.notfound .exception-panel h1 {
    color: #0f8bfd;
    text-shadow: 0px 0px 50px rgba(15, 139, 253, 0.2);
}
.exception-body2.notfound .exception-panel h3 {
    color: #013c72;
}
.exception-body2.accessdenied .exception-panel h1 {
    color: #0f8bfd;
    text-shadow: 0px 0px 50px rgba(15, 139, 253, 0.2);
    font-size: 100px;
    line-height: 2;
}
.exception-body2.accessdenied .exception-panel h3 {
    color: #013c72;
}
.exception-body.error .exception-panel h1 {
    color: #fc6161;
    text-shadow: 0px 0px 50px rgba(252, 97, 97, 0.2);
}
.exception-body2.error .exception-panel h3 {
    color: #fd9393;
    max-width: 720px;
}
.exception-body2 .exception-panel {
    text-align: center;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: -200px;
}
.exception-body2 .exception-panel h1 {
    font-size: 140px;
    font-weight: 900;
    margin-bottom: 0;
}
.exception-body2 .exception-panel h3 {
    font-size: 80px;
    font-weight: 900;
    margin-top: -90px;
    margin-bottom: 50px;
}
.exception-body2 .exception-panel p {
    font-size: 24px;
    font-weight: 400;
    max-width: 320px;
}
.exception-body2 .exception-panel button {
    margin-top: 50px;
}
.exception-body2 .exception-footer {
    position: absolute;
    bottom: 60px;
    align-items: center;
    display: flex;
}
.exception-body2 .exception-footer .exception-logo {
    width: 34px;
}
.exception-body2 .exception-footer .exception-appname {
    width: 72px;
    margin-left: 1rem;
}

@media (max-width: 576px) {
    .exception-body2 {
        background-size: auto;
    }
    .exception-body2.accessdenied .exception-panel h1,
    .exception-body2.error .exception-panel h1 {
        font-size: 70px;
    }
    .exception-body2.accessdenied .exception-panel h3,
    .exception-body2.error .exception-panel h3 {
        font-size: 40px;
        margin-top: -30px;
    }
    .exception-body2 .exception-panel {
        margin-top: 0;
    }
    .exception-body2 .exception-panel h3 {
        line-height: 0.75;
    }
    .exception-body2 .exception-panel button {
        margin-top: 0;
    }
}
</style>
