<template>
    <div style="width: 100%; flex-direction: column; justify-content: center; align-items: center; gap: 60px; display: inline-flex; background-color: #ffffff">
        <div
            style="width: 100%; height: 180px; position: relative; background-image: url('../../assets/header.png')"
            :style="{ 'background-image': 'url(' + require('../../assets/img03.png') + ')' }"
        ></div>
        <div
            style="
                width: 600px;
                padding-top: 10px;
                padding-bottom: 10px;
                top: 130px;
                position: absolute;
                background: rgba(4, 124, 193, 0.95);
                justify-content: center;
                align-items: center;
                display: inline-flex;
            "
        >
            <div>
                <p style="text-align: center; color: white; font-size: 30px; font-family: Yu Gothic; font-weight: 700">THANKS</p>
                <p class="mb-3" style="text-align: center; color: white; font-size: 15px; font-family: Yu Gothic">無料体験レッスンお申し込み（完了）</p>
            </div>
        </div>

        <div style="background-color: #ffffff; width: 70%; height: 700px">
            <div class="flex justify-content-start align-items-center mt-4"></div>

            <div class="flex mt-4">
                <div class="flex justify-content-center align-items-center" :style="{ 'background-color': par_color }" style="width: 33%; height: 40px; text-align: center; font-weight: 700">
                    入力画面
                </div>
                <div class="flex justify-content-start align-items-center text-align-center" style="width: 33%; height: 40px; transition: background-color 0.5s; background-color: #e3eff6">
                    <!-- <div class="segitiga-menghadap-kanan"></div> -->
                    <div class="" style="display: flex; justify-content: center; align-items: center; text-align: center; margin-left: 170px; font-weight: 700">確認画面</div>
                    <!-- <i style="font-size: 43px; color: rgba(4, 124, 193, 0.95); margin-bottom: 20px;" class="fa fa-play " aria-hidden="true"></i> -->
                </div>
                <div
                    class="flex justify-content-start align-items-center text-align-center"
                    style="width: 33%; height: 40px; background-color: rgba(4, 124, 193, 0.95); transition: background-color 0.5s"
                >
                    <!-- <div :class="{ 'segitiga-menghadap-kanan2': par, 'segitiga-menghadap-kanan': !par }"></div> -->
                    <div class="" style="text-align: center; margin-left: 170px; font-weight: 700">完了</div>
                    <!-- <i style="font-size: 43px; color: rgba(4, 124, 193, 0.95); margin-bottom: 20px;" class="fa fa-play " aria-hidden="true"></i> -->
                </div>
            </div>
            <div class="mt-8" style="text-align: center">
                体験レッスンのお申し込みを誠に有難うございます。<br />
                ２営業日以内にご登録のメールアドレスへご返信をさせていただきます。
            </div>
            <div class="mt-6" style="text-align: center">
                万が一2日以内にこちらからのご返信がない場合は、<br />
                通信エラー等でフォームが送信できていない可能性がございますので <br />
                大変恐れ入りますが support@live-english.jp までご連絡ください。
            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { useRouter } from 'vue-router';
import { required, email } from '@vuelidate/validators';
export default {
    setup() {
        const router = useRouter();
        return { v$: useVuelidate({ $lazy: true, $autoDirty: true }), router };
        // const background = require('@/assets/header.png');
    },
    data() {
        return {
            data: {
                id: null,
                given_name_english: null,
                surname_english: null,
                given_name_furigana: null,
                surname_furigana: null,
                gender: null,
                // birthdate: null,
                desired_date_of_exp: null,
                mobile_number: null,
                email: null,
                native_of: null,
                current_residence: null,
                highest_level_education: null,
                major_education: null,
                graduated_from: null,
                ms_role_id: 'student',
                ms_nation_id: null,
                learning_objective: [],
                student_status: 'New',
            },
            checkboxValue: [],
            bebas: null,
            display: true,
            par: true,
            par_color: '#e3eff6',
            par_color1: 'rgba(4, 124, 193, 0.95)',
            par_color2: '#e3eff6',
        };
    },
    computed: {
        formString() {
            return JSON.stringify(this.data);
        },
        isValid() {
            return !this.v$.$invalid;
        },
        isDirty() {
            return this.v$.$anyDirty;
        },
    },
    validations() {
        return {
            data: {
                given_name_english: {
                    required,
                },
                surname_english: {
                    required,
                },
                given_name_furigana: {
                    required,
                },
                surname_furigana: {
                    required,
                },
                desired_date_of_exp: {
                    required,
                },
                gender: {
                    required,
                },
                birthdate: {
                    required,
                },
                mobile_number: {
                    required,
                },
                email: {
                    required,
                    email,
                },
                native_of: {
                    required,
                },
                current_residence: {
                    required,
                },
                highest_level_education: {
                    required,
                },
                major_education: {
                    required,
                },
                graduated_from: {
                    required,
                },
                ms_role_id: {
                    required,
                },
                ms_nation_id: {
                    required,
                },
            },
        };
    },
    methods: {
        upload() {
            // console.log('iki');
            let bebas = this.$refs.bebas;
            bebas.click();
        },
        ubah_warna() {
            console.log('halooo');
            if (this.par_color == 'rgba(4, 124, 193, 0.95)') {
                this.par_color = '#e3eff6';
            } else {
                this.par_color = '#rgba(4, 124, 193, 0.95';
            }
            this.par = !this.par;
        },
        ubah_warna1() {
            if (this.par_color1 == '#e3eff6') {
                this.par_color1 = 'rgba(4, 124, 193, 0.95)';
            } else {
                this.par_color1 = '#e3eff6';
            }
            this.par = !this.par;
        },
        ubah_warna2() {
            if (this.par_color2 == '#e3eff6') {
                this.par_color2 = 'rgba(4, 124, 193, 0.95)';
            } else {
                this.par_color2 = '#e3eff6';
            }
        },
    },
};
</script>

<style scoped>
.hai {
    visibility: hidden;
}
img {
    width: 50px;
    height: 50px;
}
.segitiga-menghadap-kanan {
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 30px solid rgba(4, 124, 193, 0.95);
    transition: border-left 0.5s;
}
.segitiga-menghadap-kanan2 {
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 30px solid #e3eff6;
}
</style>
