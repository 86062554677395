<template>
    <div style="width: 100%; flex-direction: column; justify-content: center; align-items: center; gap: 60px; display: inline-flex; background-color: #ffffff">
        <div style="width: 100%; height: 180px; position: relative; background-image: url('../../assets/header.png')" :style="{ 'background-image': 'url(' + require('../../assets/header.png') + ')' }"></div>
        <div
            style="
                width: 600px;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 183.99px;
                padding-right: 183.98px;
                top: 130px;
                position: absolute;
                background: rgba(4, 124, 193, 0.95);
                justify-content: center;
                align-items: center;
                display: inline-flex;
            "
        >
            <div>
                <p style="text-align: center; color: white; font-size: 30px; font-family: Yu Gothic; font-weight: 700">Trial Lesson</p>
                <p class="mb-3" style="text-align: center; color: white; font-size: 15px; font-family: Yu Gothic; line-height: 3px">無料体験レッスンお申し込み</p>
            </div>
        </div>

        <div  style="background-color: #ffffff; width: 70%; height: 100%">
            <div  class="flex justify-content-start align-items-center mt-4">
                <div style="font-family: Yu Gothic; font-weight: 700; text-align: center; font-size: 28px; color: #047cc1">これからの学習課題がわかる、欧米ネイティブ講師との体験レッスン</div>
            </div>

            <div>
                <img style="width: 100%; height: auto" src="@/assets/header2.png" alt="" />
            </div>
            <div style="witdh: 100%; height: 150px; border: 1px solid; border-radius: 8px" class="flex justify-content-center mt-4">
                <div class="col-12 md:col-3" style="flex-direction: column">
                    <div class="flex justify-content-center">
                        <Button class="p-button-text" style="font-size: 16px; font-weight: 700">Step 01</Button>
                    </div>
                    <div style="text-align: center; color: #333333; font-size: 20px; font-family: Yu Gothic; font-weight: 700; line-height: 32px; word-wrap: break-word">無料体験レッスンお申 込み</div>
                </div>
                <div class="col-12 md:col-9 mt-5 ml-3" style="flex-direction: column">
                    <div style="text-align: left; color: #333333; font-size: 16px; font-family: Yu Gothic; font-weight: 400; line-height: 32px; word-wrap: break-word">
                        ページ下部の無料体験レッスンお申込みフォームよりお申込みください。日程の調整・確定のため、ご登録のメールアド レス宛にご連絡をさしあげます。お申し込み前までにSkypeアカウントをご準備ください。
                    </div>
                </div>
            </div>

            <div style="witdh: 100%; height: 150px; border: 1px solid; border-radius: 8px" class="flex justify-content-center mt-4">
                <div class="col-12 md:col-3" style="flex-direction: column">
                    <div class="flex justify-content-center">
                        <Button class="p-button-text" style="font-size: 16px; font-weight: 700">Step 02</Button>
                    </div>
                    <div style="text-align: center; color: #333333; font-size: 20px; font-family: Yu Gothic; font-weight: 700; line-height: 32px; word-wrap: break-word">事前カウンセリング (体験当日)</div>
                </div>
                <div class="col-12 md:col-9 mt-5 ml-3" style="flex-direction: column">
                    <div style="text-align: left; color: #333333; font-size: 16px; font-family: Yu Gothic; font-weight: 400; line-height: 32px; word-wrap: break-word">
                        まず日本人スタッフよりご連絡を差し上げます。スカイプ音声のチェックと共に、学習目的や課題などの事前カウンセリン グを行い、担当講師に引き継ぎをいたします。初級レベルの方、オンライン英会話学習が初めての方もご安心ください。
                    </div>
                </div>
            </div>

            <div style="witdh: 100%; height: 150px; border: 1px solid; border-radius: 8px" class="flex justify-content-center mt-4">
                <div class="col-12 md:col-3" style="flex-direction: column">
                    <div class="flex justify-content-center">
                        <Button class="p-button-text" style="font-size: 16px; font-weight: 700">Step 03</Button>
                    </div>
                    <div style="text-align: center; color: #333333; font-size: 20px; font-family: Yu Gothic; font-weight: 700; line-height: 32px; word-wrap: break-word">30分間のレッスン (体 験当日)</div>
                </div>
                <div class="col-12 md:col-9 mt-5 ml-3" style="flex-direction: column">
                    <div style="text-align: left; color: #333333; font-size: 16px; font-family: Yu Gothic; font-weight: 400; line-height: 32px; word-wrap: break-word">
                        ネイティブ講師とマンツーマンでレッスンをご受講いただきます。自己紹介から入り、体験用ワークシート等を使用しなが らあなたの学習に適したレベルを選定します。
                    </div>
                </div>
            </div>

            <div style="witdh: 100%; height: 150px; border: 1px solid; border-radius: 8px" class="flex justify-content-center mt-4">
                <div class="col-12 md:col-3" style="flex-direction: column">
                    <div class="flex justify-content-center">
                        <Button class="p-button-text" style="font-size: 16px; font-weight: 700">Step 04</Button>
                    </div>
                    <div style="text-align: center; color: #333333; font-size: 20px; font-family: Yu Gothic; font-weight: 700; line-height: 32px; word-wrap: break-word">学習相談とご案内 (体 験当日)</div>
                </div>
                <div class="col-12 md:col-9 mt-5 ml-3" style="flex-direction: column">
                    <div style="text-align: left; color: #333333; font-size: 16px; font-family: Yu Gothic; font-weight: 400; line-height: 32px; word-wrap: break-word">
                        レッスンへのご要望や講師からのフィードバックをもとに、日本人スタッフと今後のレッスンの進め方を決めます。システ ム・コースのご案内も致しますので、ご不安な点はどんな事でもご相談ください。
                    </div>
                </div>
            </div>

            <div style="witdh: 100%; height: 150px; border: 1px solid; border-radius: 8px" class="flex justify-content-center mt-4">
                <div class="col-12 md:col-3" style="flex-direction: column">
                    <div class="flex justify-content-center">
                        <Button class="p-button-text" style="font-size: 16px; font-weight: 700">Step 05</Button>
                    </div>
                    <div style="text-align: center; color: #333333; font-size: 20px; font-family: Yu Gothic; font-weight: 700; line-height: 32px; word-wrap: break-word">コースお申込み&ご予 約開始</div>
                </div>
                <div class="col-12 md:col-9 mt-5 ml-3" style="flex-direction: column">
                    <div style="text-align: left; color: #333333; font-size: 16px; font-family: Yu Gothic; font-weight: 400; line-height: 32px; word-wrap: break-word">
                        弊社サービスを気に入っていただけましたら、お好きなコースをお申し込み下さい。経験豊富なネイティブ講師があなた との学習を楽しみにお待ちしています。
                    </div>
                </div>
            </div>

            <div class="flex mt-4">
                <div class="flex justify-content-center align-items-center" :style="{ 'background-color': par_color }" style="width: 33%; height: 40px;  text-align: center; font-weight: 700">入力画面</div>
                <div class="flex justify-content-start align-items-center text-align-center" style="width: 33%; height: 40px; transition: background-color 0.5s" :style="{ 'background-color': par_color1 }">
                    <div class="segitiga-menghadap-kanan"></div>
                    <div class="" style="text-align: center; margin-left: 170px; font-weight: 700">確認画面</div>
                    <!-- <i style="font-size: 43px; color: rgba(4, 124, 193, 0.95); margin-bottom: 20px;" class="fa fa-play " aria-hidden="true"></i> -->
                </div>
                <div class="flex justify-content-start align-items-center text-align-center" style="width: 33%; height: 40px; background-color: #e3eff6; transition: background-color 0.5s" :style="{ 'background-color': par_color2 }">
                    <!-- <div :class="{ 'segitiga-menghadap-kanan2': par, 'segitiga-menghadap-kanan': !par }"></div> -->
                    <div class="" style="text-align: center; margin-left: 170px; font-weight: 700">完了</div>
                    <!-- <i style="font-size: 43px; color: rgba(4, 124, 193, 0.95); margin-bottom: 20px;" class="fa fa-play " aria-hidden="true"></i> -->
                </div>
            </div>
            <Button class="mt-5" @click="ubah_warna">Ubah warna</Button>

            <Button class="mt-5" @click="ubah_warna1">Ubah warna</Button>
            <Button class="mt-5" @click="ubah_warna2">Ubah warna</Button>
            <div class="flex mt-4">
                <p style="color: red">※</p>

                <p>は必須項目です。</p>
            </div>
            <div class="p-fluid formgrid grid">
                <!-- firstname english -->
                <div class="field grid col-12 md:col-12">
                    <div class="col-12 mt-4 md:col-3 flex justify-content-end">
                        <label for="given_name_english">お名前 <span style="color: red">※</span></label>
                    </div>
                    <div class="flex md:col-4">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-8">
                            <InputText
                                placeholder="姓"
                                v-model.lazy="data.given_name_english"
                                @blur="v$.data.given_name_english.$touch()"
                                :class="{ 'p-invalid': v$.data.given_name_english.$invalid ? !v$.data.given_name_english.$anyError : null }"
                                id="given_name_english"
                                type="text"
                            />
                            <small v-if="v$.data.given_name_english.$invalid" :class="{ 'p-error': v$.data.given_name_english.$invalid ? !v$.data.given_name_english.$anyError : null }">{{ v$.data.given_name_english.$errors[0].$message }}</small>
                        </div>
                        <div class="md:col-8">
                            <InputText
                                placeholder="名"
                                v-model.lazy="data.surname_english"
                                @blur="v$.data.surname_english.$touch()"
                                :class="{ 'p-invalid': v$.data.surname_english.$invalid ? !v$.data.surname_english.$anyError : null }"
                                id="surname_english"
                                type="text"
                            />
                            <small v-if="v$.data.surname_english.$invalid" :class="{ 'p-error': v$.data.surname_english.$invalid ? !v$.data.surname_english.$anyError : null }">{{ v$.data.surname_english.$errors[0].$message }}</small>
                        </div>
                    </div>
                </div>

                <!-- first name furigana -->
                <div class="field grid col-12 md:col-12">
                    <div class="col-12 mt-4 md:col-3 flex justify-content-end">
                        <label for="given_name_furigana">フリガナ <span style="color: red">※</span></label>
                    </div>
                    <div class="flex md:col-4">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-8">
                            <InputText
                                placeholder="セイ"
                                v-model.lazy="data.given_name_furigana"
                                @blur="v$.data.given_name_furigana.$touch()"
                                :class="{ 'p-invalid': v$.data.given_name_furigana.$invalid ? !v$.data.given_name_furigana.$anyError : null }"
                                id="given_name_furigana"
                                type="text"
                            />
                            <small v-if="v$.data.given_name_furigana.$invalid" :class="{ 'p-error': v$.data.given_name_furigana.$invalid ? !v$.data.given_name_furigana.$anyError : null }">{{ v$.data.given_name_furigana.$errors[0].$message }}</small>
                        </div>
                        <div class="md:col-8">
                            <InputText
                                placeholder="メイ"
                                v-model.lazy="data.surname_furigana"
                                @blur="v$.data.surname_furigana.$touch()"
                                :class="{ 'p-invalid': v$.data.surname_furigana.$invalid ? !v$.data.surname_furigana.$anyError : null }"
                                id="surname_furigana"
                                type="text"
                            />
                            <small v-if="v$.data.surname_furigana.$invalid" :class="{ 'p-error': v$.data.surname_furigana.$invalid ? !v$.data.surname_furigana.$anyError : null }">{{ v$.data.surname_furigana.$errors[0].$message }}</small>
                        </div>
                    </div>
                </div>

                <!-- Email -->
                <div class="field grid col-12 md:col-12">
                    <div class="col-12 mt-4 md:col-3 flex justify-content-end">
                        <label for="email">Email <span style="color: red">※</span></label>
                    </div>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <InputText placeholder="example@example.com" v-model.lazy="data.email" @blur="v$.data.email.$touch()" :class="{ 'p-invalid': v$.data.email.$invalid ? !v$.data.email.$anyError : null }" id="email" type="text" />
                            <small v-if="v$.data.email.$invalid" :class="{ 'p-error': v$.data.email.$invalid ? !v$.data.email.$anyError : null }">{{ v$.data.email.$errors[0].$message }}</small> <br />
                            ご連絡はメールで差し上げております。<br />
                            @live-english.jpのドメインを受信できるように設定ください。
                        </div>
                    </div>
                </div>

                <!-- phone number -->
                <div class="field grid col-12 md:col-12">
                    <div class="col-12 mt-4 md:col-3 flex justify-content-end">
                        <label for="mobile_number">携帯電話番号 <span style="color: red">※</span></label>
                    </div>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <InputText v-model.lazy="data.mobile_number" @blur="v$.data.mobile_number.$touch()" :class="{ 'p-invalid': v$.data.mobile_number.$invalid ? !v$.data.mobile_number.$anyError : null }" id="mobile_number" type="text" />
                            <small v-if="v$.data.mobile_number.$invalid" :class="{ 'p-error': v$.data.mobile_number.$invalid ? !v$.data.mobile_number.$anyError : null }">{{ v$.data.mobile_number.$errors[0].$message }}</small>
                        </div>
                    </div>
                </div>

                <!-- gender -->
                <div class="field grid col-12 md:col-12">
                    <div class="col-12 mt-3 md:col-3 flex justify-content-end">
                        <label for="gender">性別 <span style="color: red">※</span></label>
                    </div>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-2">:</span>
                        <div class="md:col-12">
                            <RadioButton id="option1" class="" name="option" value="男性・Male" v-model="data.gender" />
                            <label class="ml-2" for="option1">男性・Male</label>
                            <RadioButton id="option1" class="ml-6" name="option" value="女性・Female" v-model="data.gender" />
                            <label class="ml-2" for="option1">女性・Female</label>
                            <RadioButton id="option1" class="ml-6" name="option" value="その他・Other" v-model="data.gender" />
                            <label class="ml-2" for="option1">その他・Other</label>
                            <div class="flex justify-content-start">
                                <small v-if="v$.data.mobile_number.$invalid" :class="{ 'p-error': v$.data.mobile_number.$invalid ? !v$.data.mobile_number.$anyError : null }">{{ v$.data.mobile_number.$errors[0].$message }}</small>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field grid col-12 md:col-12">
                    <div class="col-12 mt-4 md:col-3 flex justify-content-end">
                        <label for="desire_date">体験希望日 <span style="color: red">※</span></label>
                    </div>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <Calendar :selectOtherMonths="true" id="desire_date" v-model="data.desired_date_of_exp"  iconDisplay="input"  />
                            <small v-if="v$.data.desired_date_of_exp.$invalid" :class="{ 'p-error': v$.data.desired_date_of_exp.$invalid ? !v$.data.desired_date_of_exp.$anyError : null }">{{ v$.data.desired_date_of_exp.$errors[0].$message }}</small>
                            <p style="color: red">※日程調整のため48時間後以内の回を避け、3日後以降の日程をご選択くださいますようお願い致します。</p>
                        </div>
                    </div>
                </div>

                <div class="field grid col-12 md:col-12">
                    <label for="desire_time" class="col-12 mt-2 md:col-3 flex justify-content-end"
                        >希望時間 
                        <p style="color: red">※</p></label
                    >
                    <div class="flex md:col-3">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <Calendar placeholder="" id="desire_time" v-model="data.desired_date_of_exp"  iconDisplay="input"  timeOnly />
                            <small v-if="v$.data.desired_date_of_exp.$invalid" :class="{ 'p-error': v$.data.desired_date_of_exp.$invalid ? !v$.data.desired_date_of_exp.$anyError : null }">{{ v$.data.desired_date_of_exp.$errors[0].$message }}</small>
                        </div>
                    </div>
                </div>
                <div style="font-size: 16px; font-weight: 700; font-family: Yu Gothic">事前アンケート</div>

                <div class="field flex col-12 md:col-12 mt-4">
                    <label for="firstname_kanji" style="text-align: right" class="col-12 md:col-3 mt-2 pr-3">1. 今回の学習目的 (該当するすべてにクリック) <span style="color: red">※</span> </label>
                    <div class="flex md:col-9 pl-0">
                        <span class="px-1">:</span>
                        <div style="flex-direction: column">
                            <div>
                                <Checkbox id="checkOption1" name="option" value="現在の仕事のため" class="ml-3" v-model="data.learning_objective" />
                                <label class="ml-2" for="checkOption1">現在の仕事のため</label>
                                <Checkbox id="checkOption1" name="option" value="今後の仕事で英語力が求められるため" class="ml-3" v-model="data.learning_objective" />
                                <label class="ml-2" for="checkOption1">今後の仕事で英語力が求められるため</label>
                                <Checkbox id="checkOption1" name="option" value="将来のキャリア・転職に備えて" class="ml-3" v-model="data.learning_objective" />
                                <label class="ml-2" for="checkOption1">将来のキャリア・転職に備えて</label>
                                <Checkbox id="checkOption1" name="option" value="昇給昇格のため" class="ml-3" v-model="data.learning_objective" />
                                <label class="ml-2" for="checkOption1">昇給昇格のため</label>
                            </div>
                            <div class="mt-2">
                                <Checkbox id="checkOption1" name="option" value="留学のため(海外大学院・大学)" class="ml-3" v-model="data.learning_objective" />
                                <label class="ml-2" for="checkOption1">留学のため(海外大学院・大学)</label>
                                <Checkbox id="checkOption1" name="option" value="語学留学のため" class="ml-3" v-model="data.learning_objective" />
                                <label class="ml-2" for="checkOption1">語学留学のため</label>
                                <Checkbox id="checkOption1" name="option" value="ワーキングホリデーのため" class="ml-3" v-model="data.learning_objective" />
                                <label class="ml-2" for="checkOption1">ワーキングホリデーのため</label>
                                <Checkbox id="checkOption1" name="option" value="TOEFL/IELTSのため" class="ml-3" v-model="data.learning_objective" />
                                <label class="ml-2" for="checkOption1">TOEFL/IELTSのため</label>
                            </div>
                            <div class="mt-2">
                                <Checkbox id="checkOption1" name="option" value="TOEIC/英検のため" class="ml-3" v-model="data.learning_objective" />
                                <label class="ml-2" for="checkOption1">TOEIC/英検のため</label>
                                <Checkbox id="checkOption1" name="option" value="海外赴任に備えるため" class="ml-3" v-model="data.learning_objective" />
                                <label class="ml-2" for="checkOption1">海外赴任に備えるため</label>
                                <Checkbox id="checkOption1" name="option" value="趣味・教養のため" class="ml-3" v-model="data.learning_objective" />
                                <label class="ml-2" for="checkOption1">趣味・教養のため</label>
                                <Checkbox id="checkOption1" name="option" value="友人家族との交流のため" class="ml-3" v-model="data.learning_objective" />
                                <label class="ml-2" for="checkOption1">友人家族との交流のため</label>
                            </div>
                            <div class="mt-2">
                                <Checkbox id="checkOption1" name="option" value="英語力の維持のため" class="ml-3" v-model="data.learning_objective" />
                                <label class="ml-2" for="checkOption1">英語力の維持のため</label>
                                <Checkbox id="checkOption1" name="option" value="その他" class="ml-3" v-model="data.learning_objective" />
                                <label class="ml-2" for="checkOption1">その他</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field flex col-12 md:col-12 mt-4">
                    <label for="firstname_kanji" style="text-align: right" class="col-12 md:col-3 mt-2 pr-3">2. 現在の英語使用状況 (該当するすべてにクリック)<span style="color: red">※</span> </label>
                    <div class="flex md:col-9 pl-0">
                        <span class="px-1">:</span>
                        <div style="flex-direction: column; font-size: 14px">
                            <div>
                                <Checkbox id="checkOption1" name="option" value="ほぼ毎日英語でのコミュニケーションがある" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">ほぼ毎日英語でのコミュニケーションがある</label>
                                <Checkbox id="checkOption1" name="option" value="頻繁に英語メールのやりとりがある" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">頻繁に英語メールのやりとりがある</label>
                                <Checkbox id="checkOption1" name="option" value="資料やメールを読むことは多い" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">資料やメールを読むことは多い</label>
                            </div>
                            <div class="mt-2">
                                <Checkbox id="checkOption1" name="option" value="資料やメールを書くことがある" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">資料やメールを書くことがある</label>
                                <Checkbox id="checkOption1" name="option" value="仕事で話すことは多い" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">仕事で話すことは多い</label>
                                <Checkbox id="checkOption1" name="option" value="仕事以外で話すことは多い" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">仕事以外で話すことは多い</label>
                            </div>
                            <div class="mt-2">
                                <Checkbox id="checkOption1" name="option" value="以前は英語を使っていたが現状は少ない" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">以前は英語を使っていたが現状は少ない</label>
                                <Checkbox id="checkOption1" name="option" value="英語は使っていない" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">英語は使っていない</label>
                                <Checkbox id="checkOption1" name="option" value="その他" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">その他</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field flex col-12 md:col-12 mt-4">
                    <label for="firstname_kanji" style="text-align: right" class="col-12 md:col-3 mt-2 pr-3">3. 英語学習経験について (該当するすべてにクリック) <span style="color: red">※</span> </label>
                    <div class="flex md:col-9 pl-0">
                        <span class="px-1">:</span>
                        <div style="flex-direction: column; font-size: 14px">
                            <div>
                                <Checkbox id="checkOption1" name="option" value="通学型英会話スクール (グループ)" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">通学型英会話スクール (グループ)</label>
                                <Checkbox id="checkOption1" name="option" value="通学型英会話スクール (マンツーマン)" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">通学型英会話スクール (マンツーマン)</label>
                            </div>
                            <div class="mt-2">
                                <Checkbox id="checkOption1" name="option" value="オンライン英会話スクール (ネイティブ講師)" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">オンライン英会話スクール (ネイティブ講師)</label>
                                <Checkbox id="checkOption1" name="option" value="オンライン英会話スクール (非ネイティブ講師)" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">オンライン英会話スクール (非ネイティブ講師)</label>
                            </div>
                            <div class="mt-2">
                                <Checkbox id="checkOption1" name="option" value="TOEIC/TOEFLなどの試験対策学校" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">TOEIC/TOEFLなどの試験対策学校</label>
                                <Checkbox id="checkOption1" name="option" value="留学 (海外大学・大学院)" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">留学 (海外大学・大学院)</label>
                                <Checkbox id="checkOption1" name="option" value="留学 (語学留学)" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">留学 (語学留学)</label>
                            </div>
                            <div class="mt-2">
                                <Checkbox id="checkOption1" name="option" value="ワーキングホリデー等の海外生活" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">ワーキングホリデー等の海外生活</label>
                                <Checkbox id="checkOption1" name="option" value="海外勤務" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">海外勤務</label>
                                <Checkbox id="checkOption1" name="option" value="独学" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">独学</label>
                                <Checkbox id="checkOption1" name="option" value="学習経験は特になし" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">学習経験は特になし</label>
                                <Checkbox id="checkOption1" name="option" value="その他" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">その他</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field grid col-12 md:col-12">
                    <label style="text-align: right" for="firstname_kanji" class="col-12 mb-2 md:col-3 mt-2 pr-3">4. 上記のうち、通学型英会話スクール かオンライン英会話、またはその両方 で学習されたトータルの期間を教えて ください。 </label>
                    <div class="flex md:col-9 pl-0">
                        <span class="px-1 ml-2 mt-1">:</span>
                        <div class="md:col-12 flex">
                            <RadioButton id="option1" class="ml-2" name="option" value="5ヶ月未満" v-model="radioValue" />
                            <label class="ml-2" for="option1">5ヶ月未満</label>
                            <RadioButton id="option1" class="ml-6" name="option" value="1年未満" v-model="radioValue" />
                            <label class="ml-2" for="option1">1年未満</label>
                            <RadioButton id="option1" class="ml-6" name="option" value="1~2年" v-model="radioValue" />
                            <label class="ml-2" for="option1">1~2年</label>
                            <RadioButton id="option1" class="ml-6" name="option" value="3~5年" v-model="radioValue" />
                            <label class="ml-2" for="option1">3~5年</label>
                            <RadioButton id="option1" class="ml-6" name="option" value="5年以上" v-model="radioValue" />
                            <label class="ml-2" for="option1">5年以上</label>
                        </div>
                    </div>
                </div>

                <div class="field flex col-12 md:col-12 mt-4">
                    <label for="firstname_kanji" style="text-align: right" class="col-12 md:col-3 mt-2 pr-3">5. ご興味をお持ちのコース(複数選択化) </label>
                    <div class="flex md:col-9 pl-0">
                        <span class="px-1">:</span>
                        <div style="flex-direction: column; font-size: 14px">
                            <div>
                                <Checkbox id="checkOption1" name="option" value="一般ビジネス英会話" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">一般ビジネス英会話</label>
                                <Checkbox id="checkOption1" name="option" value="ディスカッション" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">ディスカッション</label>
                                <Checkbox id="checkOption1" name="option" value="ビジネスミーティング" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">ビジネスミーティング</label>
                                <Checkbox id="checkOption1" name="option" value="英語面接準備" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">英語面接準備</label>
                                <Checkbox id="checkOption1" name="option" value="MBA留学準備英語" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">MBA留学準備英語</label>
                            </div>
                            <div class="mt-2">
                                <Checkbox id="checkOption1" name="option" value="IELTS S&W 理解・対策" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">IELTS S&W 理解・対策</label>
                                <Checkbox id="checkOption1" name="option" value="短期集中学習" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">短期集中学習</label>
                                <Checkbox id="checkOption1" name="option" value="university(大学生)" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">university(大学生)</label>
                                <Checkbox id="checkOption1" name="option" value="teens(中高生)" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">teens(中高生)</label>
                            </div>
                            <div class="mt-2">
                                <Checkbox id="checkOption1" name="option" value="日常英会話" class="ml-3" v-model="checkboxValue" />
                                <label class="ml-2" for="checkOption1">日常英会話</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field grid col-12 md:col-12">
                    <label style="text-align: right" for="firstname_kanji" class="col-12 md:col-3 mb-3 flex justify-content-end">6. 体験レッスンに使用予定のディバイス <span style="color: red">※</span></label>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <InputText placeholder="デスクトップパソコン" id="firstname_kanji" type="text" />
                            <p style="color: red">※体験時は受講システム等のご案内がございます為、スマートフォンのみでのご受講はご遠慮頂いております。</p>
                        </div>
                    </div>
                </div>

                <div class="field grid col-12 md:col-12">
                    <label style="text-align: right" for="firstname_kanji" class="col-12 md:col-3 mt-2 pr-3">7. その他、何かご要望やご質問などご ざいましたら是非お知らせください </label>
                    <div class="flex md:col-9">
                        <span class="px-1 mt-3">:</span>
                        <div class="md:col-12">
                            <Textarea id="firstname_kanji" type="text" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="text-align: center">
            <div>アンケートへのご回答を誠に有難うございます。日程のご相談のため、ご登録のメールアドレスに折り返しご連絡をさせていただきます。</div>
            <div class="mt-4">フォームをご送信の際には、利用規約とプライバシーポリシーをご確認ください</div>
            <div class="mt-4">
                <Button style="width: 50%; height: 50px; text-align: center" label="確認画面" @click="display=false"></Button>
            </div>
        </div>

        <div style="height: 50px"></div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { useRouter } from 'vue-router';
import { required, email } from '@vuelidate/validators';
export default {
    setup() {
        const router = useRouter();
        return { v$: useVuelidate({ $lazy: true, $autoDirty: true }), router };
        // const background = require('@/assets/header.png');
    },
    data() {
        return {
            data: {
                id: null,
                given_name_english: null,
                surname_english: null,
                given_name_furigana: null,
                surname_furigana: null,
                gender: null,
                // birthdate: null,
                desired_date_of_exp: null,
                mobile_number: null,
                email: null,
                native_of: null,
                current_residence: null,
                highest_level_education: null,
                major_education: null,
                graduated_from: null,
                ms_role_id: 'student',
                ms_nation_id: null,
                learning_objective: [],
                student_status: "New"
            },
            checkboxValue: [],
            bebas: null,
            display:true,
            par: true,
            par_color: 'rgba(4, 124, 193, 0.95)',
            par_color1: '#e3eff6',
            par_color2: '#e3eff6',
        };
    },
    computed: {
        formString() {
            return JSON.stringify(this.data);
        },
        isValid() {
            return !this.v$.$invalid;
        },
        isDirty() {
            return this.v$.$anyDirty;
        },
    },
    validations() {
        return {
            data: {
                given_name_english: {
                    required,
                },
                surname_english: {
                    required,
                },
                given_name_furigana: {
                    required,
                },
                surname_furigana: {
                    required,
                },
                desired_date_of_exp: {
                    required,
                },
                gender: {
                    required,
                },
                birthdate: {
                    required,
                },
                mobile_number: {
                    required,
                },
                email: {
                    required,
                    email,
                },
                native_of: {
                    required,
                },
                current_residence: {
                    required,
                },
                highest_level_education: {
                    required,
                },
                major_education: {
                    required,
                },
                graduated_from: {
                    required,
                },
                ms_role_id: {
                    required,
                },
                ms_nation_id: {
                    required,
                },
            },
        };
    },
    methods: {
        upload() {
            // console.log('iki');
            let bebas = this.$refs.bebas;
            bebas.click();
        },
        ubah_warna() {
            console.log("halooo");
            if (this.par_color == 'rgba(4, 124, 193, 0.95)') {
                this.par_color = '#e3eff6';
            } else {
                this.par_color = '#rgba(4, 124, 193, 0.95';
            }
            this.par = !this.par;

        },
        ubah_warna1() {
            if (this.par_color1 == '#e3eff6') {
                this.par_color1 = 'rgba(4, 124, 193, 0.95)';
            } else {
                this.par_color1 = '#e3eff6';
            }
            this.par = !this.par;
        },
        ubah_warna2() {
            if (this.par_color2 == '#e3eff6') {
                this.par_color2 = 'rgba(4, 124, 193, 0.95)';
            } else {
                this.par_color2 = '#e3eff6';
            }
        },
    },
};
</script>

<style scoped>
.hai {
    visibility: hidden;
}
img {
    width: 50px;
    height: 50px;
}
.segitiga-menghadap-kanan {
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 30px solid rgba(4, 124, 193, 0.95);
    transition: border-left 0.5s;
}
.segitiga-menghadap-kanan2 {
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 30px solid #e3eff6;
}
</style>
