<template>
    <Toast />
    <ConfirmDialog></ConfirmDialog>
    <Login v-if="$route.path === '/' || $route.path === '/login'" />
    <Error v-else-if="$route.path === '/error'" />
    <Access v-else-if="$route.path === '/access'" />
    <NotFound v-else-if="$route.path === '/404'" />
    <LandingPage v-else-if="$route.path === '/landing_page'" />
    <TeacherRegister v-else-if="$route.path === '/teacher_register'" />
    <StudentRegister v-else-if="$route.path === '/student_register'" />
    <StudentRegisterConfirm v-else-if="$route.path === '/student_register_confirm'" />
    <StudentRegisterConfirmation v-else-if="$route.path === '/student_register_confirmation'" />
    <StudentRegisterInquiry v-else-if="$route.path === '/student_register_inquiry'" />

    <App
        :theme="theme"
        :colorScheme="colorScheme"
        :componentTheme="componentTheme"
        @change-color-scheme="onChangeColorScheme"
        @change-component-theme="onChangeComponentTheme"
        @change-menu-theme="onChangeMenuTheme"
        v-else
    />
</template>

<script>
import App from './App.vue';
import Error from './pages/Error';
import Access from './pages/Access';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import LandingPage from './pages/LandingPage';
import TeacherRegister from './pages/Register/teacher_register';
import StudentRegister from './pages/Register/student_register';
import StudentRegisterConfirm from './pages/Register/student_register_confirm';
import StudentRegisterConfirmation from './pages/Register/student_register_confirmation';
import StudentRegisterInquiry from './pages/Register/student_register_inquiry';

export default {
    data() {
        return {
            theme: 'blue',
            componentTheme: 'blue',
            colorScheme: 'dark',
        };
    },
    computed: {
        t_scheme() {
            return this.$store.state.livejapan.scheme;
        },
        login_warning() {
            return this.$store.state.livejapan.login_warning;
        },
    },
    watch: {
        t_scheme: function (newV, oldV) {
            if (newV != oldV) {
                this.onChangeColorScheme(newV);
            }
        },
        login_warning: function (newV) {
            if (newV) {
                this.$toast.add(newV);
            }
        },
    },
    mounted() {
        this.onChangeColorScheme(this.t_scheme);
        // this.$socket.connect(); // Replace with your Socket.io server address
        this.$socket.on('connect_error', async (err) => {
            console.log(`connect_error due to ${err.message}`);
            this.$socket.connect();
        });
    },
    methods: {
        onChangeColorScheme(scheme) {
            this.changeStyleSheetUrl('layout-css', 'layout-' + scheme + '.css', 1);
            this.changeStyleSheetUrl('theme-css', 'theme-' + scheme + '.css', 1);
            this.colorScheme = scheme;
            this.$appState.colorScheme = scheme;
        },
        onChangeMenuTheme(theme) {
            const layoutLink = document.getElementById('layout-css');
            const href = 'layout/css/' + theme + '/layout-' + this.colorScheme + '.css';

            this.replaceLink(layoutLink, href);
            this.theme = theme;
        },
        onChangeComponentTheme(theme) {
            const themeLink = document.getElementById('theme-css');
            const href = 'theme/' + theme + '/theme-' + this.colorScheme + '.css';

            this.replaceLink(themeLink, href);
            this.componentTheme = theme;
        },
        changeStyleSheetUrl(id, value, from) {
            const element = document.getElementById(id);
            const urlTokens = element.getAttribute('href').split('/');

            if (from === 1) {
                // which function invoked this function - change scheme
                urlTokens[urlTokens.length - 1] = value;
            } else if (from === 2) {
                // which function invoked this function - change color
                urlTokens[urlTokens.length - 2] = value;
            }

            const newURL = urlTokens.join('/');

            this.replaceLink(element, newURL);
        },
        replaceLink(linkElement, href, callback) {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);

                if (callback) {
                    callback();
                }
            });
        },
    },
    components: {
        App: App,
        Error: Error,
        Access: Access,
        Login: Login,
        NotFound: NotFound,
        LandingPage: LandingPage,
        TeacherRegister: TeacherRegister,
        StudentRegister: StudentRegister,
        StudentRegisterConfirm: StudentRegisterConfirm,
        StudentRegisterConfirmation: StudentRegisterConfirmation,
        StudentRegisterInquiry: StudentRegisterInquiry,
    },
};
</script>

<style scoped></style>
