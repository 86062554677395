import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store/index.js';

const routes = [
    {
        path: '/dashboard',
        name: 'dashboard',
        exact: true,
        component: () => import('./views/Dashboard/dashboard.vue'),
        meta: {
            breadcrumb: [{ label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' }],
            level: ['superadmin', 'admin', 'student', 'teacher'],
            requiresAuth: true,
        },
        children: [
            {
                path: '/dashboard/student_reminder',
                name: 'studentReminder',
                components: {
                    default: () => import('./views/Dashboard/Reminder/student_reminder.vue'),
                    'reminder-view': () => import('./views/Dashboard/Reminder/student_reminder.vue'), // Menggunakan nama yang sesuai untuk komponen tab Reminder
                },
            },
            {
                path: '/dashboard/teacher_reminder',
                name: 'teacherReminder',
                components: {
                    default: () => import('./views/Dashboard/Reminder/teacher_reminder.vue'),
                    'reminder-view': () => import('./views/Dashboard/Reminder/teacher_reminder.vue'), // Menggunakan nama yang sesuai untuk komponen tab Reminder
                },
            },
            {
                path: '/dashboard/history_reminder',
                name: 'historyReminder',
                components: {
                    default: () => import('./views/Dashboard/Reminder/history_reminder.vue'),
                    'reminder-view': () => import('./views/Dashboard/Reminder/history_reminder.vue'), // Menggunakan nama yang sesuai untuk komponen tab Reminder
                },
            },
            {
                path: '/dashboard/total_lesson',
                name: 'TotalLesson',
                components: {
                    default: () => import('./views/Dashboard/Lesson_Revenue/total_lesson.vue'),
                    'revenue-view': () => import('./views/Dashboard/Lesson_Revenue/total_lesson.vue'), // Menggunakan nama yang sesuai untuk komponen tab Lesson & Revenue
                },
            },
            {
                path: '/dashboard/total_revenue',
                name: 'TotalRevenue',
                components: {
                    default: () => import('./views/Dashboard/Lesson_Revenue/total_revenue.vue'),
                    'revenue-view': () => import('./views/Dashboard/Lesson_Revenue/total_revenue.vue'), // Menggunakan nama yang sesuai untuk komponen tab Lesson & Revenue
                },
            },
        ],
    },
    {
        path: '/all_reminder',
        name: 'all reminder',
        exact: true,
        component: () => import('./views/Dashboard/All_Reminder/tab_menu_all_reminder.vue'),
        meta: {
            breadcrumb: [{ label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' }],
            level: ['superadmin', 'admin'],
            requiresAuth: true,
        },
        children: [
            {
                path: '/dashboard/all_student_reminder',
                name: 'All Student Reminder',
                component: () => import('./views/Dashboard/All_Reminder/all_student_reminder.vue'),
            },
            {
                path: '/dashboard/all_teacher_reminder',
                name: 'All Teacher Reminder',
                component: () => import('./views/Dashboard/All_Reminder/all_teacher_reminder.vue'),
            },
            {
                path: '/dashboard/all_history_reminder',
                name: 'All History Reminder',
                component: () => import('./views/Dashboard/All_Reminder/all_history_reminder.vue'),
            },
        ],
    },
    {
        path: '/',
        name: 'Login',
        exact: true,
        component: () => import('./pages/Login.vue'),
    },
    {
        path: '/404',
        name: '404',
        exact: true,
        component: () => import('./pages/NotFound.vue'),
    },
    {
        path: '/teacher_register',
        name: 'TeacherRegister',
        exact: true,
        component: () => import('./pages/Register/teacher_register.vue'),
    },

    {
        path: '/student_register',
        name: 'StudentRegister',
        exact: true,
        component: () => import('./pages/Register/student_register.vue'),
    },
    {
        path: '/student_register_confirm',
        name: 'StudentRegisterConfirm',
        exact: true,
        component: () => import('./pages/Register/student_register_confirm.vue'),
    },
    {
        path: '/student_register_confirmation',
        name: 'StudentRegisterConfirmation',
        exact: true,
        component: () => import('./pages/Register/student_register_confirmation.vue'),
    },
    {
        path: '/student_register_inquiry',
        name: 'StudentRegisterInquiry',
        exact: true,
        component: () => import('./pages/Register/student_register_inquiry.vue'),
    },
    {
        path: '/schedule',
        name: 'Schedule',
        exact: true,
        component: () => import('./views/Schedule/Schedule.vue'),
        meta: {
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Schedule', to: '/schedule' },
            ],
            requiresAuth: true,
            level: ['superadmin', 'admin', 'student', 'teacher'],
        },
    },
    {
        path: '/evaluation',
        name: 'Evaluation',
        exact: true,
        component: () => import('./views/Evaluation/evaluation.vue'),
        meta: {
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Evaluation', to: '/evaluation' },
            ],
            requiresAuth: true,
            level: ['superadmin', 'admin', 'student', 'teacher'],
        },
    },
    {
        path: '/teachers',
        name: 'Teachers',
        exact: true,
        component: () => import('./views/Teachers/TabMenuTeacher.vue'),
        meta: {
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Teachers', to: '/teachers' },
            ],
            requiresAuth: true,
            level: ['superadmin', 'admin'],
        },
        children: [
            {
                path: '/teacher/application',
                name: 'Application',
                component: () => import('./views/Teachers/Application/application.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                        { label: 'Teachers', to: '/teacher/application' },
                        { label: 'Applications', to: '/teacher/application' },
                    ],
                    requiresAuth: true,
                    level: ['superadmin', 'admin'],
                },
            },
            {
                path: '/teacher/application/detail',
                name: 'ApplicationDetail',
                component: () => import('./views/Teachers/Application/detail_application.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                        { label: 'Teachers', to: '/teacher/application' },
                        { label: 'Applications', to: '/teacher/application' },
                        { label: 'Detail Application', to: '/teacher/application/detail' },
                    ],
                    requiresAuth: true,
                    level: ['superadmin', 'admin'],
                },
            },
            {
                path: '/teacher/profile',
                name: 'TeacherProfile',
                component: () => import('./views/Teachers/Profile/Teacher_Profile/list_teacher.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                        { label: 'Teachers', to: '/teacher/profile' },
                        { label: 'Profile', to: '/teacher/profile' },
                    ],
                    requiresAuth: true,
                    level: ['superadmin', 'admin'],
                },
            },
            {
                path: '/teacher/profile/detail',
                name: 'TeacherDetail',
                component: () => import('./views/Teachers/Profile/tab_menu_profile.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                        { label: 'Teachers', to: '/teacher/profile' },
                        { label: 'Profile', to: '/teacher/profile' },
                        { label: 'Detail', to: '/teacher/profile/detail' },
                    ],
                    requiresAuth: true,
                    level: ['superadmin', 'admin'],
                },
                children: [
                    {
                        path: '/teacher/profile/detail_profile',
                        name: 'TeacherDetailProfile',
                        component: () => import('./views/Teachers/Profile/Detail_Profile/detail_profile.vue'),
                        meta: {
                            breadcrumb: [
                                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                                { label: 'Teachers', to: '/teacher/profile' },
                                { label: 'Profile', to: '/teacher/profile' },
                                { label: 'Detail', to: '/teacher/profile/detail_profile' },
                            ],
                            requiresAuth: true,
                            level: ['superadmin', 'admin'],
                        },
                    },
                    {
                        path: '/teacher/profile/detail_profile/edit_profile',
                        name: 'EditProfileTeacher',
                        component: () => import('./views/Teachers/Profile/Detail_Profile/edit_profile.vue'),
                        meta: {
                            breadcrumb: [
                                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                                { label: 'Teachers', to: '/teacher/profile' },
                                { label: 'Profile', to: '/teacher/profile' },
                                { label: 'Detail', to: '/teacher/profile/detail_profile' },
                                { label: 'Edit Profile', to: '/teacher/profile/detail_profile/edit_profile' },
                            ],
                            requiresAuth: true,
                            level: ['superadmin', 'admin'],
                        },
                    },
                    {
                        path: '/teacher/profile/introduction',
                        name: 'Introduction',
                        component: () => import('./views/Teachers/Profile/Introduction/introduction.vue'),
                        meta: {
                            breadcrumb: [
                                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                                { label: 'Teachers', to: '/teacher/profile' },
                                { label: 'Profile', to: '/teacher/profile' },
                                { label: 'Introduction', to: '/teacher/profile/introduction' },
                            ],
                            requiresAuth: true,
                        },
                    },
                    {
                        path: '/teacher/profile/evaluation',
                        name: 'TeacherEvaluation',
                        component: () => import('./views/Teachers/Profile/Evaluation/evaluation.vue'),
                        meta: {
                            breadcrumb: [
                                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                                { label: 'Teachers', to: '/teacher/profile' },
                                { label: 'Profile', to: '/teacher/profile' },
                                { label: 'Evaluation', to: '/teacher/profile/evaluation' },
                            ],
                            requiresAuth: true,
                            level: ['superadmin', 'admin'],
                        },
                    },
                    {
                        path: '/teacher/profile/notes',
                        name: 'TeacherNotes',
                        component: () => import('./views/Teachers/Profile/Notes/notes.vue'),
                        meta: {
                            breadcrumb: [
                                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                                { label: 'Teachers', to: '/teacher/profile' },
                                { label: 'Profile', to: '/teacher/profile' },
                                { label: 'Notes', to: '/teacher/profile/notes' },
                            ],
                            requiresAuth: true,
                            level: ['superadmin', 'admin'],
                        },
                    },
                    {
                        path: '/teacher/profile/add_teacher',
                        name: 'AddTeacher',
                        component: () => import('./views/Teachers/Profile/Teacher_Profile/add_teacher.vue'),
                        meta: {
                            breadcrumb: [
                                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                                { label: 'Teachers', to: '/teacher/profile' },
                                { label: 'Profile', to: '/teacher/profile' },
                                { label: 'Add', to: '/teacher/profile/add_teacher' },
                            ],
                            requiresAuth: true,
                            level: ['superadmin', 'admin'],
                        },
                    },
                    {
                        path: '/teacher/profile/detail/lesson_report',
                        name: 'TeacherLessonReport',
                        component: () => import('./views/Teachers/Profile/Detail_Profile/lesson_report.vue'),
                        meta: {
                            breadcrumb: [
                                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                                { label: 'Teachers', to: '/teacher/profile' },
                                { label: 'Profile', to: '/teacher/profile' },
                                { label: 'Detail', to: '/teacher/profile/detail_profile' },
                                { label: 'Lesson Report', to: '/teacher/profile/detail/lesson_report' },
                            ],
                            requiresAuth: true,
                            level: ['superadmin', 'admin'],
                        },
                    },
                    {
                        path: '/teacher/profile/detail/student',
                        name: 'TeachersStudent',
                        component: () => import('./views/Teachers/Profile/Detail_Profile/teachers_student.vue'),
                        meta: {
                            breadcrumb: [
                                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                                { label: 'Teachers', to: '/teacher/profile' },
                                { label: 'Profile', to: '/teacher/profile' },
                                { label: 'Detail', to: '/teacher/profile/detail_profile' },
                                { label: 'Student', to: '/teacher/profile/detail/student' },
                            ],
                            requiresAuth: true,
                            level: ['superadmin', 'admin'],
                        },
                    },
                    {
                        path: '/teacher/profile/detail/lesson_report/booking_detail',
                        name: 'TeacherBooking',
                        component: () => import('./views/Teachers/Profile/Detail_Profile/booking.vue'),
                        meta: {
                            breadcrumb: [
                                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                                { label: 'Teachers', to: '/teacher/profile' },
                                { label: 'Profile', to: '/teacher/profile' },
                                { label: 'Detail', to: '/teacher/profile/detail_profile' },
                                { label: 'Lesson Report', to: '/teacher/profile/detail/lesson_report' },
                                { label: 'Booking', to: '/teacher/profile/detail/booking' },
                            ],
                            requiresAuth: true,
                            level: ['superadmin', 'admin'],
                        },
                    },
                    {
                        path: '/teacher/profile/detail/lesson_report/available_detail',
                        name: 'AvailableBooking',
                        component: () => import('./views/Teachers/Profile/Detail_Profile/available.vue'),
                        meta: {
                            breadcrumb: [
                                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                                { label: 'Teachers', to: '/teacher/profile' },
                                { label: 'Profile', to: '/teacher/profile' },
                                { label: 'Detail', to: '/teacher/profile/detail_profile' },
                                { label: 'Lesson Report', to: '/teacher/profile/detail/lesson_report' },
                                { label: 'Booking', to: '/teacher/profile/detail/booking' },
                            ],
                            requiresAuth: true,
                            level: ['superadmin', 'admin'],
                        },
                    },
                    {
                        path: '/teacher/profile/detail/lesson_report/lesson_detail',
                        name: 'TeacherLessonDetail',
                        component: () => import('./views/Lesson_History/lesson_detail.vue'),
                        meta: {
                            breadcrumb: [
                                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                                { label: 'Teacher', to: '/teacher/registration' },
                                { label: 'Profile', to: '/teacher/profile/detail_profile' },
                                { label: 'Lesson Report', to: '/teacher/profile/detail/lesson_report' },
                                { label: 'Lesson Detail', to: '/teacher/profile/detail/lesson_report/lesson_detail' },
                            ],
                            requiresAuth: true,
                            level: ['superadmin', 'admin'],
                        },
                    },
                    {
                        path: '/teacher/profile/detail/detail_lesson',
                        name: 'DetailLessonTeacher',
                        component: () => import('./views/Teachers/Profile/Detail_Profile/detail_lesson.vue'),
                        meta: {
                            breadcrumb: [
                                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                                { label: 'Teachers', to: '/teacher/profile' },
                                { label: 'Profile', to: '/teacher/profile' },
                                { label: 'Detail', to: '/teacher/profile/detail_profile' },
                                { label: 'Lesson', to: '/teacher/profile/detail_profile/detail_lesson' },
                            ],
                            requiresAuth: true,
                            level: ['superadmin', 'admin'],
                        },
                    },
                    {
                        path: '/teacher/profile/application',
                        name: 'ApplicationTeacher',
                        component: () => import('./views/Teachers/Profile/Detail_Profile/Application/teacher_application.vue'),
                        meta: {
                            breadcrumb: [
                                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                                { label: 'Teachers', to: '/teacher/profile' },
                                { label: 'Profile', to: '/teacher/profile' },
                                { label: 'Detail', to: '/teacher/profile/detail_profile' },
                                { label: 'Lesson', to: '/teacher/profile/detail_profile/teacher_application' },
                            ],
                            requiresAuth: true,
                            level: ['superadmin', 'admin'],
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/courses',
        name: 'Courses',
        exact: true,
        component: () => import('./views/Courses/Courses.vue'),
        meta: {
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Courses', to: '/courses' },
            ],
            requiresAuth: true,
            level: ['superadmin', 'admin', 'student', 'teacher'],
        },
    },
    {
        path: '/course_and_fee',
        name: 'CoursesAndFee',
        exact: true,
        component: () => import('./views/Course_And_Fee/course_and_fee.vue'),
        meta: {
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Course and Fee', to: '/course_and_fee' },
            ],
            requiresAuth: true,
            level: ['superadmin', 'admin', 'student', 'teacher'],
        },
        children: [
            {
                path: '/subscription',
                name: 'subcription',
                component: () => import('./views/Course_And_Fee/subscription.vue'),
                breadcrumb: [
                    { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                    { label: 'Subscription', to: '/subscription' },
                ],
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/purchase_plan',
                name: 'purchase_plan',
                component: () => import('./views/Course_And_Fee/purchase_plan.vue'),
                breadcrumb: [
                    { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                    { label: 'Purchase Plan', to: '/purchase_plan' },
                ],
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/package_plan',
                name: 'package_plan',
                component: () => import('./views/Course_And_Fee/package_plan.vue'),
                breadcrumb: [
                    { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                    { label: 'Package Plan', to: '/package_plan' },
                ],
                meta: {
                    requiresAuth: true,
                },
            },
        ],
    },
    {
        path: '/payments',
        name: 'payments',
        exact: true,
        component: () => import('./views/Payment/payment.vue'),
        meta: {
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Payment', to: '/payments' },
            ],
            requiresAuth: true,
            level: ['superadmin', 'admin'],
        },
    },
    {
        path: '/orders',
        name: 'Orders',
        exact: true,
        component: () => import('./views/Orders/Orders.vue'),
        meta: {
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Orders', to: '/orders' },
            ],
            requiresAuth: true,
            level: ['superadmin', 'admin'],
        },
    },
    {
        path: '/orders/detail',
        name: 'Order Detail',
        exact: true,
        component: () => import('./views/Orders/detail_order.vue'),
        meta: {
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Orders', to: '/orders' },
                { label: 'Detail', to: '/orders/detail' },
            ],
            requiresAuth: true,
            level: ['superadmin', 'admin'],
        },
    },
    {
        path: '/orders/detail/record',
        name: 'Order Record',
        exact: true,
        component: () => import('./views/Orders/record_payment.vue'),
        meta: {
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Orders', to: '/orders' },
                { label: 'Detail', to: '/orders/detail' },
                { label: 'Record', to: '/orders/detail/record' },
            ],
            requiresAuth: true,
            level: ['superadmin', 'admin', 'student', 'teacher'],
        },
    },
    {
        path: '/orders/detail/change_history',
        name: 'Order Record',
        exact: true,
        component: () => import('./views/Orders/point_history.vue'),
        meta: {
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Orders', to: '/orders' },
                { label: 'Detail', to: '/orders/detail' },
                { label: 'Change History', to: '/orders/detail/change_history' },
            ],
            requiresAuth: true,
            level: ['superadmin', 'admin'],
        },
    },
    // {
    //     path: '/student_lesson_history',
    //     name: 'StudentLessonHistory',
    //     exact: true,
    //     component: () => import('./views/Student_Site/Student_Lesson_History/lesson_history.vue'),
    //     meta: {
    //         breadcrumb: [{ label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' }],
    //         requiresAuth: true,
    //         level: ['superadmin', 'admin', 'student', 'teacher'],
    //     },
    // },
    {
        path: '/lesson_history/lesson_detail/lesson_evaluation',
        name: 'Lesson Evaluation',
        exact: true,
        component: () => import('./views/Lesson_History/Student_Lesson_History/lesson_evaluation.vue'),
        meta: {
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Lesson History', to: '/lesson_history' },
                { label: 'Lesson Evaluation', to: '/lesson_history/lesson_detail/lesson_evaluation' },
            ],
            requiresAuth: true,
            level: ['superadmin', 'admin', 'student'],
        },
    },
    // {
    //     path: '/teacher_lesson_history',
    //     name: 'TeacherLessonHistory',
    //     exact: true,
    //     component: () => import('./views/Teacher_Site/Teacher_Lesson_History/lesson_history.vue'),
    //     meta: {
    //         breadcrumb: [{ label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' }],
    //         requiresAuth: true,
    //         level: ['superadmin', 'admin', 'student', 'teacher'],
    //     },
    // },
    {
        path: '/students_teacher',
        name: 'StudentsTeacher',
        exact: true,
        component: () => import('./views/Student_Site/Students_Teacher/students_teacher.vue'),
        meta: {
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Teacher', to: '/students_teacher' },
            ],
            requiresAuth: true,
            level: ['superadmin', 'admin', 'student'],
        },
    },
    {
        path: '/students_teacher/detail_students_teacher',
        name: 'DetailStudentsTeacher',
        exact: true,
        component: () => import('./views/Student_Site/Students_Teacher/detail_students_teacher.vue'),
        meta: {
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Teacher', to: '/students_teacher' },
                { label: 'Detail Teacher', to: '/detail_students_teacher' },
            ],
            requiresAuth: true,
            level: ['superadmin', 'admin', 'student', 'teacher'],
        },
    },
    {
        path: '/teachers_profile',
        name: 'TeachersProfile',
        exact: true,
        component: () => import('./views/Student_Site/Students_Teacher/detail_students_teacher.vue'),
        meta: {
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Teacher Profile', to: '/teachers_profile' },
            ],
            requiresAuth: true,
            level: ['superadmin', 'admin', 'student', 'teacher'],
        },
    },
    {
        path: '/teachers_student',
        name: 'Teacher_Student',
        exact: true,
        component: () => import('./views/Teacher_Site/Teachers_Student/teachers_student.vue'),
        meta: {
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Students', to: '/teachers_student', icon: '' },
            ],
            requiresAuth: true,
            level: ['superadmin', 'admin', 'teacher'],
        },
    },

    // all news
    {
        path: '/teacher_news',
        name: 'TeacherNews',
        exact: true,
        component: () => import('./views/Tools/Teacher_News/teacher_news.vue'),
        meta: {
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Teacher News', to: '/teacher_news' },
            ],
            requiresAuth: true,
            level: ['superadmin', 'admin', 'teacher'],
        },
    },
    {
        path: '/add_teacher_news',
        name: 'Add News Teacher',
        exact: true,
        component: () => import('./views/Tools/Teacher_News/add_teacher_news.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Teacher News', to: '/teacher_news' },
                { label: 'Add', to: '/add_teacher_news' },
            ],
            level: ['superadmin', 'admin'],
        },
    },
    {
        path: '/update_teacher_news',
        name: 'Update Teacher News',
        exact: true,
        component: () => import('./views/Tools/Teacher_News/edit_teacher_news.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Teacher News', to: '/teacher_news' },
                { label: 'Update', to: '/update_teacher_news' },
            ],
            level: ['superadmin', 'admin', 'student', 'teacher'],
        },
    },

    {
        path: '/student_news',
        name: 'StudentNews',
        exact: true,
        component: () => import('./views/Tools/Student_News/student_news.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Student News', to: '/students_news' },
            ],
            level: ['superadmin', 'admin', 'student', 'teacher'],
        },
    },
    {
        path: '/add_student_news',
        name: 'Add News Student',
        exact: true,
        component: () => import('./views/Tools/Student_News/add_student_news.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Student News', to: '/student_news' },
                { label: 'Add', to: '/add_Student_news' },
            ],
            level: ['superadmin', 'admin', 'student', 'teacher'],
        },
    },
    {
        path: '/update_student_news',
        name: 'Update Student News',
        exact: true,
        component: () => import('./views/Tools/Student_News/edit_student_news.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Student News', to: '/student_news' },
                { label: 'Update', to: '/update_student_news' },
            ],
            level: ['superadmin', 'admin', 'student', 'teacher'],
        },
    },
    {
        path: '/all_student_news',
        name: 'All Student News',
        exact: true,
        component: () => import('./views/Dashboard/News/all_news_student.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Live English News', to: '/all_student_news' },
            ],
            level: ['superadmin', 'admin', 'student', 'teacher'],
        },
    },
    {
        path: '/all_incoming_lesson',
        name: 'All Incoming Lesson',
        exact: true,
        component: () => import('./views/Dashboard/all_incoming.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'All Incoming Lesson', to: '/all_incoming_lesson' },
            ],
            level: ['superadmin', 'admin', 'student', 'teacher'],
        },
    },
    {
        path: '/all_incoming_lesson_teacher',
        name: 'All Incoming Lesson Teacher',
        exact: true,
        component: () => import('./views/Dashboard/all_incoming_teacher.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'All Incoming Lessons', to: '/all_incoming_lesson_teacher' },
            ],
            level: ['superadmin', 'admin', 'student', 'teacher'],
        },
    },
    {
        path: '/all_teacher_news',
        name: 'All Teacher News',
        exact: true,
        component: () => import('./views/Dashboard/News/all_news_teacher.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Live English News', to: '/all_teacher_news' },
            ],
            level: ['superadmin', 'admin', 'student', 'teacher'],
        },
    },
    {
        path: '/detail_student_news',
        name: 'Detail Student News',
        exact: true,
        component: () => import('./views/Dashboard/News/detail_student_news.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Live English News', to: '/all_student_news' },
                { label: 'Detail News', to: '/detail_news' },
            ],
            level: ['superadmin', 'admin', 'student'],
        },
    },
    {
        path: '/detail_teacher_news',
        name: 'Detail Teacher News',
        exact: true,
        component: () => import('./views/Dashboard/News/detail_teacher_news.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Live English News', to: '/all_teacher_news' },
                { label: 'Detail News', to: '/detail_news' },
            ],
            level: ['superadmin', 'admin', 'teacher'],
        },
    },
    {
        path: '/all_newsbank',
        name: 'All Newsbank',
        exact: true,
        component: () => import('./views/NewsBank/newsbank.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Newsbank', to: '/all_newsbank' },
            ],
            level: ['superadmin', 'admin', 'student', 'teacher'],
        },
    },
    {
        path: '/newsbank',
        name: 'newsbank',
        exact: true,
        component: () => import('./views/Tools/Newsbank/newsbank.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Newsbank', to: '/newsbank' },
            ],
            level: ['superadmin', 'admin', 'student', 'teacher'],
        },
    },
    {
        path: '/add_newsbank',
        name: 'Add Newsbank',
        exact: true,
        component: () => import('./views/Tools/Newsbank/add_newsbank.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Newsbank', to: '/newsbank' },
                { label: 'Add', to: '/add_newsbank' },
            ],
            level: ['superadmin', 'admin'],
        },
    },
    {
        path: '/update_newsbank',
        name: 'AddNewsbank',
        exact: true,
        component: () => import('./views/Tools/Newsbank/edit_newsbank.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Newsbank', to: '/newsbank' },
                { label: 'Update', to: '/update_newsbank' },
            ],
            level: ['superadmin', 'admin'],
        },
    },
    {
        path: '/newsbank/detail_newsbank',
        name: 'DetailNewsbank',
        exact: true,
        component: () => import('./views/Tools/Newsbank/detail_newsbank.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [{ parent: 'Tools', label: 'Newsbank' }],
            level: ['superadmin', 'admin', 'student', 'teacher'],
        },
    },
    {
        path: '/landing_page',
        name: 'Landing Page',
        component: () => import('./pages/LandingPage.vue'),
    },
    // master
    {
        path: '/master',
        name: 'Master',
        meta: {
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Student', to: '/students' },
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/master/course',
        name: 'Course',
        component: () => import('./views/Master/course/course.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Master', to: '/master/course' },
                { label: 'Course', to: '/master/course' },
            ],
            level: ['superadmin', 'admin'],
        },
    },
    {
        path: '/master/add_course',
        name: 'Add Course',
        component: () => import('./views/Master/course/add_course.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Master', to: '/master/course' },
                { label: 'Course', to: '/master/course' },
                { label: 'Add', to: '/master/add_course' },
            ],

            level: ['superadmin', 'admin'],
        },
    },
    {
        path: '/master/update_course',
        name: 'Update Course',
        component: () => import('./views/Master/course/update_course.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Master', to: '/master/course' },
                { label: 'Course', to: '/master/course' },
                { label: 'Update', to: '/master/update_course' },
            ],
            level: ['superadmin', 'admin'],
        },
    },
    {
        path: '/master/course_type',
        name: 'Course Type',
        component: () => import('./views/Master/course_type/course_type.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Master', to: '/master/course_type' },
                { label: 'Course Type', to: '/master/course_type' },
            ],
            level: ['superadmin', 'admin'],
        },
    },
    {
        path: '/master/level_teaching',
        name: 'Level Teaching',
        component: () => import('./views/Master/level_teaching/level_teaching.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Master', to: '/master/level_teaching' },
                { label: 'Level Teaching', to: '/master/level_teaching' },
            ],
            level: ['superadmin', 'admin'],
        },
    },
    {
        path: '/master/lesson_atmosphere',
        name: 'Lesson Atmosphere',
        component: () => import('./views/Master/lesson_atmosphere/lesson_atmosphere.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Master', to: '/master/lesson_atmosphere' },
                { label: 'Lesson Atmosphere', to: '/master/lesson_atmosphere' },
            ],
            level: ['superadmin', 'admin'],
        },
    },
    {
        path: '/master/nation',
        name: 'Nation',
        component: () => import('./views/Master/nation/nation.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Master', to: '/master/nation' },
                { label: 'Nation', to: '/master/nation' },
            ],
            level: ['superadmin', 'admin'],
        },
    },
    {
        path: '/master/payment_plan',
        name: 'Payment Plan',
        component: () => import('./views/Master/payment_plan/payment_plan.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Master', to: '/master/payment_plan' },
                { label: 'Payment Plan', to: '/master/payment_plan' },
            ],
            level: ['superadmin', 'admin'],
        },
    },
    {
        path: '/master/personality',
        name: 'Personality',
        component: () => import('./views/Master/personality/personality.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Master', to: '/master/personality' },
                { label: 'Personality', to: '/master/personality' },
            ],
            level: ['superadmin', 'admin'],
        },
    },
    {
        path: '/master/role',
        name: 'Role',
        component: () => import('./views/Master/role/role.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Master', to: '/master/role' },
                { label: 'Role', to: '/master/role' },
            ],
            level: ['superadmin', 'admin'],
        },
    },
    {
        path: '/master/methodology',
        name: 'Methodology',
        component: () => import('./views/Master/methodology/methodology.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Master', to: '/master/methodology' },
                { label: 'Methodology', to: '/master/methodology' },
            ],
            level: ['superadmin', 'admin'],
        },
    },
    {
        path: '/master/skill',
        name: 'Skill',
        component: () => import('./views/Master/skill/skill.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Master', to: '/master/skill' },
                { label: 'Skill', to: '/master/skill' },
            ],
            level: ['superadmin', 'admin'],
        },
    },
    {
        path: '/master/skillset',
        name: 'Skillset',
        component: () => import('./views/Master/skillset/skillset.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Master', to: '/master/skillset' },
                { label: 'Skill', to: '/master/skillset' },
            ],
            level: ['superadmin', 'admin'],
        },
    },
    {
        path: '/master/teacher_industry',
        name: 'Teacher Industry',
        component: () => import('./views/Master/teacher_industry/teacher_industry.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Master', to: '/master/teacher_industry' },
                { label: 'Teacher Industry', to: '/master/teacher_industry' },
            ],
            level: ['superadmin', 'admin'],
        },
    },
    {
        path: '/master/teaching_style',
        name: 'Teaching Style',
        component: () => import('./views/Master/teaching_style/teaching_style.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Master', to: '/master/teaching_style' },
                { label: 'Teaching Style', to: '/master/teaching_style' },
            ],
            level: ['superadmin', 'admin'],
        },
    },
    // student admins
    {
        path: '/students',
        name: 'Students',
        component: () => import('./views/Students/TabMenuStudent.vue'),
        meta: {
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Student', to: '/students' },
            ],
            requiresAuth: true,
            level: ['superadmin', 'admin'],
        },
        children: [
            {
                path: '/students/registration',
                name: 'Registration',
                component: () => import('./views/Students/Registration/Registration.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                        { label: 'Student', to: '/students/registration' },
                        { label: 'Registration', to: '/students/registration' },
                    ],
                    requiresAuth: true,
                    level: ['superadmin', 'admin'],
                },
            },
            {
                path: '/students/registration/detail',
                name: 'DetailRegistrationTrial',
                component: () => import('./views/Students/Registration/detail_registration.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                        { label: 'Student', to: '/students/registration' },
                        { label: 'Registration', to: '/students/registration' },
                        { label: 'Detail Trial', to: '/students/registration/detail' },
                    ],
                    requiresAuth: true,
                    level: ['superadmin', 'admin'],
                },
            },
            {
                path: '/students/registration/detail/inquiry',
                name: 'DetailRegistrationInquiry',
                component: () => import('./views/Students/Registration/detail_registration_inquiry.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                        { label: 'Student', to: '/students/registration' },
                        { label: 'Registration', to: '/students/registration' },
                        { label: 'Detail Inquiry', to: '/students/registration/detail/inquiry' },
                    ],
                    requiresAuth: true,
                    level: ['superadmin', 'admin'],
                },
            },
            {
                path: '/students/profile',
                name: 'Profile',
                component: () => import('./views/Students/Profile/Student_Profile/list_student.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                        { label: 'Student', to: '/students/registration' },
                        { label: 'Profile', to: '/students/profile' },
                    ],
                    requiresAuth: true,
                    level: ['superadmin', 'admin'],
                },
            },
            {
                path: '/students/profile/add_student',
                name: 'AddStudent',
                component: () => import('./views/Students/Profile/Student_Profile/add_student.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                        { label: 'Student', to: '/students/registration' },
                        { label: 'Profile', to: '/students/profile' },
                        { label: 'Add', to: '/students/profile/add_student' },
                    ],
                    requiresAuth: true,
                    level: ['superadmin', 'admin'],
                },
            },
            // student profile detail
            {
                path: '/students/profile/detail',
                name: 'Student Detail',
                component: () => import('./views/Students/Profile/tab_menu_profile.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                        { label: 'Student', to: '/students/registration' },
                        { label: 'Profile', to: '/students/profile' },
                        { label: 'Detail', to: '/students/profile/detail' },
                    ],
                    requiresAuth: true,
                    level: ['superadmin', 'admin'],
                },
                children: [
                    {
                        path: '/students/profile/detail_profile',
                        name: 'Student Detail Profile',
                        component: () => import('./views/Students/Profile/Detail_Profile/detail_profile.vue'),
                        meta: {
                            breadcrumb: [
                                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                                { label: 'Student', to: '/students/registration' },
                                { label: 'Profile', to: '/students/profile' },
                                { label: 'Detail', to: '/students/profile/detail_profile' },
                            ],
                            requiresAuth: true,
                            level: ['superadmin', 'admin'],
                        },
                    },
                    {
                        path: '/students/profile/detail/edit_profile',
                        name: 'Edit Profile',
                        component: () => import('./views/Students/Profile/Detail_Profile/edit_profile.vue'),
                        meta: {
                            breadcrumb: [
                                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                                { label: 'Student', to: '/students/registration' },
                                { label: 'Profile', to: '/students/profile/detail_profile' },
                                { label: 'Edit', to: '/students/profile/detail/edit_profile' },
                            ],
                            requiresAuth: true,
                            level: ['superadmin', 'admin'],
                        },
                    },
                    {
                        path: '/students/profile/detail/lesson_report',
                        name: 'LessonReport',
                        component: () => import('./views/Students/Profile/Detail_Profile/lesson_report.vue'),
                        meta: {
                            breadcrumb: [
                                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                                { label: 'Student', to: '/students/registration' },
                                { label: 'Profile', to: '/students/profile/detail_profile' },
                                { label: 'Lesson report', to: '/students/profile/detail/lesson_report' },
                            ],
                            requiresAuth: true,
                            level: ['superadmin', 'admin'],
                        },
                    },
                    {
                        path: '/students/profile/detail/lesson_report/lesson_detail',
                        name: 'LessonReportDetail',
                        component: () => import('./views/Lesson_History/lesson_detail.vue'),
                        meta: {
                            breadcrumb: [
                                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                                { label: 'Student', to: '/students/registration' },
                                { label: 'Profile', to: '/students/profile/detail_profile' },
                                { label: 'Lesson Report', to: '/students/profile/detail/lesson_report' },
                                { label: 'Lesson Detail', to: '/students/profile/detail/lesson_report/lesson_detail' },
                            ],
                            requiresAuth: true,
                            level: ['superadmin', 'admin'],
                        },
                    },
                    {
                        path: '/students/profile/detail/lesson_report/booking_detail',
                        name: 'DetailBooking',
                        component: () => import('./views/Students/Profile/Detail_Profile/detail_booking.vue'),
                        meta: {
                            breadcrumb: [
                                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                                { label: 'Student', to: '/students/registration' },
                                { label: 'Profile', to: '/students/profile/detail_profile' },
                                { label: 'Lesson', to: '/students/profile/detail/lesson_report' },
                                { label: 'Booking', to: '/students/profile/detail/lesson_report/booking_detail' },
                            ],
                            requiresAuth: true,
                            level: ['superadmin', 'admin'],
                        },
                    },
                    {
                        path: '/students/profile/orders',
                        name: 'Order',
                        component: () => import('./views/Students/Profile/Order/orders.vue'),
                        meta: {
                            breadcrumb: [
                                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                                { label: 'Student', to: '/students/registration' },
                                { label: 'Profile', to: '/students/profile' },
                                { label: 'Order', to: '/students/profile/orders' },
                            ],
                            requiresAuth: true,
                            level: ['superadmin', 'admin'],
                        },
                    },
                    {
                        path: '/students/profile/orders/new_order',
                        name: 'NewOrder',
                        component: () => import('./views/Students/Profile/Order/new_order.vue'),
                        meta: {
                            breadcrumb: [
                                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                                { label: 'Student', to: '/students/registration' },
                                { label: 'Profile', to: '/students/profile' },
                                { label: 'Order', to: '/students/profile/orders' },
                                { label: 'New Order', to: '/students/profile/orders/new_order' },
                            ],
                            requiresAuth: true,
                            level: ['superadmin', 'admin'],
                        },
                    },
                    {
                        path: '/students/profile/orders/detail_order',
                        name: 'DetailOrder',
                        component: () => import('./views/Students/Profile/Order/detail_order.vue'),
                        meta: {
                            breadcrumb: [
                                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                                { label: 'Student', to: '/students/registration' },
                                { label: 'Profile', to: '/students/profile/detail_profile' },
                                { label: 'Order', to: '/students/profile/orders' },
                                { label: 'Detail', to: '/students/profile/orders/detail_order' },
                            ],
                            requiresAuth: true,
                            level: ['superadmin', 'admin'],
                        },
                    },
                    {
                        path: '/students/profile/orders/list_order',
                        name: 'ListOrder',
                        component: () => import('./views/Students/Profile/Order/list_order.vue'),
                        meta: {
                            breadcrumb: [
                                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                                { label: 'Student', to: '/students/registration' },
                                { label: 'Profile', to: '/students/profile/detail_profile' },
                                { label: 'Order', to: '/students/profile/orders' },
                                { label: 'Course List', to: '/students/profile/orders/list_order' },
                            ],
                            requiresAuth: true,
                            level: ['superadmin', 'admin'],
                        },
                    },
                    {
                        path: '/students/profile/orders/record_payment',
                        name: 'RecordPayment',
                        component: () => import('./views/Students/Profile/Order/record_payment.vue'),
                        meta: {
                            breadcrumb: [
                                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                                { label: 'Student', to: '/students/registration' },
                                { label: 'Profile', to: '/students/profile/detail_profile' },
                                { label: 'Order', to: '/students/profile/orders' },
                                { label: 'Payment', to: '/students/profile/orders/record_payment' },
                            ],
                            requiresAuth: true,
                            level: ['superadmin', 'admin'],
                        },
                    },
                    {
                        path: '/students/profile/orders/alter_points_history',
                        name: 'RecordAlterStudent',
                        component: () => import('./views/Students/Profile/Order/used_point.vue'),
                        meta: {
                            breadcrumb: [
                                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                                { label: 'Student', to: '/students/registration' },
                                { label: 'Profile', to: '/students/profile/detail_profile' },
                                { label: 'Order', to: '/students/profile/orders' },
                                { label: 'Payment', to: '/students/profile/orders/alter_points_history' },
                            ],
                            requiresAuth: true,
                            level: ['superadmin', 'admin'],
                        },
                    },
                    {
                        path: '/students/profile/notes',
                        name: 'Notes',
                        component: () => import('./views/Students/Profile/Notes/notes.vue'),
                        meta: {
                            breadcrumb: [
                                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                                { label: 'Student', to: '/students/registration' },
                                { label: 'Notes', to: '/students/profile/notes' },
                            ],
                            requiresAuth: true,
                            level: ['superadmin', 'admin'],
                        },
                    },
                    {
                        path: '/students/profile/evaluations',
                        name: 'Evaluations',
                        component: () => import('./views/Students/Profile/Evaluation/evaluation.vue'),
                        meta: {
                            breadcrumb: [
                                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                                { label: 'Student', to: '/students/registration' },
                                { label: 'Profile', to: '/students/profile' },
                                { label: 'Evaluations', to: '/students/profile/evaluations' },
                            ],
                            requiresAuth: true,
                            level: ['superadmin', 'admin'],
                        },
                    },
                    {
                        path: '/students/profile/registration_form',
                        name: 'RegistrationForm',
                        component: () => import('./views/Students/Profile/Registration_Form/registration_form.vue'),
                        meta: {
                            breadcrumb: [
                                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                                { label: 'Student', to: '/students/registration' },
                                { label: 'Profile', to: '/students/profile/detail_profile' },
                                { label: 'Registration Form', to: '/students/profile/registration_form' },
                            ],
                            requiresAuth: true,
                            level: ['superadmin', 'admin'],
                        },
                    },
                ],
            },
            {
                path: '/students/sleeping',
                name: 'Sleeping',
                component: () => import('./views/Students/Sleeping/Sleeping.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                        { label: 'Student', to: '/students/registration' },
                        { label: 'Sleeping', to: '/students/sleeping' },
                    ],
                    requiresAuth: true,
                    level: ['superadmin', 'admin'],
                },
            },
            {
                path: '/students/sleeping/chat',
                name: 'Chat',
                component: () => import('./views/Students/Sleeping/chattrial.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                        { label: 'Student', to: '/students/registration' },
                        { label: 'Sleeping', to: '/students/sleeping' },
                        { label: 'Chat', to: '/students/sleeping/chat' },
                    ],
                    requiresAuth: true,
                    level: ['superadmin', 'admin'],
                },
            },
            {
                path: '/students/failed_registration',
                name: 'FailedRegistration',
                component: () => import('./views/Students/Failed_Registration/failed_registration.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                        { label: 'Student', to: '/students/registration' },
                        { label: 'Failed', to: '/students/failed_registration' },
                    ],
                    requiresAuth: true,
                    level: ['superadmin', 'admin'],
                },
            },
            {
                path: '/students/failed_registration/detail',
                name: 'DetailFailed',
                component: () => import('./views/Students/Failed_Registration/detail_failed.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                        { label: 'Failed Student', to: '/students/failed_registration' },
                        { label: 'Detail', to: '/students/failed_registration/detail' },
                    ],
                    requiresAuth: true,
                    level: ['superadmin', 'admin'],
                },
            },
            {
                path: '/students/failed_registration/detail',
                name: 'DetailFailed',
                component: () => import('./views/Students/Failed_Registration/detail_failed.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                        { label: 'Student', to: '/students/registration' },
                        { label: 'Failed', to: '/students/failed_registration' },
                        { label: 'Detail', to: '/students/failed_registration/detail' },
                    ],
                    requiresAuth: true,
                    level: ['superadmin', 'admin'],
                },
            },
        ],
    },
    {
        path: '/lesson_history',
        name: 'Lesson History',
        exact: true,
        component: () => import('./views/Lesson_History/lesson_history.vue'),
        meta: {
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Lesson History', to: '/lesson_history' },
            ],
            requiresAuth: true,
            level: ['superadmin', 'admin', 'student', 'teacher'],
        },
    },
    {
        path: '/lesson_detail',
        name: 'Lesson Detail',
        exact: true,
        component: () => import('./views/Lesson_History/lesson_detail.vue'),
        meta: {
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Lesson History', to: '/lesson_history' },
                { label: 'Lesson Detail', to: '/lesson_detail' },
            ],
            requiresAuth: true,
            level: ['superadmin', 'admin', 'student', 'teacher'],
        },
    },
    {
        path: '/students_profile',
        name: 'Students Profile',
        exact: true,
        component: () => import('./views/Teacher_Site/Teachers_Student/student_profile.vue'),
        meta: {
            // breadcrumb: [
            //     { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
            //     { label: 'Lesson History', to: '/lesson_history' },
            //     { label: 'Lesson Detail', to: '/lesson_detail' },
            // ],
            requiresAuth: true,
            level: ['superadmin', 'admin', 'teacher'],
        },
    },

    // user profile student
    {
        path: '/user_profile',
        name: 'User Profiles',
        component: () => import('./views/User/user_profile'),
        meta: {
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Profile', to: '/user_profile' },
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/user_profile/teacher/profile/detail',
        name: 'User Teacher Detail',
        component: () => import('./views/User/Teacher/Profile/tab_menu_profile.vue'),
        meta: {
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Profile', to: '/user_profile/teacher/profile/detail' },
            ],
            requiresAuth: true,
        },
        children: [
            {
                path: '/user_profile/teacher/profile/detail_profile',
                name: 'User Teacher Detail Profile',
                component: () => import('./views/User/Teacher/Profile/Detail_Profile/detail_profile.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                        { label: 'Profile', to: '/user_profile/teacher/profile/detail_profile' },
                    ],
                    requiresAuth: true,
                },
            },
            {
                path: '/user_profile/teacher/profile/introduction',
                name: 'User Teacher Introduction',
                component: () => import('./views/User/Teacher/Profile/Introduction/introduction.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                        { label: 'Teachers', to: '/teacher/profile' },
                        { label: 'Introduction', to: '/teacher/profile/introduction' },
                    ],
                    requiresAuth: true,
                },
            },
            // {
            //     path: '/teacher/profile/evaluation',
            //     name: 'TeacherEvaluation',
            //     component: () => import('./views/Teachers/Profile/Evaluation/evaluation.vue'),
            //     meta: {
            //         breadcrumb: [
            //             { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
            //             { label: 'Teachers', to: '/teacher/profile' },
            //             { label: 'Profile', to: '/teacher/profile' },
            //             { label: 'Evaluation', to: '/teacher/profile/evaluation' },
            //         ],
            //         requiresAuth: true,
            //     },
            // },
            // {
            //     path: '/teacher/profile/notes',
            //     name: 'TeacherNotes',
            //     component: () => import('./views/Teachers/Profile/Notes/notes.vue'),
            //     meta: {
            //         breadcrumb: [
            //             { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
            //             { label: 'Teachers', to: '/teacher/profile' },
            //             { label: 'Profile', to: '/teacher/profile' },
            //             { label: 'Notes', to: '/teacher/profile/notes' },
            //         ],
            //         requiresAuth: true,
            //     },
            // },
            // {
            //     path: '/teacher/profile/detail/lesson_report',
            //     name: 'TeacherLessonReport',
            //     component: () => import('./views/Teachers/Profile/Detail_Profile/lesson_report.vue'),
            //     meta: {
            //         breadcrumb: [
            //             { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
            //             { label: 'Teachers', to: '/teacher/profile' },
            //             { label: 'Profile', to: '/teacher/profile' },
            //             { label: 'Detail', to: '/teacher/profile/detail_profile' },
            //             { label: 'Lesson Report', to: '/teacher/profile/detail/lesson_report' },
            //         ],
            //         requiresAuth: true,
            //     },
            // },
            {
                path: '/user_profile/teacher/profile/detail/student',
                name: 'User Teacher Student',
                component: () => import('./views/User/Teacher/Profile/Detail_Profile/teachers_student.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                        { label: 'Profile', to: '/user_profile/teacher/profile' },
                        { label: 'Student', to: '/user_profile/teacher/profile/detail/student' },
                    ],
                    requiresAuth: true,
                },
            },
            // {
            //     path: '/teacher/profile/detail/lesson_report/booking_detail',
            //     name: 'TeacherBooking',
            //     component: () => import('./views/Teachers/Profile/Detail_Profile/booking.vue'),
            //     meta: {
            //         breadcrumb: [
            //             { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
            //             { label: 'Teachers', to: '/teacher/profile' },
            //             { label: 'Profile', to: '/teacher/profile' },
            //             { label: 'Detail', to: '/teacher/profile/detail_profile' },
            //             { label: 'Lesson Report', to: '/teacher/profile/detail/lesson_report' },
            //             { label: 'Booking', to: '/teacher/profile/detail/booking' },
            //         ],
            //         requiresAuth: true,
            //     },
            // },
            // {
            //     path: '/teacher/profile/detail/lesson_report/lesson_detail',
            //     name: 'TeacherLessonDetail',
            //     component: () => import('./views/Lesson_History/lesson_detail.vue'),
            //     meta: {
            //         breadcrumb: [
            //             { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
            //             { label: 'Student', to: '/teacher/registration' },
            //             { label: 'Profile', to: '/teacher/profile/detail_profile' },
            //             { label: 'Lesson Report', to: '/teacher/profile/detail/lesson_report' },
            //             { label: 'Lesson Detail', to: '/teacher/profile/detail/lesson_report/lesson_detail' },
            //         ],
            //         requiresAuth: true,
            //     },
            // },
            // {
            //     path: '/teacher/profile/detail/detail_lesson',
            //     name: 'DetailLessonTeacher',
            //     component: () => import('./views/Teachers/Profile/Detail_Profile/detail_lesson.vue'),
            //     meta: {
            //         breadcrumb: [
            //             { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
            //             { label: 'Teachers', to: '/teacher/profile' },
            //             { label: 'Profile', to: '/teacher/profile' },
            //             { label: 'Detail', to: '/teacher/profile/detail_profile' },
            //             { label: 'Lesson', to: '/teacher/profile/detail_profile/detail_lesson' },
            //         ],
            //         requiresAuth: true,
            //     },
            // },
        ],
    },
    {
        path: '/user_profile/students/profile/detail',
        name: 'User Student Detail',
        component: () => import('./views/User/Students/Profile/tab_menu_profile.vue'),
        meta: {
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Profile', to: '/user_profile/students/profile/detail' },
            ],
            requiresAuth: true,
        },
        children: [
            {
                path: '/user_profile/students/profile/detail_profile',
                name: 'User Student Detail Profile',
                component: () => import('./views/User/Students/Profile/Detail_Profile/detail_profile.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                        { label: 'Profile', to: '/user_profile/students/profile/detail_profile' },
                    ],
                    requiresAuth: true,
                },
            },
            // {
            //     path: '/user_profile/students/profile/detail/edit_profile',
            //     name: 'User Student Edit Profile',
            //     component: () => import('./views/User/Students/Profile/Detail_Profile/edit_profile.vue'),
            //     meta: {
            //         breadcrumb: [
            //             { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
            //             { label: 'Student', to: '/user_profile/students/registration' },
            //             { label: 'Profile', to: '/user_profile/students/profile/detail_profile' },
            //             { label: 'Edit', to: '/user_profile/students/profile/detail/edit_profile' },
            //         ],
            //         requiresAuth: true,
            //     },
            // },
            // {
            //     path: '/user_profile/students/profile/detail/lesson',
            //     name: 'User Student Lesson',
            //     component: () => import('./views/User/Students/Profile/Detail_Profile/lesson_report.vue'),
            //     meta: {
            //         breadcrumb: [
            //             { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
            //             { label: 'Student', to: '/user_profile/students/registration' },
            //             { label: 'Profile', to: '/user_profile/students/profile/detail_profile' },
            //             { label: 'Lesson', to: '/user_profile/students/profile/detail/lesson' },
            //         ],
            //         requiresAuth: true,
            //     },
            // },
            // {
            //     path: '/user_profile/students/profile/detail/lesson/lesson_detail',
            //     name: 'User Student DetailLesson',
            //     component: () => import('./views/User/Students/Profile/Detail_Profile/detail_lesson.vue'),
            //     meta: {
            //         breadcrumb: [
            //             { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
            //             { label: 'Student', to: '/user_profile/students/registration' },
            //             { label: 'Profile', to: '/user_profile/students/profile/detail_profile' },
            //             { label: 'Lesson', to: '/user_profile/students/profile/detail/lesson' },
            //             { label: 'Lesson Detail', to: '/user_profile/students/profile/detail/lesson/lesson_detail' },
            //         ],
            //         requiresAuth: true,
            //     },
            // },
            // {
            //     path: '/user_profile/students/profile/detail/lesson/booking_detail',
            //     name: 'User Student DetailBooking',
            //     component: () => import('./views/User/Students/Profile/Detail_Profile/detail_booking.vue'),
            //     meta: {
            //         breadcrumb: [
            //             { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
            //             { label: 'Student', to: '/user_profile/students/registration' },
            //             { label: 'Profile', to: '/user_profile/students/profile/detail_profile' },
            //             { label: 'Lesson', to: '/user_profile/students/profile/detail/lesson' },
            //             { label: 'Booking', to: '/user_profile/students/profile/detail/lesson/booking_detail' },
            //         ],
            //         requiresAuth: true,
            //     },
            // },
            // {
            //     path: '/user_profile/students/profile/orders',
            //     name: 'User Student Order',
            //     component: () => import('./views/User/Students/Profile/Order/orders.vue'),
            //     meta: {
            //         breadcrumb: [
            //             { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
            //             { label: 'Student', to: '/user_profile/students/registration' },
            //             { label: 'Profile', to: '/user_profile/students/profile' },
            //             { label: 'Order', to: '/user_profile/students/profile/orders' },
            //         ],
            //         requiresAuth: true,
            //     },
            // },
            // {
            //     path: '/user_profile/students/profile/orders/new_order',
            //     name: 'User Student NewOrder',
            //     component: () => import('./views/User/Students/Profile/Order/new_order.vue'),
            //     meta: {
            //         breadcrumb: [
            //             { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
            //             { label: 'Student', to: '/user_profile/students/registration' },
            //             { label: 'Profile', to: '/user_profile/students/profile' },
            //             { label: 'Order', to: '/user_profile/students/profile/orders' },
            //             { label: 'New Order', to: '/user_profile/students/profile/orders/new_order' },
            //         ],
            //         requiresAuth: true,
            //     },
            // },
            // {
            //     path: '/user_profile/students/profile/orders/detail_order',
            //     name: 'User Student DetailOrder',
            //     component: () => import('./views/User/Students/Profile/Order/detail_order.vue'),
            //     meta: {
            //         breadcrumb: [
            //             { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
            //             { label: 'Student', to: '/user_profile/students/registration' },
            //             { label: 'Profile', to: '/user_profile/students/profile/detail_profile' },
            //             { label: 'Order', to: '/user_profile/students/profile/orders' },
            //             { label: 'Detail', to: '/user_profile/students/profile/orders/detail_order' },
            //         ],
            //         requiresAuth: true,
            //     },
            // },
            // {
            //     path: '/user_profile/students/profile/orders/list_order',
            //     name: 'User Student ListOrder',
            //     component: () => import('./views/User/Students/Profile/Order/list_order.vue'),
            //     meta: {
            //         breadcrumb: [
            //             { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
            //             { label: 'Student', to: '/user_profile/students/registration' },
            //             { label: 'Profile', to: '/user_profile/students/profile/detail_profile' },
            //             { label: 'Order', to: '/user_profile/students/profile/orders' },
            //             { label: 'Course List', to: '/user_profile/students/profile/orders/list_order' },
            //         ],
            //         requiresAuth: true,
            //     },
            // },
            // {
            //     path: '/user_profile/students/profile/orders/record_payment',
            //     name: 'User Student RecordPayment',
            //     component: () => import('./views/User/Students/Profile/Order/record_payment.vue'),
            //     meta: {
            //         breadcrumb: [
            //             { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
            //             { label: 'Student', to: '/user_profile/students/registration' },
            //             { label: 'Profile', to: '/user_profile/students/profile/detail_profile' },
            //             { label: 'Order', to: '/user_profile/students/profile/orders' },
            //             { label: 'Payment', to: '/user_profile/students/profile/orders/record_payment' },
            //         ],
            //         requiresAuth: true,
            //     },
            // },
            // {
            //     path: '/user_profile/students/profile/notes',
            //     name: 'User Student Notes',
            //     component: () => import('./views/User/Students/Profile/Notes/notes.vue'),
            //     meta: {
            //         breadcrumb: [
            //             { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
            //             { label: 'Student', to: '/user_profile/students/registration' },
            //             { label: 'Notes', to: '/user_profile/students/profile/notes' },
            //         ],
            //         requiresAuth: true,
            //     },
            // },
            // {
            //     path: '/user_profile/students/profile/evaluations',
            //     name: 'User Student Evaluations',
            //     component: () => import('./views/User/Students/Profile/Evaluation/evaluation.vue'),
            //     meta: {
            //         breadcrumb: [
            //             { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
            //             { label: 'Student', to: '/user_profile/students/registration' },
            //             { label: 'Profile', to: '/user_profile/students/profile' },
            //             { label: 'Evaluations', to: '/user_profile/students/profile/evaluations' },
            //         ],
            //         requiresAuth: true,
            //     },
            // },
            // {
            //     path: '/user_profile/students/profile/registration_form',
            //     name: 'User Student RegistrationForm',
            //     component: () => import('./views/User/Students/Profile/Registration_Form/registration_form.vue'),
            //     meta: {
            //         breadcrumb: [
            //             { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
            //             { label: 'Student', to: '/user_profile/students/registration' },
            //             { label: 'Profile', to: '/user_profile/students/profile/detail_profile' },
            //             { label: 'Registration Form', to: '/user_profile/students/profile/registration_form' },
            //         ],
            //         requiresAuth: true,
            //     },
            // },
        ],
    },
    {
        path: '/all_student_orders',
        name: 'User Student All Order',
        component: () => import('./views/Dashboard/all_student_order.vue'),
        meta: {
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Order', to: '/user_profile/students/profile/orders' },
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/all_notification',
        name: 'User Admin Notification',
        component: () => import('./views/Dashboard/notifications'),
        meta: {
            breadcrumb: [
                { label: 'Dashboard', to: '/dashboard', icon: 'pi pi-home' },
                { label: 'Notification', to: '/all_notifications' },
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/logout',
        name: 'Logout',
        exact: true,
        meta: {
            requiresAuth: true,
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return { top: 0, y: 0 };
    },
});

router.beforeEach((to, from, next) => {
    let token = store.state.livejapan.login_token ? store.state.livejapan.login_token : null;
    let allow = to.meta.level ? to.meta.level.includes(store.state.livejapan.aktif_role) : true;
    let x = to.matched;
    store.commit('set_last_query', to);
    store.commit('set_last_from', from);
    if (x.length) {
        if (to.meta.requiresAuth) {
            if (token) {
                // console.log(to.path);
                if (to.path == '/logout') {
                    store.commit('set_clear_token');
                    next('/');
                } else if (allow) {
                    next();
                } else {
                    next(false);
                }
            }
        } else {
            // console.log(3);
            if (token) {
                // console.log(4);
                next('/dashboard');
            } else {
                // console.log(5);
                next();
            }
        }
    } else {
        console.log('router not match');
        next('/404');
    }
});

export default router;
