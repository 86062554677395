<template>
    <div class="grid">
        <!-- <Button type="button" icon="pi pi-plus" label="Add" class="p-button mt-2 ml-2" @click="(display = true), v$.$reset()" /> -->
        <Avatar v-badge.danger="badge" class="p-overlay-badge" shape="circle" icon="pi pi-bell" size="" @click="display = true" />
        <div class="col-12 lg:col-12">
            <Dialog header="Notifications" v-model:visible="display" :breakpoints="{ '960px': '75vw' }" :style="{ width: '40vw' }" :modal="true" @hide="$store.commit('check_notif', display_notif)">
                <div class="grid">
                    <div v-for="i in display_notif" :key="i.id" class="note col-12 lg:col-12 p-fluid" :style="!this.seen.includes(i.iden) ? 'background-color: #C1D1F3' : ''">
                        <div style="color: #2563eb; font-size: 14px; font-weight: 600">
                            <span
                                ><b>{{ $moment(i.time).fromNow() }}</b></span
                            >
                        </div>
                        <div v-if="i.origin" class="mt-1">
                            <span v-if="i.title_notification == 'Cancelled Lesson'">
                                Your Lesson {{ $moment(i.notification_text.start_date).format('YYYY/MM/DD') }} {{ $moment(i.notification_text.start_date).format('HH: mm') }} -
                                {{ $moment(i.notification_text.end_date).format('HH:mm') }} is Cancelled.
                            </span>
                            <span v-if="i.title_notification == 'Lesson Evaluation'">
                                <span @click="go_evaluation(i.notification_text)">You have New Lesson Evaluation !</span>
                            </span>
                            <span v-else-if="i.title_notification == 'News For Students'">
                                <span style="cursor: pointer; text-decoration: underline" @click="i.news_status == 'active' ? go_student_news(i.news_id) : ''">
                                    Student News is UP! -
                                    <span>{{ i.news_title }}</span>
                                </span>
                            </span>
                            <span v-else-if="i.title_notification == 'News For Teachers'">
                                <span style="cursor: pointer; text-decoration: underline" @click="i.news_status == 'active' ? go_teacher_news(i.news_id) : ''">
                                    Instructor News is UP! -
                                    <span>{{ i.notification_text.news_title }}</span>
                                </span>
                            </span>
                            <span v-else-if="i.title_notification == 'News Bank'">
                                <span style="cursor: pointer; text-decoration: underline" @click="i.news_bank_status == 'active' ? go_newsbank(i.news_bank_id) : ''"> Newsbank Updated !</span></span
                            >
                            <span v-else-if="i.title_notification == 'Lesson Chat' && i.lesson_status != 0">
                                <span
                                    v-if="i.notification_text.user_id != user_id"
                                    style="cursor: pointer; text-decoration: underline"
                                    @click="i.lesson_status != 0 ? go_lesson(i.notification_text) : ''"
                                >
                                    You have a new Message from {{ i.notification_text.given_name_english ? i.notification_text.given_name_english : '' }}
                                    {{ i.notification_text.surname_english ? i.notification_text.surname_english : '' }}!
                                    <!-- <span v-if="i.lesson_status == 0" class="p-error font-italic"> (the lesson has been cancelled!)</span> -->
                                </span>
                                <span v-else style="cursor: pointer; text-decoration: underline" @click="i.lesson_status != 0 ? go_lesson(i.notification_text) : ''">
                                    You added new Message to Lesson chat !
                                    <!-- <span v-if="i.lesson_status == 0" class="p-error font-italic"> (the lesson has been cancelled!)</span> -->
                                </span>
                            </span>
                            <!-- <span v-else-if="i.title_notification == 'New Teacher Application'">
                                <span style="cursor: pointer; text-decoration: underline;" @click="go_lesson(i.notification_text)">
                                    New Teacher {{ i.notification_text.first_name ? i.notification_text.first_name : '' }} {{ i.notification_text.last_name ? i.notification_text.last_name : '' }}!
                                </span>
                            </span> -->
                        </div>
                        <div v-else class="mt-1">
                            <span>
                                Your Lesson {{ $moment(i.date_start_lesson).format('YYYY/MM/DD') }} {{ $moment(i.date_start_lesson).format('HH: mm') }} -
                                {{ $moment(i.date_end_lesson).format('HH:mm') }} will start soon !
                            </span>
                        </div>
                    </div>
                </div>
                <template #footer>
                    <!-- <Button label="Cancel" @click="display = false" icon="" class="p-button-secondary" />
                    <Button label="Save" @click="register()" icon="" class="p-button-success" :disabled="!isValid" /> -->
                </template>
            </Dialog>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            display: false,
            notif: [],
            upcoming: [],
            myInterval: null,
        };
    },
    computed: {
        role() {
            return this.$store.state.livejapan.aktif_role ? this.$store.state.livejapan.aktif_role : null;
        },
        isLogin() {
            return this.$store.state.livejapan.login_token ? true : false;
        },
        isStudent() {
            return this.role == 'student';
        },
        isTeacher() {
            return this.role == 'teacher';
        },
        badge() {
            let x = this.display_notif.filter((i) => {
                return !this.seen.includes(i.iden);
            });
            return this.display_notif.length ? x.length : 0;
        },
        user_id() {
            return this.$store.state.livejapan.aktif_login_data ? this.$store.state.livejapan.aktif_login_data.user_id : null;
        },
        display_notif() {
            let vm = this;
            let x = vm.notif;
            vm.upcoming.forEach((i) => {
                x.unshift(i);
            });
            return x.sort((a, b) => {
                return b.sort - a.sort;
            });
        },
        seen() {
            return this.$store.state.livejapan.seen_notif ? this.$store.state.livejapan.seen_notif : null;
        },
    },
    watch: {
        // user_id(newv) {
        //     if (newv) {
        //         if (this.isTeacher) {
        //             this.get_upcoming_teacher();
        //         } else if (this.isStudent) {
        //             this.get_upcoming_student();
        //         }
        //     }
        // },
    },
    created() {
        this.get_notif();
        // if (this.isTeacher) {
        //     this.get_upcoming_teacher();
        // } else if (this.isStudent) {
        //     this.get_upcoming_student();
        // }
        setTimeout(() => {
            this.startInterval();
        }, 5000);
    },
    methods: {
        startInterval() {
            let vm = this;
            if (vm.isLogin) {
                let interval = setInterval(() => {
                    this.get_notif();
                    // if (this.isTeacher) {
                    //     this.get_upcoming_teacher();
                    // } else if (this.isStudent) {
                    //     this.get_upcoming_student();
                    // }
                }, 300000);
                vm.myInterval = interval;
                clearInterval(interval - 1);
            }
        },
        go_teacher_news(x) {
            this.$router.push('/detail_teacher_news?id=' + x);
            this.display = false;
        },
        go_student_news(x) {
            this.$router.push('/detail_student_news?id=' + x);
            this.display = false;
        },
        go_newsbank(x) {
            this.$router.push('/newsbank/detail_newsbank?id=' + x);
            this.display = false;
        },
        go_evaluation() {
            this.$router.push('/evaluation');
            this.display = false;
        },
        go_lesson(x) {
            if (x.type_lesson == 'Group Lesson') {
                this.$router.push('/lesson_detail?id=' + x.lesson_schedule_id + '&group=true');
            } else {
                this.$router.push('/lesson_detail?id=' + x.lesson_schedule_id);
            }
            this.display = false;
        },
        async get_notif() {
            let vm = this;
            let notif = await vm.$axios.post(vm.isStudent ? 'notification/list_for_student' : vm.isTeacher ? 'notification/list_for_teacher' : 'notification/list_for_admin', {
                limit: 20,
                page: '0',
                teacher_id: vm.isTeacher ? vm.user_id : null,
                student_id: vm.isStudent ? vm.user_id : null,
            });
            // console.log(notif.data.data, 'notif');
            if (notif.data.status == 200) {
                if (notif.data.data.length) {
                    vm.notif = notif.data.data.map((i) => {
                        i.time = vm.$moment(i.createdAt);
                        i.sort = i.time.diff(vm.$moment());
                        i.origin = true;
                        i.iden = i.id;
                        return i;
                    });
                }
            }
        },
        async get_upcoming_teacher() {
            let vm = this;
            vm.upcoming = [];
            let coming = await vm.$axios.post('lesson_schedule/list_upcoming_lesson_by_teacher', {
                teacher_id: vm.user_id,
            });
            vm.upcoming = coming.data.data.filter((i) => {
                i.time = vm.$moment(i.date_start_lesson).subtract(1, 'hours');
                i.sort = i.time.diff(vm.$moment());
                i.origin = false;
                i.iden = i.lesson_schedule_id;
                return i.time <= vm.$moment();
                // return i;
            });
            // console.log(coming.data, 'coming');
        },
        async get_upcoming_student() {
            let vm = this;
            vm.upcoming = [];
            let coming = await vm.$axios.post('lesson_schedule/list_upcoming_lesson_by_student', {
                student_id: vm.user_id,
            });
            vm.upcoming = coming.data.data.filter((i) => {
                i.time = vm.$moment(i.date_start_lesson).subtract(1, 'hours');
                i.sort = i.time.diff(vm.$moment());
                i.origin = false;
                i.iden = i.lesson_schedule_id;
                return i.time <= vm.$moment();
                // return i;
            });
            // console.log(coming.data, 'coming');
        },
    },
};
</script>

<style scoped>
.note:hover {
    background-color: '#C1D1F3';
}
</style>
