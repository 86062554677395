<template>
    <div :class="['layout-inline-menu', { 'layout-inline-menu-active': activeInlineProfile }]" v-if="!isMobile() && (isStatic() || isSlim() || isSidebar())">
        <a class="layout-inline-menu-action" @click="onChangeActiveInlineMenu">
            <!-- <img :src="images" alt="avatar" style="width: 44px; height: 44px" /> -->
            <!-- <Avatar @error="showError($event)" v-if="images" :image="images" size="large" shape="circle" />
            <Avatar v-else :label="initial" size="large" shape="circle" /> -->
            <div v-if="images" class="temp">
                <img :src="images" alt="avatar" class="img-circle-small-sm" @error="src = null" />
            </div>
            <div v-else class="temp">
                <p :data-letters-sm="initial"></p>
            </div>
            <span style="text-transform: capitalize" class="layout-inline-menu-text">{{ name }}</span>
            <i class="layout-inline-menu-icon pi pi-angle-down"></i>
        </a>

        <transition name="layout-inline-menu-action-panel">
            <ul v-show="activeInlineProfile" class="layout-inline-menu-action-panel">
                <li class="layout-inline-menu-action-item" @click="$router.push('/logout')">
                    <a>
                        <i class="pi pi-power-off pi-fw"></i>
                        <span>Logout</span>
                    </a>
                </li>
                <!-- <li class="layout-inline-menu-action-item">
                    <a>
                        <i class="pi pi-cog pi-fw"></i>
                        <span>Settings</span>
                    </a>
                </li> -->
                <!-- <li class="layout-inline-menu-action-item">
                    <a>
                        <i class="pi pi-user pi-fw"></i>
                        <span>Profile</span>
                    </a>
                </li> -->
            </ul>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'AppInlineMenu',
    emits: ['change-inlinemenu'],
    props: {
        menuMode: String,
        activeInlineProfile: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        tokens() {
            return this.$store.state.livejapan.login_token ? this.$store.state.livejapan.login_token : null;
        },
        user_id() {
            return this.$store.state.livejapan.aktif_login_data ? this.$store.state.livejapan.aktif_login_data.user_id : null;
        },
        login_data() {
            return this.$store.state.livejapan.login_data ? this.$store.state.livejapan.login_data : null;
        },
        name() {
            return this.$store.state.livejapan.aktif_login_data
                ? `${this.$store.state.livejapan.aktif_login_data.given_name_english} ${this.$store.state.livejapan.aktif_login_data.surname_english} `
                : null;
        },
        initial() {
            let x = this.$store.state.livejapan.aktif_login_data ? this.$store.state.livejapan.aktif_login_data : null;
            if (x) {
                let initials = x.given_name_english ? x.given_name_english[0].toUpperCase() : 'N';
                initials += x.surname_english ? x.surname_english[0].toUpperCase() : 'N';
                return initials;
            } else {
                return 'NN';
            }
        },
        images() {
            let x = this.$store.state.livejapan.aktif_login_data;
            if (x) {
                return x.user_photo ? this.ip + x.user_photo : null;
            } else {
                return null;
            }
        },
    },
    watch: {
        user_id(newV) {
            if (newV) {
                this.checkUser(newV);
            }
        },
    },
    created() {
        // document.addEventListener('visibilitychange', () => {
        //     if (document.visibilityState == 'visible') {
        //         console.log('tab is active');
        //         this.checkUser();
        //     } else {
        //         console.log('tab is inactive');
        //     }
        // });
    },
    methods: {
        async checkUser() {
            let vm = this;
            let user = await vm.$axios('user/check_user');
            // console.log(user.data.data, 'check');
            if (user.data.status == 200) {
                if (user.data.message == 'success') {
                    if (user.data.data.length) {
                        vm.$store.commit('userCheck', user.data.data[0]);
                    }
                }
            }
        },
        showError(x) {
            console.log(x, 'error avatar');
        },
        onChangeActiveInlineMenu(event) {
            this.$emit('change-inlinemenu', event);
            event.preventDefault();
        },
        isHorizontal() {
            return this.menuMode === 'horizontal';
        },
        isSlim() {
            return this.menuMode === 'slim';
        },
        isOverlay() {
            return this.menuMode === 'overlay';
        },
        isStatic() {
            return this.menuMode === 'static';
        },
        isSidebar() {
            return this.menuMode === 'sidebar';
        },
        isDesktop() {
            return window.innerWidth > 991;
        },
        isMobile() {
            return window.innerWidth <= 991;
        },
    },
};
</script>

<style scoped>
img {
    object-fit: cover;
}
</style>
